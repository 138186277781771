import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ConfigService} from '../config/config.service';
import {environment} from '../../environments/environment';

// we can now access environment.apiUrl
const API_URL = environment.apiUrl;

@Injectable()
export class APIService {
  protected session_headers;
  protected apiServer;
  protected externalEventsAPI;
  protected externalEducatorAPI;

  constructor(
    private http: HttpClient,
    private _configService: ConfigService
  ) {
    this.session_headers = new HttpHeaders();
    this.session_headers = this.session_headers.set('Content-Type', 'application/json');
    this.setAPIServer();
  }

  public get(ext: string, params: Object, headers?: any[]): Observable<any> {
    if (headers) {
      this.addHeaders(headers);
    }
    const httpOptions = {headers: this.session_headers, params: this.addParams(params)};
    let url = this.apiServer + ext;
    return this.http.get(url, httpOptions).pipe(
      map((res) => res['data']),
      catchError(this.handleError)
    );
  }

  public post(ext: string, params: object, headers?: any[]): Observable<any> {
    if (headers) {
      this.addHeaders(headers);
    }
    const httpOptions = {headers: this.session_headers};

    let url = this.apiServer + ext;
    let data = JSON.stringify(params);

    return this.http.post(url, data, httpOptions).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  public put(ext: string, params: object, headers?: any[]): Observable<any> {
    if (headers) {
      this.addHeaders(headers);
    }
    const httpOptions = {headers: this.session_headers};

    let url = this.apiServer + ext;
    let data = JSON.stringify(params);

    return this.http.put(url, data, httpOptions).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  public addHeaders(headers: any[]): void {
    if (headers.length > 0) {
      for (let header of headers) {
        if (!this.session_headers.get(header.key)) {
          this.session_headers = this.session_headers.append(header.key, header.val.toString());
        }
      }
    }
  }

  public addParams(params: Object): HttpParams {
    let httpParams = new HttpParams();
    for (let key in params) {
      httpParams = httpParams.append(key, JSON.stringify(params[key]));
    }
    return httpParams;
  }

  public clearHeaders(header?: string | undefined): void {
    // if no header name is given all headers except for 'application/json' are removed
    // if a header name is given only that header is removed.
    if (header) {
      this.session_headers = this.session_headers.delete(header);
    } else {
      let nameList = this.session_headers.keys();
      for (let name of nameList) {
        this.session_headers = this.session_headers.delete(name);
      }
      this.session_headers = this.session_headers.set('Content-Type', 'application/json');
    }
  }

  public downloadFile(ext, params?: Object) {
    let httpOptions = {params: null, responseType: 'text' as 'text'};
    httpOptions.params = this.addParams(params);
    let url = this.apiServer + ext;
    return this.http.get(url, httpOptions);
  }

  getGeoAddresses = function (text) {
    return this.http.get('https://maps.googleapis.com/maps/api/place/autocomplete/json?input=mat&types=(cities)&key=' + environment.gapi);
  };

  reverserGeoCode = function (lat, lng) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + environment.gapi);
  };

  private setAPIServer() {
    this.apiServer = API_URL + '/';
    this._configService.getConfig()
      .subscribe(
        (data) => {
          this.apiServer = API_URL + '/';
          this.externalEventsAPI = data.client.externalEventsAPI;
          this.externalEducatorAPI = data.client.externalEducatorAPI;
        },
        (error) => this.apiServer = null
      );
  }

  private handleError(error) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      errMsg = `${error.status} - ${error.statusText || ''} `;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
