import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-speaker-list',
  templateUrl: './speaker-list.component.html'
})
export class SpeakerListComponent implements OnInit {
  @Input('speakers') speakers;
  @Input('title') title;
  @Input('webPage') webPage;

  constructor() {
  }

  ngOnInit() {
  }
}
