import {Component, Input} from '@angular/core';
import {Location} from '../interfaces/index';

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html'
})
export class MapLocationComponent{
  @Input('location') location: Location;
  @Input('webPage') webPage;
  @Input('programType') programType;
  @Input('meetingLive') meetingLive;

  mapOptions: google.maps.MapOptions  = {
    mapTypeControl: false,
    fullscreenControl: false
  };

  constructor() {
  }

  ngAfterViewInit() {
    const mapContainer = document.querySelector('.map-container');

    if (mapContainer) {
      mapContainer.setAttribute('style', 'width: 100% !important; height: 280px !important;');
    }
  }
}
