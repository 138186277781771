import {Component, OnInit} from '@angular/core';
import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-resources',
  template: `
    <ng-container [ngSwitch]="webPage">
      <ubrelvy-resources *ngSwitchCase="'ubrelvy'"></ubrelvy-resources>
      <qulipta-resources *ngSwitchCase="'qulipta'"></qulipta-resources>
    </ng-container>`
})
export class ResourcesComponent implements OnInit {
  webPage = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected _configService: ConfigService
  ) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data) => {
          if (data.client.webpage === 'migraine') {
            this.route.params.subscribe(params => {
              this.webPage = params['product'];
            });
          } else {
            this.router.navigate(['/home']);
          }
        },
        (error) => this.webPage = null
      );
  }
}

@Component({
  selector: 'ubrelvy-resources',
  templateUrl: '../ui/ubrelvy/resources.component.html',
})

export class UbrelvyResourcesComponent extends ResourcesComponent {
}

@Component({
  selector: 'qulipta-resources',
  templateUrl: '../ui/qulipta/resources.component.html',
})

export class QuliptaResourcesComponent extends ResourcesComponent {
  enrollment_link = '';

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.enrollment_link = data.client.qulipta_enrollment_form_link;
        },
      );
  }
}
