import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as $ from 'jquery';

import {Config} from '../config/config';

import {SearchObject} from '../interfaces/index';
import {ConfigService} from '../config/config.service';
import {AttendeeService} from '../services/index';
import 'rxjs/add/operator/finally';
import {Title} from '@angular/platform-browser';
import {AnalyticsService} from '../services/analytics.service';
import {NgForm, NgModel} from '@angular/forms';

@Component({
  selector: 'app-search',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-search *ngSwitchCase="'vraylar'"></vraylar-search>
      <migraine-search *ngSwitchCase="'migraine'"></migraine-search>
      <vuity-search *ngSwitchCase="'vuity'"></vuity-search>
      <botox-search *ngSwitchCase="'botox'"></botox-search>
    </ng-container>`
})
export class SearchComponent implements OnInit {
  webPage = null;
  showList = false;
  searchModel: SearchObject = {page: 1, topic_audience: 'other', zip: null, distance: 50, search_button_clicked: false};
  searchVirtual: Boolean = false;
  distanceList: Array<any> = [5, 15, 25, 50];
  invalidzip = false;
  loading: Boolean = false;
  disableSearchBtn: Boolean = false;
  defaultSearchDistance;

  external_uri = null;

  constructor(
    protected _configService: ConfigService,
    protected _attendeeService: AttendeeService,
    protected titleService: Title,
    protected _analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    $('input[name="zip"]').focus();
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
          this.searchModel.distance = data.client.search_distance;
          this.external_uri = data.client.externalUri;
        },
        (error) => this.webPage = null
      );
  }

  subscribeDataLoading() {
    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.loading = status;
      });
  }

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getConfig()
        .subscribe((data: Config) => {
          this.subscribeDataLoading();
          this.webPage = data.client.webpage;
          this.searchModel.distance = data.client.search_distance;
          this.defaultSearchDistance = data.client.search_distance;
          this.searchVirtual = data.client.search_virtual;
          this.searchVirtual ? this.distanceList.push('Virtual') : false;

          this.external_uri = data.client.externalUri;
          if (!this._attendeeService.searchParams) {
            this._attendeeService.getCurrentZip()
              .subscribe((zip) => {
                this.searchModel.zip = zip;
                if (data.client.pull_webcast) {
                  //  this.searchPrograms();
                }
              });
          }

          setTimeout(() => {
            if (this._attendeeService.searchParams) {
              this.searchModel = this._attendeeService.searchParams;
              this.searchPrograms(true);

            } else if (data.client.pull_webcast) {
              this.searchPrograms(false);

            }
          }, 100);
        });
    });
  }

  resetZip(): void {
    if (this.searchModel.distance.toString() === 'Virtual'
      || this.searchModel.distance.toString() === 'broadcasts') {
      this.searchModel.zip = null;
    }
  }

  searchPrograms(search_button_clicked: boolean): void {
    this._attendeeService.setSearchDistance(this.searchModel.distance);
    this.searchModel.search_button_clicked = search_button_clicked;
    this.searchModel.page = 1;
    this.disableSearchBtn = search_button_clicked;
    this.resetZip();

    this.showList = true;
    this.invalidzip = false;
    this._attendeeService.clearMeetingLists()
      .subscribe(success => {
          this._attendeeService.setMeetingLists(this.webPage, this.searchModel)
            .finally(() => {
              this.disableSearchBtn = false;
            })
            .subscribe(success => {
              },
              (error) => {
                this.invalidzip = true;
              }
            );
        }
      );
  }

}

@Component({
  selector: 'botox-search',
  templateUrl: '../ui/botox/search.component.html',
  styleUrls: ['search.component.css']
})

export class BotoxSearchComponent extends SearchComponent {
  ngOnInit() {
    this.initializeClient().subscribe(() => {
    });
  }
}

@Component({
  selector: 'vraylar-search',
  templateUrl: '../ui/vraylar/search.component.html',
  styleUrls: ['search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VraylarSearchComponent extends SearchComponent {
  ngOnInit() {
    this.initializeClient().subscribe(() => {
    });
  }
}

@Component({
  selector: 'migraine-search',
  templateUrl: '../ui/migraine/search.component.html',
  styleUrls: ['search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MigraineSearchComponent extends SearchComponent {
  ngOnInit() {
    this.initializeClient().subscribe(() => {
    });
    this.distanceList = [5, 15, 25, 50];

    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.disableSearchBtn = status;
      });
  }
}

@Component({
  selector: 'vuity-search',
  templateUrl: '../ui/vuity/search.component.html',
  styleUrls: ['search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VuitySearchComponent extends SearchComponent {
  firstInitiation = true;

  ngOnInit() {
    this.initializeClient().subscribe(() => {
    });
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
          setTimeout(() => {
            this.track();
          }, 2000);
        },
        (error) => this.webPage = null
      );
    this.distanceList = [5, 15, 25, 50];

    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.disableSearchBtn = status;
      });
  }

  track() {
    const analyticObject = {
      'pageInfo': {
        'domain': window.location.hostname,
        'subDomain': '',
        'url': window.location.href,
        'path': window.location.pathname,
        'pageName': 'Home Page',
        'title': this.titleService.getTitle(),
        'prefix': 'vuitylive'
      },
      'category': {
        'primaryCategory': 'Home Page',
        'subCategory1': '',
        'subCategory2': ''
      },
      'attributes': {
        'country': 'US',
        'language': 'en-US',
        'template': '',
        'promoMatsNumber': 'US-VUI-210146',
        'globalISI': '',
        'type': 'Homepage'
      }
    };

    this._analyticsService.updateStateObject(analyticObject);
  }

  onRadioChange(): void {
    this.resetZip();
    const fieldValue = this.searchModel.distance.toString();
    this.trackInteraction('Search Within', 'Search Within', fieldValue, '');
  }

  onZipChange(zip: NgModel): void {
    const fieldValue = this.searchModel.zip.toString();
    let fieldError = '';
    if (zip.invalid) {
      fieldError = 'Zip Code is Invalid';
    }
    this.trackInteraction('Zipcode', 'Zip Code', fieldValue, fieldError);
  }

  onSearchSubmit(form: NgForm): void {
    this.resetZip();
    let formError = '';
    if (form.invalid) {
      formError = 'Zip code is invalid';
    }
    const fieldValues = 'Search Within:' + this.searchModel.distance +
      '|ZipCode:' + this.searchModel.zip;

    this.trackInteraction('Search', 'Search Within', '',
      formError, fieldValues, 'primary', 'form', 'Event Search');
    if (form.valid) {
      this.searchPrograms(true);
    }
  }

  trackInteraction(title, fieldName, fieldValue, formerror,
                   fieldValues = '',
                   mvaTier = '',
                   mvaType = '',
                   mvaCategory = '') {
    let stepName = '';
    if (title === 'Search') {
      stepName = 'submit';
    } else if (this.firstInitiation) {
      stepName = 'initiation';
    }

    this.firstInitiation = false;

    const analyticObject = {
      link: {
        name: title,
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: title,
        name: 'Search Within',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Form',
        name: ''
      },
      form: {
        name: 'Event Search',
        category: 'interaction',
        subCategory: 'event search',
        fieldName: fieldName,
        fieldValue: fieldValue,
        fieldValues: fieldValues,
        stepName: stepName,
        stepNumber: '',
        error: formerror
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: mvaTier,
        type: mvaType,
        category: mvaCategory
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }
}
