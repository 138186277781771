<style type="text/css">
  h5 {
    font-weight: 400;
  }

  div.header-table table {
    margin-bottom: 0;
    border: 1px solid #fff;
    border-bottom: 0;
  }

  .invalid {
    border-color: #dc3545;
  }

  .container {
    font-weight: 500;
  }

  button {
    border-radius: 0.2rem;
    padding-left: 12px;
    padding-right: 12px;
    width: 80px;
    font-weight: 400 !important;
    margin: 0 12px 0 0;
  }

  .panel-body {
    padding: 15px;
  }

  #register label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
    cursor: pointer;
  }

  .register_panel {
    background-color: #4D3A99;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    margin-bottom: 20px;
  }

  .register_panel table {
    font-size: 14px !important;
  }

  a.privacy_policy {
    font-weight: 600;
    color: #03AEA9 !important;
  }

  p.opt_in {
    font-size: 14px;
    margin: 0;
    padding-left: 10px;
  }

  .title {
    margin-bottom: 25px;
    text-align: center;
  }

  .title h1 {
    color: #26DEC2;
    font-weight: 700;
    display: inline-block;
    margin: 10px 0;
  }

  .title h2 {
    margin: 0;
    font-weight: 700;
    color: #4d4d4f;
  }

  .error,
  .invalid-feedback {
    font-size: 16px;
    color: #F6AAA8 !important;
  }

  .register-form .form-control {
    font-size: 13px !important;
    padding: 5px;
  }

  .display_block {
    display: block;
  }

  .buttons .btn-register {
    background-color: #26DEC2;
  }

  .buttons .btn-default {
    color: #000;
  }

  .register-message h2 {
    color: #364FCB;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    .register-message h2 {
      font-size: 22px
    }

    #phone_number_section {
      width: 100%;
    }
  }
</style>

<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>EVENT REGISTRATION</h1>
      </div>
    </div>
  </div>
  <div class="specialty_curve"></div>
</section>
<div class="container" id="register">
  <div *ngIf="!program || loading" class="loading container text-center"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="program && !loading" class="row">
    <div class="register-message col-12">
      <h2>For U.S. Healthcare Professionals Only </h2>
    </div>
    <div class="col-12" id="right_bar">
      <div class="row justify-content-center" *ngIf='program.location'>
        <div class="col-12"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-map-location [location]='program.location' [webPage]="webPage" [meetingLive]="program.meeting_type_live">
          </app-map-location>
        </div>
        <div class="col-12 col-md-6"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-speaker-list *ngIf="program" [speakers]='program.speakers' [title]="'Speaker(s)'" [webPage]="webPage">
          </app-speaker-list>
        </div>
        <div class="col-12 col-md-6" *ngIf="program.event_type_sub_code !== 'BRDPGM'"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-sf-members *ngIf="program" [rep]='program.sf_members' [title]="'Sales Representative'"
                          [webPage]="webPage"></app-sf-members>
        </div>
      </div>
    </div>
    <div class="title col-12">
      <h1>Register for Event: {{program.formatted_program_id}}<br></h1>
      <div>
        <h2>{{program.topic}}</h2>
        <h2> {{program.meeting_date_long}} at {{program.meeting_time}} {{program.timezone}}
          <span *ngIf='program.meeting_type_live'> in {{program.location.city}},
            {{program.location.state}}</span>
        </h2>
      </div>
    </div>
    <div class="col-lg-12 left_content">
      <form #f="ngForm" (ngSubmit)="registerVuity(f)" class="register-form" name="form" novalidate>
        <div class="panel register_panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label class="rgstr-label" for="firstname">First Name *</label>
                  <input #firstname="ngModel" pattern ="^[A-Za-z\-']+$" (change)="onFieldChange(firstname, 'First Name', true)"
                         [(ngModel)]="attendeeModel.firstname"
                         [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }"
                         class="form-control" id="firstname" name="firstname" ngModel required type="text">
                  <div *ngIf="f.submitted && firstname.invalid" class="invalid-feedback">
                    <div *ngIf="firstname.errors.required">First Name is required</div>
                    <div *ngIf="firstname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2 col-lg-2">
                <div class="form-group">
                  <label class="rgstr-label" for="midname">MI</label>
                  <input #midname="ngModel" [(ngModel)]="attendeeModel.midname" [maxLength]="1"
                         [ngClass]="{ 'is-invalid': f.submitted && midname.invalid }"
                         class="form-control" id="midname" name="midname" ngModel pattern="[a-zA-Z]{1}"
                         type="text">
                  <div *ngIf="f.submitted && midname.invalid" class="invalid-feedback">
                    <div *ngIf="midname.errors.pattern">Enter valid MI</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label class="rgstr-label" for="lastname">Last Name *</label>
                  <input #lastname="ngModel" pattern ="^[A-Za-z\-']+$" (change)="onFieldChange(lastname, 'Last Name', true)"
                         [(ngModel)]="attendeeModel.lastname"
                         [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }"
                         class="form-control" id="lastname" name="lastname" ngModel required type="text">
                  <div *ngIf="f.submitted && lastname.invalid" class="invalid-feedback">
                    <div *ngIf="lastname.errors.required">Last Name is required</div>
                    <div *ngIf="lastname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label class="rgstr-label" for="affiliation">Affiliation</label>
                  <input #affiliation="ngModel" (change)="onFieldChange(affiliation, 'Affiliation')"
                         [(ngModel)]="attendeeModel.affiliation" class="form-control"
                         id="affiliation" name="affiliation" ngModel type="text">
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label class="rgstr-label" for="address1">Street Address 1*</label>
                  <input #address1="ngModel" [(ngModel)]="attendeeModel.address1"
                         [ngClass]="{ 'is-invalid': f.submitted && address1.invalid }" class="form-control"
                         id="address1" name="address1" ngModel required
                         type="text" (ngModelChange)="validateAddress(attendeeModel.address1)" ngx-google-places-autocomplete 
                         (onAddressChange)="handleAddressChange($event)" (input)="resetFields()">
                         <div *ngIf="f.submitted  && addressErrors" class="error">
                          <div *ngFor="let error of addressErrors">{{ error }}</div>
                        </div>
                  <div *ngIf="f.submitted && address1.invalid" class="invalid-feedback">
                    <div *ngIf="address1.errors.required">Street Address is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="address2" class="rgstr-label">Street Address 2</label>
                  <input id="address2" class="form-control" [(ngModel)]="attendeeModel.address2" name="address2" type="text" #address2="ngModel" ngModel>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label class="rgstr-label" for="city">City *</label>
                  <input #city="ngModel" (change)="onFieldChange(city, 'City')"
                         [(ngModel)]="attendeeModel.city" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }"
                         class="form-control readonly-field" id="city" name="city" ngModel required type="text"
                         (ngModelChange)="validateCity(attendeeModel.city)" readonly>
                  <div *ngIf="f.submitted && city.invalid" class="invalid-feedback">
                    <div *ngIf="city.errors.required">City is required</div>
                  </div>
                  <div *ngIf="f.submitted  && cityErrorMessage" class="error">{{cityErrorMessage}}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2 col-lg-2">
                <div class="form-group">
                  <label class="rgstr-label" for="state">State *</label>
                  <select #state="ngModel" (change)="onFieldChange(state, 'State')"
                          [(ngModel)]="attendeeModel.state" [ngClass]="{ 'is-invalid': f.submitted && state.invalid }"
                          class="form-control browser-default custom-select readonly-field" id="state" name="state" ngModel required (mousedown)="false"
                          (keydown)="$event.preventDefault()" readonly>
                    <option [value]="null"> Select State</option>
                    <option *ngFor="let state of states" [value]="state.state_alpha2"> {{state.name}} </option>
                  </select>
                  <div *ngIf="f.submitted && state.invalid" class="invalid-feedback">
                    <div *ngIf="state.errors.required">State is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label class="rgstr-label" for="zip">Zip *</label>
                  <input #zip="ngModel" (change)="onFieldChange(zip, 'ZipCode')" [(ngModel)]="attendeeModel.zip"
                         [ngClass]="{'is-invalid': f.submitted && zip.invalid }" pattern="^(?!00000)[0-9]{5}(?:-[0-9]{4})?$"
                         class="form-control readonly-field" id="zip" maxlength="10" name="zip" ngModel required type="text" readonly>
                  <div *ngIf="f.submitted && zip.invalid" class="invalid-feedback">
                    <div *ngIf="zip.errors.required">ZIP code is required</div>
                    <div *ngIf="zip.errors?.pattern">Invalid: enter 5 or 9 numbers and cannot be 00000</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label class="rgstr-label" for="email">Email *</label>
                  <input #email="ngModel" (change)="onFieldChange(email, 'Email Address')"
                         [(ngModel)]="attendeeModel.email" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                         class="form-control" id="email" name="email"
                         ngModel
                         pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required
                         type="email">
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.pattern">Please enter valid Email</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input #phone="ngModel" (change)="onFieldChange(phone, 'Phone')"
                         [(ngModel)]="attendeeModel.phone" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
                         [textMask]="{mask: mask}" class="form-control" id="phone" name="phone"
                         pattern="\(\d{3}\) \d{3}\-\d{4}" type="tel"/>
                  <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                    <div *ngIf="phone.errors.pattern">Please enter valid Phone Number</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="form-group">
                  <label class="rgstr-label" for="degree">Degree *</label>
                  <angular2-multiselect #degree="ngModel" (onDeSelect)="onDegreeChange(degree, 'Degree')"
                                        (onDeSelectAll)="onDegreeChange(degree, 'Degree')"
                                        (onFilterDeSelectAll)="onDegreeChange(degree, 'Degree')"
                                        (onFilterSelectAll)="onDegreeChange(degree, 'Degree')"
                                        (onSelect)="onDegreeChange(degree, 'Degree')"
                                        (onSelectAll)="onDegreeChange(degree, 'Degree')"
                                        [(ngModel)]="attendeeModel.degree" [data]="degrees"
                                        [settings]="degeeDropdownSettings"
                                        class="browser-default"
                                        id="degree"
                                        name="degree"
                                        required>
                  </angular2-multiselect>
                  <div *ngIf="f.submitted && degree.invalid" class="invalid-feedback display_block">
                    <div>Degree is required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="show_degree_other" class="col-12 col-sm-4">
                <div class="form-group">
                  <label class="rgstr-label" for="degreeother">Enter Degree</label>
                  <input #degreeother="ngModel" [(ngModel)]="attendeeModel.degreeother"
                         [ngClass]="{ 'is-invalid': f.submitted && degreeother.invalid }"
                         class="form-control" id="degreeother" name="degreeother" ngModel required
                         type="text">
                  <div *ngIf="f.submitted && degreeother.invalid" class="invalid-feedback">
                    <div *ngIf="degreeother.errors.required">Degree Other is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <label for="licensestate" class="rgstr-label">License State {{license_required ? '*':''}}</label>
                  <select #licensestate="ngModel" (change)="onFieldChange(licensestate, 'License State')"
                          [(ngModel)]="attendeeModel.licensestate"
                          [ngClass]="{ 'is-invalid': f.submitted && licensestate.invalid }"
                          [required]="license_required" class="form-control browser-default custom-select" id="licensestate"
                          name="licensestate" ngModel>
                    <option [value]="null"> Select License State</option>
                    <option *ngFor="let licensestate of licensestates" [value]="licensestate.state_alpha2">
                      {{licensestate.name}} </option>
                  </select>
                  <div *ngIf="f.submitted && licensestate.invalid" class="invalid-feedback">
                    <div *ngIf="licensestate.errors.required">License State is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <label for="statelicense" class="rgstr-label">
                    State License # {{licensestate.value != 'N/A' && license_required ? '*' : ''}}
                  </label>
                  <input #statelicense="ngModel" (change)="onFieldChange(statelicense, 'State License')"
                         [(ngModel)]="attendeeModel.statelicense"
                         [ngClass]="{ 'is-invalid': f.submitted && statelicense.invalid }"
                         [required]="licensestate.value != 'N/A' && license_required" class="form-control"
                         id="statelicense" name="statelicense" ngModel type="text">
                  <div *ngIf="f.submitted && statelicense.invalid" class="invalid-feedback">
                    <div *ngIf="statelicense.errors.required">State License # is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <div class="form-group">
                  <label class="rgstr-label" for="specialty">Specialty</label>
                  <angular2-multiselect #specialty="ngModel" (onDeSelect)="onSpecialtyChange(specialty, 'Specialty')"
                                        (onDeSelectAll)="onSpecialtyChange(specialty, 'Specialty')"
                                        (onFilterDeSelectAll)="onSpecialtyChange(specialty, 'Specialty')"
                                        (onFilterSelectAll)="onSpecialtyChange(specialty, 'Specialty')"
                                        (onSelect)="onSpecialtyChange(specialty, 'Specialty')"
                                        (onSelectAll)="onSpecialtyChange(specialty, 'Specialty')"
                                        [(ngModel)]="attendeeModel.specialty"
                                        [data]="specialties"
                                        [settings]="SpecialtydropdownSettings"
                                        class="browser-default"
                                        id="specialty"
                                        name="specialty"
                                        ngModel>
                  </angular2-multiselect>
                </div>
              </div>
              <div *ngIf="show_specialty_other" class="col-12 col-sm-4">
                <div class="form-group">
                  <label class="rgstr-label" for="specialtyother">Enter Specialty</label>
                  <input #specialtyother="ngModel" [(ngModel)]="attendeeModel.specialtyother"
                         [ngClass]="{ 'is-invalid': f.submitted && specialtyother.invalid }"
                         class="form-control" id="specialtyother" name="specialtyother" required
                         type="text">
                  <div *ngIf="f.submitted && specialtyother.invalid " class="invalid-feedback">
                    <div *ngIf="specialtyother.errors.required">Specialty Other is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="form-group">
                  <label class="rgstr-label" for="npi">NPI #</label>
                  <input #npi="ngModel" (change)="onFieldChange(npi, 'NPI')"
                         [(ngModel)]="attendeeModel.npi" [maxLength]="10"
                         [ngClass]="{ 'is-invalid': f.submitted && npi.invalid }"
                         class="form-control" id="npi" name="npi" ngModel pattern="\d{10}" type="text">
                  <div *ngIf="f.submitted && npi.invalid" class="invalid-feedback">
                    <div *ngIf="npi.errors.pattern">Please enter valid NPI Number</div>
                  </div>
                </div>
              </div>
              <div class="col-12 privacy-policy">
                <p>
                  We respect your privacy. The information pertaining to you that we collect will be used in accordance
                  with our <a (click)="linkInteraction('Privacy Statement', 'External')" class="privacy_policy"
                              href="https://www.abbvie.com/privacy.html"
                              target="_blank">Privacy
                  Statement.</a> By providing your email address above, you agree and acknowledge that you would
                  like to receive email communications from AbbVie, including information about this and other
                  educational programs.
                </p>
              </div>
              <div class="col-12" id="rsvp_signature">
                <p>
                  <strong>Within the box below, electronically sign your registration and submit.</strong><br/>
                  PC Users: use mouse or mouse pad <br/>
                  Tablet/Mobile Device Users: use finger or stylus <br/>
                </p>
                <div class="wrapper" [class.valid]="validSignature">
                  <app-signature-pad [options]="signaturePadOptions" (beginEvent)="drawStart()"></app-signature-pad>
                  <button type="button" class="btn btn-lg btn-default" (click)="clearSignature()">Clear</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-1">
            <span class="text-blue">SIGN UP TO RECEIVE TEXT MESSAGES SO YOU DON'T MISS OUT!</span>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-start">
              <input (change)="checkMessageConfirmation()" [(ngModel)]="attendeeModel.message_confirmation"
                     class="mt-1" name="message-confirmation" ngModel type="checkbox" value="true">
              <p class="opt_in">
                Check this box if you are interested in receiving SMS text message confirmations and reminders for this
                AbbVie event. Please note messages will be sent to the mobile phone number provided below, and standard
                data messaging rates, as determined by your cellular carrier, will apply. By opting in, you are agreeing
                to receive messages as specified above.
              </p>
            </div>
          </div>
          <div class="col-md-12 mb-2">
            <div class="d-flex flex-wrap justify-content-between">
              <div class="mb-2" id="phone_number_section">
                <label for="confirmationPhone"><b>Phone Number</b></label>
                <input #phoneNumber="ngModel" (blur)="checkPhoneNumber(phoneNumber)"
                       [(ngModel)]="attendeeModel.phone_number"
                       [attr.disabled]="!attendeeModel.message_confirmation ? 'disabled' : null"
                       [attr.required]="attendeeModel.message_confirmation"
                       [ngClass]="{ 'invalid': errorPhoneNumber }"
                       [textMask]="{mask: mask}"
                       class="form-control" id="confirmationPhone"
                       name="confirmationPhone" pattern="^[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                       type="text">
                <div *ngIf="errorPhoneNumber">
                  <p class="text-danger">{{errorPhoneMsg}}</p>
                </div>
              </div>
              <div class="align-self-end buttons clearfix mb-2">
                <button (click)="cancel()" class="btn btn-lg btn-default" type="button">CANCEL</button>
                <button class="btn btn-lg btn-register" type="submit">
                  <span *ngIf="!registering">SUBMIT</span>
                  <span *ngIf="registering"><i class="fa fa-spinner fa-spin"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
