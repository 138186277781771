<section id="{{webPage}}_map_section">
  <div class="right_bar_header">
    <div class="row">
      <div class="col-3 marker">
        <i class="fa fa-map-marker"></i>
        <img src="assets/images/icon_pat.png" class="" alt="Icon pat call center" />
      </div>
      <div class="col-9 title"> Location </div>
    </div>
  </div>
  <div *ngIf='(meetingLive); else notShowMap'>
    <div class="content" >
      <div class="map_info">
        <div class=""> {{location.name}}</div>
        <div class="" *ngIf="location.address != ' '"> {{location.address}}
          <br />  <span   *ngIf="location.city || location.state"> {{location.city}}, {{location.state}} </span> {{location.zipcode}}
        </div>
      </div>
    </div>
    <google-map [center]="{lat: location.lat, lng: location.lng}" [options]="mapOptions" [zoom]="15" class="col-xs-10 google-map" 
      *ngIf="location.lat && location.lng">
      <map-marker [position]="{lat: location.lat, lng: location.lng}"></map-marker>
    </google-map>
  </div>
  <ng-template #notShowMap>
    <div class="col-12 content">
      Virtual Program
    </div>
  </ng-template>
</section>