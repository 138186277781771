<style type="text/css">
  .self-center {
    margin: 0 auto;
  }

  .title {
    font-weight: bold;
    color: #4d4d4f;
    text-decoration: underline;
    text-align: center;
    font-size: 24px;
  }

  .box_header_container {
    margin-top: 0 !important;
  }

  .box_header_container .box_header h4 {
    line-height: 30px;
    height: 30px;
  }

  .box_header_container .box_header {
    background-color: #364FCB;
    max-width: none;
    font-size: 32px;
  }

  .text-secondary {
    color: #4d4d4f !important;
    font-size: 24px;
  }

  .box_container {
    background-color: #e6eaea;
    padding: 0;
    border: none;
    margin: 20px auto;
  }

  .box_content {
    min-height: 305px;
    overflow: auto;
    padding: 20px;
    color: #000;
  }

  .single-program .box_content label,
  .single-program .topic {
    color: #000;
  }

  .single-program .topic {
    margin-bottom: 5px;
  }

  .single-program .speaker {
    margin-bottom: 5px;
  }

  .register-message {
    color: #364FCB;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    .register-message {
      font-size: 22px
    }
  }
</style>
<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>YOU ARE REGISTERED!</h1>
      </div>
    </div>
  </div>
  <div class="specialty_curve"></div>
</section>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="program && (attendee_status == 1)">
    <div>
      <h2 class="register-message">For U.S. Healthcare Professionals Only </h2>
      <h2 class="title">Thank you for registering for a Vuity Speaker Program Event<br></h2>
      <h5 class="text-center text-secondary"><span>If you have any questions, please call (888) 591-3998.</span></h5>
    </div>
    <div class="row">
      <div class=" col-12 col-sm-6 self-center">
        <div class="box_container single-program">
          <div class="box_header_container">
            <div class="box_header text-center">
              <h4> {{program.event_type_sub_code == "BRDPGM" ? "Broadcast" :
                (!program.meeting_type_live ? "Virtual" : program.event_heading)}} </h4>
            </div>
          </div>
          <div class="box_content">
            <div class="text-center">
              <div class="topic">{{program.topic}}</div>
            </div>
            <div>
              <label>DATE AND TIME</label>
              <div>{{program.meeting_date}}</div>
              <div>{{program.meeting_time}} {{program.timezone}}</div>
            </div>
            <div>
              <label>LOCATION</label>
              <div *ngIf="program.meeting_type_live; else notShowLocation">
                <div> {{program.location.name}}</div>
                <div>{{program.location.address}}</div>
                <div>
                  <span *ngIf="program.location.city">{{program.location.city}}, </span>{{program.location.state}}
                  {{program.location.zipcode}}
                </div>
              </div>
              <ng-template #notShowLocation>
                <div>
                  Virtual Program
                </div>
              </ng-template>
            </div>
            <div>
              <label>SPEAKER</label>
              <ng-container *ngFor="let speaker of program.speakers;">
                <div class="speaker">
                  <div>{{speaker.name}}, {{speaker.degree}}</div>
                  <div>{{speaker.institution}}</div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="program.event_type_sub_code !== 'BRDPGM'">
              <label>SALES REP</label>
              <div *ngIf="program.sf_members.email">
                <div>{{program.sf_members.lastname}}
                  , {{program.sf_members.firstname}} {{program.sf_members.midname}} </div>
                <div>{{program.sf_members.email}}</div>
                <div>{{program.sf_members.phone}}</div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
