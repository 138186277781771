<style type="text/css">
  label {
    width: 100%;
  }

  #submit_buttons .btn {
    border-radius: 0;
  }

  #submit_buttons .btn-warn {
    margin: 8px;
    background-color: #bfbfbf !important;
    border-color: #bfbfbf;
  }

  #submit_buttons .btn-primary {
    background: #5f63b3;
    color: #fff !important;
  }

  .invalid_emails {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  #register h3 {
    font-weight: 500;
    color: #000;
    font-size: 17px;
  }

  .agree_check, .agree_text {
    margin: 0;
  }

  .agree_text {
    max-width: 95%;
  }

  @media only screen and (max-width: 450px) {
    .agree_text {
      max-width: 90%;
    }
  }
</style>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program || loading"><i class="fa fa-spinner fa-spin"> </i></div>
  <div class="row" *ngIf="program && !loading">
    <div class="col-12">
      <h5> {{program.meeting_date}} {{program.meeting_time}} {{program.timezone}}</h5>
      <h5 *ngIf="program.event_type === 'Webcast'">{{program.alternate_time_zones}}</h5>
    </div>
    <div class="col-12">
      <form name="form" (ngSubmit)="registerBotox(f.form)" class="row" #f="ngForm" novalidate>
        <div class="col-12 col-md-9">
          <div class="mr-md-5">
            <div class="col-12 alert alert-danger" id="failed_register" *ngIf="register_failed"> {{errorMsg}} </div>
            <div class="info ml-0">
              *Indicates a required field
            </div>
            <div class="text-danger" *ngIf="(f.submitted && f.form.invalid ) || different_email">
              Oops... Something is not quite right. Please correct the fields in red:
            </div>
            <div class="form-row">
              <div class="form-group col-xs-12 col-sm-6">
                <label for="firstname">First Name*</label>
                <input type="text" class="form-control" name="firstname" id="firstname"
                       [(ngModel)]="registerModel.firstname" pattern ="^[A-Za-z\-']+$" #firstname="ngModel"
                       [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }" required/>
                <div *ngIf="f.submitted && firstname.invalid" class="invalid-feedback">
                  <div *ngIf="firstname.errors.required">Is missing and required</div>
                  <div *ngIf="firstname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <label for="lastname">Last Name*</label>
                <input type="text" class="form-control" name="lastname" id="lastname"
                       [(ngModel)]="registerModel.lastname" pattern ="^[A-Za-z\-']+$"
                       #lastname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }" required/>
                <div *ngIf="f.submitted && lastname.invalid" class="invalid-feedback">
                  <div *ngIf="lastname.errors.required">Is missing and required</div>
                  <div *ngIf="lastname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-xs-12 col-sm-6">
                <label for="email">Email Address*</label>
                <input type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" name="email" id="email" [(ngModel)]="registerModel.email"
                       #email="ngModel"
                       [ngClass]="{ 'is-invalid': f.submitted && (email.invalid ||  registerModel.email != registerModel.confirm_email || different_email) }"
                       required email/>
                <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                  <div *ngIf="email.errors.required">Is missing and required</div>
                  <div *ngIf="email.errors.email || email.errors.pattern">Email must be a valid email address</div>
                  <div *ngIf="different_email">Confirm Email must match Email Address</div>
                </div>
                <div *ngIf="f.submitted && registerModel.email != registerModel.confirm_email" class="invalid_emails">
                  <div>Email addresses are not same</div>
                </div>
              </div>
              <div class="form-group col-xs-12 col-sm-6">
                <label for="email">Confirm Email Address*</label>
                <input type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" name="confirm_email" id="confirm_email"
                       [(ngModel)]="registerModel.confirm_email" #confirm_email="ngModel"
                       [ngClass]="{ 'is-invalid': f.submitted && (confirm_email.invalid ||  registerModel.email != registerModel.confirm_email) }"
                       required email/>
                <div *ngIf="f.submitted && confirm_email.invalid" class="invalid-feedback">
                  <div *ngIf="confirm_email.errors.required">Is missing and required</div>
                  <div *ngIf="confirm_email.errors.email || different_email">Confirm Email must match Email Address
                  </div>
                </div>
                <div *ngIf="f.submitted && registerModel.email != registerModel.confirm_email" class="invalid_emails">
                  <div>Email addresses are not same</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-xs-12 col-sm-6">
                <label for="phone">Phone Number</label>
                <input type="tel" class="form-control" name="phone" id="phone" [(ngModel)]="registerModel.phone"
                       #phone="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
                       [textMask]="{mask: mask}" pattern="\(\d{3}\) \d{3}\-\d{4}"/>
                <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                  <div *ngIf="phone.errors.pattern">Is invalid</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12 col-sm-6">
                <label for="additional_guests">Additional guests?</label>
                <select class="form-control" name="additional_guests" id="additional_guests"
                        [(ngModel)]="registerModel.guests_rsvpd" #additional_guests="ngModel">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row d-none d-sm-block">
            <div class="col-12 privacy-policy col-md-12">
              <p class="agree_text"><b>Privacy Notice: </b>AbbVie may collect your personal data through your online and offline interactions with us, including your contact data. 
                We may also collect your online usage data automatically through cookies and similar technologies. We use this data for several purposes, 
                such as to comply with our legal obligations, to perform a contract with you, to provide you with and improve our programs, 
                services, and products, to customize your experiences, and for research and analytics. We 
                retain your personal data for as long as necessary to fulfill these purposes or to comply with our 
                record retention obligations.  We do not sell your personal data, but we may use and disclose it to marketing 
                and advertising partners to deliver you ads based on your interests inferred from your activity across 
                other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out 
                of the use or disclosure of your personal data for online targeted advertising or for website 
                analytics, go to your <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy
                  Choices</a> on our website. For more information on the data categories we collect, the 
                purposes for their collection, our disclosures to third parties, your data subject rights, and 
                our data retention criteria, visit our <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" 
                target="_blank" class="privacy_policy">Privacy Notice</a>.
              </p>
              <p class="agree_text mt-3">
                Through my submission of the program enrollment form, I consent to the collection, use, and disclosure of my personal
                health data, as described in the Privacy Notice above and in AbbVie’s Privacy Notice in the
                <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html#h03 " target="_blank">“How We May Disclose
                Personal Data”</a> section. My consent is required to process sensitive personal data under certain privacy laws,
                and I have the right to withdraw my consent by visiting <a href="https://abbviemetadata.my.site.com/AbbvieDSRM"
                target="_blank">“Your Privacy Choices”</a> on AbbVie’s website.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3" id="right_bar">
          <app-map-location *ngIf="program" [location]='program.location' [meetingLive]="program.meeting_type_live"
                            [webPage]="webPage"></app-map-location>
          <app-speaker-list *ngIf="program" [speakers]="program.speakers" [title]="'Speaker'"
                            [webPage]="webPage"></app-speaker-list>
        </div>
        <div class="col-12 d-block d-sm-none">
          <div class="privacy-policy">
            <p class="agree_text"><b>Privacy Notice: </b>AbbVie may collect your personal data through your online and offline interactions with us, including your contact data. 
              We may also collect your online usage data automatically through cookies and similar technologies. We use this data for several purposes, 
              such as to comply with our legal obligations, to perform a contract with you, to provide you with and improve our programs, 
              services, and products, to customize your experiences, and for research and analytics. We 
              retain your personal data for as long as necessary to fulfill these purposes or to comply with our 
              record retention obligations.  We do not sell your personal data, but we may use and disclose it to marketing 
              and advertising partners to deliver you ads based on your interests inferred from your activity across 
              other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out 
              of the use or disclosure of your personal data for online targeted advertising or for website 
              analytics, go to your <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy
                Choices</a> on our website. For more information on the data categories we collect, the 
              purposes for their collection, our disclosures to third parties, your data subject rights, and 
              our data retention criteria, visit our <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" 
              target="_blank" class="privacy_policy">Privacy Notice</a>.
            </p>
            <p class="agree_text mt-3">
              Through my submission of the program enrollment form, I consent to the collection, use, and disclosure of my personal
              health data, as described in the Privacy Notice above and in AbbVie’s Privacy Notice in the
              <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html#h03 " target="_blank">“How We May Disclose
              Personal Data”</a> section. My consent is required to process sensitive personal data under certain privacy laws,
              and I have the right to withdraw my consent by visiting <a href="https://abbviemetadata.my.site.com/AbbvieDSRM"
              target="_blank">“Your Privacy Choices”</a> on AbbVie’s website.
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group text-center" id="submit_buttons" *ngIf="!registered">
            <button type="button" class="btn btn-warn" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-success">
              <span *ngIf="!registering">Submit</span>
              <i class="fa fa-spinner fa-spin" *ngIf="registering"> </i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
