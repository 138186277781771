import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sf-members',
  templateUrl: './sf-members.component.html'
})
export class SFMembersComponent implements OnInit {
  @Input('rep') rep;
  @Input('title') title;
  @Input('webPage') webPage;
  @Input('formatPhone') formatPhone = false;

  constructor() {
  }

  ngOnInit() {
  }

  formatPhoneNumber(phone: string) {
    if (this.formatPhone) {
      return phone.replace(/[()]/g, '').replace(' ', '-');
    } else {
      return phone;
    }
  }
}
