import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';

@Component({
  selector: 'app-speaker-modal',
  styleUrls: ['./speaker-modal.component.css'],
  templateUrl: './speaker-modal.component.html'
})
export class SpeakerModalComponent implements OnInit {
  @Input('speaker') speaker;
  @Input('webPage') webPage;
  @Input() showModal;
  @Input() currentModal;
  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2) {
  }

  onCloseBtnClick() {
    this.showModal = false;
    this.hideModal.emit(this.showModal);
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit() {
  }
}
