import {AfterViewChecked, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';

import {fromEvent, Subscription} from 'rxjs';
import * as $ from 'jquery';
import {CookieService} from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';

@Component({
  selector: 'app-cookie-consent',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-cookie-consent *ngSwitchCase="'vraylar'"></vraylar-cookie-consent>
      <migraine-cookie-consent *ngSwitchCase="'migraine'"></migraine-cookie-consent>
      <vuity-cookie-consent *ngSwitchCase="'vuity'"></vuity-cookie-consent>
      <botox-cookie-consent *ngSwitchCase="'botox'"></botox-cookie-consent>
    </ng-container>`
})
export class CookieConsentComponent implements OnInit {
  webPage = null;
  cookieExpire = 60;

  constructor(protected cookieService: CookieService,
              private _configService: ConfigService) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.cookieExpire = data.client.cookie_expire_days;
          this.webPage = data.client.webpage;
        },
        (error) => {
          this.cookieExpire = 60;
          this.webPage = null;
        }
      );
  }

  setCookie() {
    if(!environment.production){
      this.cookieService.set('cookieConsent', 'accepted', this.cookieExpire, '/', '', false, 'Lax');
    } else {
      this.cookieService.set('cookieConsent', 'accepted', this.cookieExpire);
    }
  }
}

@Component({
  selector: 'vraylar-cookie-consent',
  templateUrl: '../ui/vraylar/cookie-consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VraylarCookieConsentComponent extends CookieConsentComponent
  implements AfterViewChecked, OnDestroy {
  showCookieConsent = false;
  showReadMoreButton = true;
  showReadLessButton = false;
  resizeSubscription$: Subscription;
  resizeTimer;

  ngOnInit() {
    if (!this.cookieService.check('cookieConsent')) {
      this.showCookieConsent = true;
    }
    this.resizeSubscription$ = fromEvent(window, 'resize').subscribe(event => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(function () {
        this.updateFooterHeight();
      }.bind(this), 250);
    });
  }

  ngAfterViewChecked(): void {
    this.updateFooterHeight();
  }

  manageContent() {
    if (this.showReadMoreButton) {
      this.showReadMoreButton = false;
      this.showReadLessButton = true;
    } else {
      this.showReadMoreButton = true;
      this.showReadLessButton = false;
    }
  }

  updateFooterHeight() {
    const cookieSectionHeight = $('#cookie_consent_section').outerHeight();
    $('#safety_information').css({'margin-bottom': cookieSectionHeight});
  }

  onAcceptBtnClick() {
    this.setCookie();
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}

@Component({
  selector: 'migraine-cookie-consent',
  templateUrl: '../ui/migraine/cookie-consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MigraineCookieConsentComponent extends CookieConsentComponent implements AfterViewChecked, OnDestroy {
  showCookieConsent = false;
  showReadMoreButton = true;
  showReadLessButton = false;
  resizeSubscription$: Subscription;
  resizeTimer;

  ngOnInit() {
    if (!this.cookieService.check('cookieConsent')) {
      this.showCookieConsent = true;
    }
    this.resizeSubscription$ = fromEvent(window, 'resize').subscribe(event => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(function () {
        this.updateFooterHeight();
      }.bind(this), 250);
    });
  }

  ngAfterViewChecked(): void {
    this.updateFooterHeight();
  }

  manageContent() {
    if (this.showReadMoreButton) {
      this.showReadMoreButton = false;
      this.showReadLessButton = true;
    } else {
      this.showReadMoreButton = true;
      this.showReadLessButton = false;
    }
  }

  updateFooterHeight() {
    const cookieSectionHeight = $('#cookie_consent_section').outerHeight();
    $('#migraine-footer').css({'margin-bottom': cookieSectionHeight});
  }

  onAcceptBtnClick() {
    this.setCookie();
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}

@Component({
  selector: 'vuity-cookie-consent',
  templateUrl: '../ui/vuity/cookie-consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VuityCookieConsentComponent extends CookieConsentComponent implements AfterViewChecked, OnDestroy {
  showCookieConsent = false;
  showReadMoreButton = true;
  showReadLessButton = false;
  resizeSubscription$: Subscription;
  resizeTimer;

  ngOnInit() {
    if (!this.cookieService.check('cookieConsent')) {
      this.showCookieConsent = true;
    }
    this.resizeSubscription$ = fromEvent(window, 'resize').subscribe(event => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(function () {
        this.updateFooterHeight();
      }.bind(this), 250);
    });
  }

  ngAfterViewChecked(): void {
    this.updateFooterHeight();
  }

  manageContent() {
    if (this.showReadMoreButton) {
      this.showReadMoreButton = false;
      this.showReadLessButton = true;
    } else {
      this.showReadMoreButton = true;
      this.showReadLessButton = false;
    }
  }

  updateFooterHeight() {
    const cookieSectionHeight = $('#cookie_consent_section').outerHeight();
    $('#vuity-footer').css({'margin-bottom': cookieSectionHeight});
  }

  onAcceptBtnClick() {
    this.setCookie();
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}

@Component({
  selector: 'ubrelvy-cookie-consent',
  templateUrl: '../ui/ubrelvy/cookie-consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UbrelvyCookieConsentComponent extends CookieConsentComponent implements AfterViewChecked, OnDestroy {
  showCookieConsent = false;
  showReadMoreButton = true;
  showReadLessButton = false;
  resizeSubscription$: Subscription;
  resizeTimer;

  ngOnInit() {
    if (!this.cookieService.check('cookieConsent')) {
      this.showCookieConsent = true;
    }
    this.resizeSubscription$ = fromEvent(window, 'resize').subscribe(event => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(function () {
        this.updateFooterHeight();
      }.bind(this), 250);
    });
  }

  ngAfterViewChecked(): void {
    this.updateFooterHeight();
  }

  manageContent() {
    if (this.showReadMoreButton) {
      this.showReadMoreButton = false;
      this.showReadLessButton = true;
    } else {
      this.showReadMoreButton = true;
      this.showReadLessButton = false;
    }
  }

  updateFooterHeight() {
    const cookieSectionHeight = $('#cookie_consent_section').outerHeight();
    $('#ubrelvy-footer').css({'margin-bottom': cookieSectionHeight + 95});
    $('#safety_information').css({'margin-bottom': cookieSectionHeight});
  }

  onAcceptBtnClick() {
    this.setCookie();
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}

@Component({
  selector: 'qulipta-cookie-consent',
  templateUrl: '../ui/qulipta/cookie-consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class QuliptaCookieConsentComponent extends CookieConsentComponent implements AfterViewChecked, OnDestroy {
  showCookieConsent = false;
  showReadMoreButton = true;
  showReadLessButton = false;
  resizeSubscription$: Subscription;
  resizeTimer;

  ngOnInit() {
    if (!this.cookieService.check('cookieConsent')) {
      this.showCookieConsent = true;
    }
    this.resizeSubscription$ = fromEvent(window, 'resize').subscribe(event => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(function () {
        this.updateFooterHeight();
      }.bind(this), 250);
    });
  }

  ngAfterViewChecked(): void {
    this.updateFooterHeight();
  }

  manageContent() {
    if (this.showReadMoreButton) {
      this.showReadMoreButton = false;
      this.showReadLessButton = true;
    } else {
      this.showReadMoreButton = true;
      this.showReadLessButton = false;
    }
  }

  updateFooterHeight() {
    const cookieSectionHeight = $('#cookie_consent_section').outerHeight();
    $('#qulipta-footer').css({'margin-bottom': cookieSectionHeight});
    $('#safety_information').css({'margin-bottom': cookieSectionHeight});
  }

  onAcceptBtnClick() {
    this.setCookie();
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}

@Component({
  selector: 'botox-cookie-consent',
  templateUrl: '../ui/botox/cookie-consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BotoxCookieConsentComponent extends CookieConsentComponent implements AfterViewChecked, OnDestroy {
  showCookieConsent = false;
  showReadMoreButton = true;
  showReadLessButton = false;
  resizeSubscription$: Subscription;
  resizeTimer;

  ngOnInit() {
    if (!this.cookieService.check('cookieConsent')) {
      this.showCookieConsent = true;
    }
    this.resizeSubscription$ = fromEvent(window, 'resize').subscribe(event => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(function () {
        this.updateFooterHeight();
      }.bind(this), 250);
    });
  }

  ngAfterViewChecked(): void {
    this.updateFooterHeight();
  }

  manageContent() {
    if (this.showReadMoreButton) {
      this.showReadMoreButton = false;
      this.showReadLessButton = true;
    } else {
      this.showReadMoreButton = true;
      this.showReadLessButton = false;
    }
  }

  updateFooterHeight() {
    const cookieSectionHeight = $('#cookie_consent_section').outerHeight();
    $('#safety_information').css({'margin-bottom': cookieSectionHeight});
  }

  onAcceptBtnClick() {
    this.setCookie();
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}
