<style>
  /* Dual Speaker Heading */
  .dual_speaker_sec {
    background: #ef5e31;
    padding: 15px 0;
  }

  .dual_speaker_content {
    width: 100%;
  }

  .dual_speaker_content h1 {
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 10px 5px;
  }

  /* Home New Banner */
  .register_banner_sec {
    position: relative;
    margin-bottom: 229px;
  }

  .register_banner_sec figure {
    position: relative;
    padding-bottom: 48%;
    margin: 0;
  }

  .register_banner_sec figure img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .home_register {
    position: absolute;
    bottom: -100px;
    right: 80px;
    padding: 0;
  }

  .home_register a {
    padding: 15px 36px;
    color: #ef5e31;
    text-transform: uppercase;
    font-weight: 500;
    border: solid 3px #fff;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .home_register a:hover {
    text-decoration: none;
    background-color: #fff;
    color: #ef5e31;
    border-color: #fff;
  }

  @media only screen and (min-width: 1250px) {
    .register_banner_sec figure {
      padding-bottom: 48%;
    }

    .home_register a {
      font-size: 47px;
    }
  }

  @media only screen and (max-width: 1250px) and (min-width: 991px) {
    .register_banner_sec figure {
      padding-bottom: 48%;
    }

    .home_register a {
      font-size: 30px;
      position: relative;
      right: -15px;
    }

    .home_register {
      bottom: -70px;
    }
  }

  @media only screen and (max-width: 991px) and (min-width: 850px) {
    .home_register a {
      font-size: 28px;
      position: relative;
      padding: 13px 30px;
      right: -18px;
    }

    .home_register {
      bottom: -60px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 850px) {
    .home_register a {
      font-size: 25px;
      padding: 13px 24px;
      position: relative;
      right: -20px;
    }

    .home_register {
      bottom: -55px;
    }
  }

  @media only screen and (min-width: 680px) and (max-width: 768px) {
    .home_register a {
      font-size: 20px;
      padding: 10px 20px;
      position: relative;
      right: -30px;
    }

    .home_register {
      bottom: -45px;
    }
  }

  @media only screen and (min-width: 500px) and (max-width: 680px) {
    .home_register a {
      font-size: 15px;
      padding: 10px 15px;
      position: relative;
      right: -40px;
    }

    .home_register {
      bottom: -35px;
    }
  }

  @media only screen and (min-width: 450px) and (max-width: 540px) {
    .home_register a {
      font-size: 10px;
      padding: 10px 10px;
      position: relative;
      right: -40px;
      border: solid 2px #fff;
    }

    .home_register {
      bottom: -30px;
    }
  }

  @media only screen and (min-width: 390px) and (max-width: 450px) {
    .register_banner_sec {
      margin-bottom: 50px;
    }

    .home_register a {
      font-size: 10px;
      padding: 8px;
      position: relative;
      right: -45px;
      border: solid 1px #fff;
    }

    .home_register {
      bottom: -25px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .home_register[_ngcontent-c10] {
      bottom: -15px;
      left: 50px;
    }
  }

  @media only screen and (max-width: 768px) {
    .dual_speaker_content h1 {
      font-size: 16px;
    }

    .home_register[_ngcontent-c10] {
      left: 20px;
    }
  }

  @media only screen and (max-width: 390px) {
    .register_banner_sec {
      margin-bottom: 50px;
    }

    .home_register a {
      font-size: 8px;
      padding: 5px;
      position: relative;
      right: -50px;
      border: solid 0.5px #fff
    }

    .home_register {
      bottom: -20px;
    }
  }


</style>
<!-- Header End -->
<section class="dual_speaker_sec bg-orange">
  <div class="container">
    <div class="row">
      <div class="dual_speaker_content">
        <h1>
          A MIGRAINE PORTFOLIO PRESENTATION: TREATMENT AND PREVENTION OF MIGRAINE ATTACKS
        </h1>
      </div>
    </div>
  </div>
</section>
<!-- dual_speaker_sec -->
<section class="register_banner_sec">
  <figure>
    <img src="../../../assets/migraine/images/index-banner.jpg" class="img-fluid" alt="home banner"
         title="Register Now">
  </figure>
  <div class="container">
    <div class="row">
      <div class="home_register">
        <a routerLink="/home">Register Now</a>
      </div>
    </div>
  </div>
</section>
<app-savings-information></app-savings-information>
