import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {ConfigService} from '../config/config.service';
import {Config} from '../config/config';
import {AttendeeService} from '../services/index';

@Component({
    selector: 'app-ondemand-program',
    template: `
      <ng-container [ngSwitch]="webPage">
        <botox-ondemand-program *ngSwitchCase="'botox'"></botox-ondemand-program>
      </ng-container>`
  })
export class OnDemandProgramComponent implements OnInit {
  showModal: boolean = false;
  webPage;
  constructor(
    protected _configService: ConfigService,
    protected _attendeeService: AttendeeService,
  ) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
        this.webPage = data.client.webpage;
      },
        (error) => this.webPage = null
      );
  }

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getConfig()
        .subscribe((data) => {
          this.webPage = data.client.webpage;
          if (this.webPage) {
            this._attendeeService.setOnDemandMeetingLists(this.webPage, {})
              .finally(() => {
              })
              .subscribe(success => {
              },
                (error) => {
                }
              );
          }
        });
    });
  }
}

@Component({
    selector: 'botox-ondemand-program',
    templateUrl: '../ui/botox/ondemand-program.component.html',
  })
  export class BotoxOnDemandProgramComponent extends OnDemandProgramComponent {
    program:any;
    selectedProgram:any;

    ngOnInit() {
      this.initializeClient().subscribe(() => {
      });
      this.getMeetingList();
    }

    getMeetingList() {
      this._attendeeService.getOnDemandMeetingLists().subscribe(response => {
        this.program = response['meetings'] && response['meetings'][0] ? response['meetings'][0] : {};
      });
    }

    playVideo(program:any) {
      this.showModal = true;
      this.selectedProgram = program;
    }

    closeModal(){
      this.showModal = false;
    }
}
