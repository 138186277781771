import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, Renderer2, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Meeting, FormattedAddress} from '../interfaces/index';
import {ConfigService} from '../config/config.service';
import {APIService, AttendeeService} from '../services';
import {Config} from '../config/config';
import {Meta, Title} from '@angular/platform-browser';
import {SignaturePadComponent} from '../signature-pad/signature-pad.component';
import {AnalyticsService} from '../services/analytics.service';
import {NgModel} from '@angular/forms';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {AddressService} from '../services/address.service';

@Component({
  selector: 'app-register-form',
  template: `
    <ng-container [ngSwitch]="webPage">
      <migraine-register-form *ngSwitchCase="'migraine'"></migraine-register-form>
      <vuity-register-form *ngSwitchCase="'vuity'"></vuity-register-form>
      <vraylar-register-form *ngSwitchCase="'vraylar'"></vraylar-register-form>
    </ng-container>`
})

export class RegisterFormComponent implements OnInit {
  states: Array<any>;
  degrees: Array<any>;
  licensestates: Array<any>;
  specialties: Array<any>;
  webPage;
  program_id: String;
  program: Meeting;

  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  // status flags;
  registered = false;
  registering = false;
  firstInitiation = true;
  loading;
  confirmAttendance = false;
  addressErrors = [];
  cityErrorMessage: string;

  constructor(
    protected _activeRouter: ActivatedRoute,
    protected _router: Router,
    protected _configService: ConfigService,
    protected _apiService: APIService,
    protected _attendeeService: AttendeeService,
    protected changeDetector: ChangeDetectorRef,
    private meta: Meta,
    protected titleService: Title,
    protected _analyticsService: AnalyticsService,
    protected renderer: Renderer2,
    protected element: ElementRef,
    protected addressService: AddressService
  ) {
  }

  ngOnInit() {
    this._configService.getWebPage()
      .subscribe((data) => {
          this.webPage = data;
        },
        (error) => this.webPage = null
      );
    this._configService.getConfig()
      .subscribe((data: Config) => {
        if (data.client.has_meta_description) {
          this.meta.updateTag({name: 'description', content: data.client.registration_meta_description});
        }
      });
  }

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getWebPage()
        .subscribe((data) => {
            this.webPage = data;

            this._activeRouter.params
              .subscribe(params => {
                  this.program_id = params['program_id'];
                  this.confirmAttendance = params['signature_status'] != null ? params['signature_status'] == 1 : false;
                  this.loading = true;
                  this._attendeeService.getCurrentProgram(this.program_id, {confirming_attendance: this.confirmAttendance})
                    .subscribe((program: Meeting) => {
                      this.program = program;
                      this.program_id = this.program.program_id;
                      this.loading = false;
                      observer.next(true);
                    });
                },
                error => {
                  console.log(error);
                  observer.error(false);
                });
          },
          (error) => {
            this.webPage = null;
            observer.error(false);
          }
        );
    });
  }


  getStates() {
    this._apiService.get(this.webPage + '/getStates', [])
      .subscribe(states => {
          this.states = states;
        }, (error) => {
          console.log('Problem getting states');
        }
      );
  }

  getDegrees() {
    this._apiService.get(this.webPage + '/getDegrees', [])
      .subscribe(degrees => {
          this.degrees = degrees;
        }, (error) => {
          console.log('Problem getting degrees');
        }
      );
  }

  getLicenseStates() {
    this._apiService.get(this.webPage + '/getStates', [])
      .subscribe(licensestates => {
          this.licensestates = licensestates;
        }, (error) => {
          console.log('Problem getting state licenses');
        }
      );
  }

  getSpecialties() {
    this._apiService.get(this.webPage + '/getSpecialties', [])
      .subscribe(specialties => {
          this.specialties = specialties;
        }, (error) => {
          console.log('Problem getting specialties');
        }
      );
  }

  validateCity(input: string): void {
    this.cityErrorMessage = '';
    if (input !== '') {
      if (!/^[A-Za-z\d\s,'.-]+$/.test(input)) {
        this.cityErrorMessage = 'Invalid - ~!@$%^&*()_+=`[]{}\|;:"<>? special characters not allowed';
      } else {
        this.cityErrorMessage = "";
      }
    }
  }

  validateAddress(input: string): void {
    this.addressErrors = [];
    if (input !== "") {
      if (/[^A-Za-z0-9\s,'.-]/.test(input)) {
        this.addressErrors.push('Invalid - ~!@$%^&*()_+=`[]{}\|;:"<>? special characters not allowed');
      }
      if ((!/[a-zA-Z]/.test(input)) || (!/\d/.test(input))) {
        this.addressErrors.push("Invalid - enter at least one number and one letter.");
      }
    } else {
      this.addressErrors = [];
    }
  }
}

@Component({
  selector: 'migraine-register-form',
  templateUrl: '../ui/migraine/register-form.component.html'
})

export class MigraineRegisterFormComponent extends RegisterFormComponent implements AfterViewChecked {
  show_degree_other: Boolean = false;
  show_specialty_other: Boolean = false;
  errorPhoneNumber = false;
  errorPhoneMsg = '';
  attendeeModel = {
    'firstname': null,
    'lastname': null,
    'midname': null,
    'affiliation': null,
    'address1': null,
    'address2': "",
    'zip': null,
    'state': null,
    'city': null,
    'email': null,
    'phone': null,
    'degree': null,
    'degreeother': null,
    'licensestate': null,
    'statelicense': null,
    'specialty': null,
    'specialtyother': null,
    'npi': null,
    'phone_number': '',
    'sms_opt_in': '',
    'message_confirmation': false
  };

  degeeDropdownSettings = {
    singleSelection: true,
    text: 'Select Degree',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'desc',
    badgeShowLimit: 1
  };

  SpecialtydropdownSettings = {
    singleSelection: false,
    text: 'Select Specialty',
    enableCheckAll: false,
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'desc',
    badgeShowLimit: 1
  };

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
      this.getStates();
      this.getDegrees();
      this.getLicenseStates();
      this.getSpecialties();
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  resetFields(){
    this.attendeeModel.city = '';
    this.attendeeModel.state = '';
    this.attendeeModel.zip = '';
  }

  handleAddressChange(address: Address) {
    const formattedAddress: FormattedAddress = this.addressService.getFormattedAddress(address);
    this.attendeeModel.address1 = '';
    this.changeDetector.detectChanges();

    this.attendeeModel.address1 = `${formattedAddress.street_number} ${formattedAddress.address1}`;
    this.attendeeModel.address1 = this.attendeeModel.address1.trim();
    this.attendeeModel.city = formattedAddress.city;
    this.attendeeModel.state = formattedAddress.state;
    this.attendeeModel.zip = formattedAddress.zip;

    this.validateAddress(this.attendeeModel.address1);
    this.validateCity(this.attendeeModel.city);

    const cityInput = this.element.nativeElement.querySelector('input[name="city"]');
    const zipInput = this.element.nativeElement.querySelector('input[name="zip"]');

    this.renderer.selectRootElement(cityInput).focus();
    this.renderer.selectRootElement(zipInput).focus();
  }

  checkMessageConfirmation() {
    this.errorPhoneNumber = false;
    if (this.attendeeModel.message_confirmation) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter Mobile Phone Number';
    } else {
      this.attendeeModel.phone_number = '';
      this.errorPhoneMsg = '';
    }
  }

  checkPhoneNumber(phone) {
    if (phone.value === '') {
      this.errorPhoneNumber = true;
    } else if (phone.invalid) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter a Valid Mobile Phone Number';
    } else {
      this.errorPhoneNumber = false;
      this.errorPhoneMsg = '';
    }
  }

  cancel() {
    this._router.navigate(['home']);
  }

  registerMigraine(form) {
    if (form.invalid || this.errorPhoneNumber || this.addressErrors.length > 0 || this.cityErrorMessage !== "") {
      return;
    }

    if (this.attendeeModel.message_confirmation) {
      this.attendeeModel.sms_opt_in = '1';
    } else {
      this.attendeeModel.sms_opt_in = '0';
    }

    this.registering = true;
    this._apiService.post(this.webPage + '/meetings/' + this.program_id + '/createAttendee', this.attendeeModel)
      .subscribe(attendeeData => {
          this.registering = false;
          this._router.navigate(['confirm/' + this.program_id + '/1']);
        }, (error) => {
          this.registering = false;
        }
      );
  }

  onDegreeChange() {
    const checkOtherField = this.attendeeModel.degree.find(item => item.abbrv === 'OTH');
    this.show_degree_other = checkOtherField !== undefined;
  }

  onSpecialtyChange() {
    const checkOtherField = this.attendeeModel.specialty.find(item => item.abbrv === 'OTH');
    this.show_specialty_other = checkOtherField !== undefined;
  }
}

@Component({
  selector: 'vuity-register-form',
  templateUrl: '../ui/vuity/register-form.component.html'
})

export class VuityRegisterFormComponent extends RegisterFormComponent implements AfterViewChecked {
  show_degree_other = false;
  show_specialty_other = false;
  license_required = false;
  errorPhoneNumber = false;
  errorPhoneMsg = '';
  attendeeModel = {
    'firstname': null,
    'lastname': null,
    'midname': null,
    'affiliation': null,
    'address1': null,
    'address2': "",
    'zip': null,
    'state': null,
    'city': null,
    'email': null,
    'phone': null,
    'degree': null,
    'degreeother': null,
    'licensestate': null,
    'statelicense': null,
    'specialty': null,
    'specialtyother': null,
    'npi': null,
    'rsvp_signature': '',
    'phone_number': '',
    'sms_opt_in': '',
    'message_confirmation': false
  };

  degeeDropdownSettings = {
    singleSelection: true,
    text: 'Select Degree',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'desc',
    badgeShowLimit: 1
  };

  SpecialtydropdownSettings = {
    singleSelection: false,
    text: 'Select Specialty',
    unSelectAllText: 'UnSelect All',
    enableCheckAll: false,
    enableSearchFilter: true,
    labelKey: 'desc',
    badgeShowLimit: 1
  };

  validSignature = false;
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  signaturePadOptions = {
    'minWidth': 1,
    'canvasWidth': window.innerWidth > 768 ? 500 : 350,
    'canvasHeight': 170,
    'backgroundColor': 'rgb(255, 255, 255)'
  };

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
      this.getStates();
      this.getDegrees();
      this.getLicenseStates();
      this.getSpecialties();
      this.track();
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  resetFields(){
    this.attendeeModel.city = '';
    this.attendeeModel.state = '';
    this.attendeeModel.zip = '';
  }

  handleAddressChange(address: Address) {
    const formattedAddress: FormattedAddress = this.addressService.getFormattedAddress(address);
    this.attendeeModel.address1='';
    this.changeDetector.detectChanges();

    this.attendeeModel.address1 = `${formattedAddress.street_number} ${formattedAddress.address1}`;
    this.attendeeModel.address1 = this.attendeeModel.address1.trim();
    this.attendeeModel.city = formattedAddress.city;
    this.attendeeModel.state = formattedAddress.state;
    this.attendeeModel.zip = formattedAddress.zip;

    this.validateAddress(this.attendeeModel.address1);
    this.validateCity(this.attendeeModel.city);

    const cityInput = this.element.nativeElement.querySelector('input[name="city"]');
    const zipInput = this.element.nativeElement.querySelector('input[name="zip"]');

    this.renderer.selectRootElement(cityInput).focus();
    this.renderer.selectRootElement(zipInput).focus();
  }

  track() {
    const analyticObject = {
      'pageInfo': {
        'domain': window.location.hostname,
        'subDomain': '',
        'url': window.location.href,
        'path': window.location.pathname,
        'pageName': 'Register Event: New HCP',
        'title': this.titleService.getTitle(),
        'prefix': 'vuitylive'
      },
      'category': {
        'primaryCategory': 'Register',
        'subCategory1': 'New HCP',
        'subCategory2': this.program_id.toString()
      },
      'attributes': {
        'country': 'US',
        'language': 'en-US',
        'template': '',
        'promoMatsNumber': 'US-VUI-210146',
        'globalISI': '',
        'type': 'Register'
      }
    };

    this._analyticsService.updateStateObject(analyticObject);
  }

  getLicenseStates() {
    this._apiService.get(this.webPage + '/getStates', [])
      .subscribe(licensestates => {
          this.licensestates = licensestates;
          this.licensestates.push({
            'name': 'N/A',
            'state_alpha2': 'N/A',
          });
        }, (error) => {
          console.log('Problem getting state licenses');
        }
      );
  }

  onFieldChange(field: NgModel, fieldName, isPII = false) {
    let fieldValue = '',
      fieldError = '',
      stepName = '';
    if (!isPII) {
      fieldValue = field.value;
    }

    if (field.invalid) {
      if (field.errors.required) {
        fieldError = field.name + ' is required';
      } else {
        fieldError = field.name + ' is invalid';
      }
    }

    if (this.firstInitiation) {
      stepName = 'initiation';
    }

    this.firstInitiation = false;
    const analyticObject = {
      link: {
        name: fieldName,
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: fieldName,
        name: 'Register for Event',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Form',
        name: ''
      },
      form: {
        name: 'Event Registration',
        category: 'registration',
        subCategory: 'event :' + this.program.program_id,
        fieldName: fieldName,
        fieldValue: fieldValue,
        fieldValues: '',
        stepName: stepName,
        stepNumber: '',
        error: fieldError
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  linkInteraction(title, type) {
    const analyticObject = {
      link: {
        name: title,
        displayTitle: '',
        type: type,
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: title,
        name: 'Register for Event',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Link',
        name: ''
      },
      form: {
        name: '',
        category: '',
        subCategory: '',
        fieldName: '',
        fieldValue: '',
        fieldValues: '',
        stepName: '',
        stepNumber: '',
        error: ''
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  clearSignature() {
    this.signaturePad.clear();
    this.validSignature = false;
  }

  drawStart() {
    this.validSignature = true;
  }

  checkMessageConfirmation() {
    this.errorPhoneNumber = false;
    if (this.attendeeModel.message_confirmation) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter Phone Number';
    } else {
      this.attendeeModel.phone_number = '';
      this.errorPhoneMsg = '';
    }
  }

  checkPhoneNumber(phone) {
    if (phone.value === '') {
      this.errorPhoneNumber = true;
    } else if (phone.invalid) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter a Valid Phone Number';
    } else {
      this.errorPhoneNumber = false;
      this.errorPhoneMsg = '';
    }
  }

  cancel() {
    this._router.navigate(['home']);
  }

  registerVuity(form) {
    if (form.invalid || this.errorPhoneNumber || this.addressErrors.length > 0 || this.cityErrorMessage !== "") {
      return;
    }
    if (this.signaturePad.isEmpty()) {
      return;
    }

    if (this.attendeeModel.message_confirmation) {
      this.attendeeModel.sms_opt_in = '1';
    } else {
      this.attendeeModel.sms_opt_in = '0';
    }

    this.registering = true;
    this.attendeeModel.rsvp_signature = this.signaturePad.toDataURL().split('data:image/png;base64,')[1];
    this._apiService.post(this.webPage + '/meetings/' + this.program_id + '/createAttendee', this.attendeeModel)
      .subscribe(attendeeData => {
          this.registering = false;
          this._router.navigate(['confirm/' + this.program_id + '/1']);
        }, (error) => {
          this.registering = false;
        }
      );
  }

  onDegreeChange(field: NgModel, fieldName) {
    this.onFieldChange(field, fieldName);
    const checkOtherField = this.attendeeModel.degree.find(item => item.abbrv === 'OTH');
    this.show_degree_other = checkOtherField !== undefined;
    const checkLicenseState = this.attendeeModel.degree.find(item => item.license_required === true);
    this.license_required = checkLicenseState !== undefined;
  }

  onSpecialtyChange(field: NgModel, fieldName) {
    this.onFieldChange(field, fieldName);
    const checkOtherField = this.attendeeModel.specialty.find(item => item.abbrv === 'OTH');
    this.show_specialty_other = checkOtherField !== undefined;
  }
}

@Component({
  selector: 'vraylar-register-form',
  templateUrl: '../ui/vraylar/register-form.component.html'
})

export class VraylarRegisterFormComponent extends RegisterFormComponent implements AfterViewChecked {
  show_degree_other: Boolean = false;
  show_specialty_other: Boolean = false;
  attendeeModel = {
    'firstname': null,
    'lastname': null,
    'midname': null,
    'affiliation': null,
    'address1': null,
    'address2': "",
    'zip': null,
    'state': null,
    'city': null,
    'email': null,
    'phone': null,
    'degree': null,
    'degreeother': null,
    'licensestate': null,
    'statelicense': null,
    'specialty': null,
    'specialtyother': null,
    'npi': null,
    'rsvp_signature': null,
    'phone_number': null,
    'attended': null
  };

  validSignature = false;

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': window.innerWidth > 768 ? 500 : 350,
    'canvasHeight': 170,
    'backgroundColor': 'rgb(255, 255, 255)'
  };

  degeeDropdownSettings = {
    singleSelection: true,
    text: 'Select Degree',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'desc',
    badgeShowLimit: 1
  };

  SpecialtydropdownSettings = {
    singleSelection: false,
    text: 'Select Specialty',
    enableCheckAll: false,
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'desc',
    badgeShowLimit: 1
  };

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
      this.getStates();
      this.getDegrees();
      this.getLicenseStates();
      this.getSpecialties();
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  resetFields(){
    this.attendeeModel.city = '';
    this.attendeeModel.state = '';
    this.attendeeModel.zip = '';
  }

  handleAddressChange(address: Address) {
    const formattedAddress: FormattedAddress = this.addressService.getFormattedAddress(address);
    this.attendeeModel.address1='';
    this.changeDetector.detectChanges();

    this.attendeeModel.address1 = `${formattedAddress.street_number} ${formattedAddress.address1}`;
    this.attendeeModel.address1 = this.attendeeModel.address1.trim();
    this.attendeeModel.city = formattedAddress.city;
    this.attendeeModel.state = formattedAddress.state;
    this.attendeeModel.zip = formattedAddress.zip;

    this.validateAddress(this.attendeeModel.address1);
    this.validateCity(this.attendeeModel.city);

    const cityInput = this.element.nativeElement.querySelector('input[name="city"]');
    const zipInput = this.element.nativeElement.querySelector('input[name="zip"]');

    this.renderer.selectRootElement(cityInput).focus();
    this.renderer.selectRootElement(zipInput).focus();
  }
  
  cancel() {
    this._router.navigate(['home']);
  }

  clearSignature() {
    this.signaturePad.clear();
    this.validSignature = false;
  }

  drawStart() {
    this.validSignature = true;
  }

  registerVraylar(form) {
    if (form.invalid || this.addressErrors.length > 0 || this.cityErrorMessage !== "") {
      return;
    }

    if(this.confirmAttendance){
      if (this.signaturePad.isEmpty()) {
        return;
      }
      this.attendeeModel.rsvp_signature = this.signaturePad.toDataURL().split('data:image/png;base64,')[1];
      this.attendeeModel.attended = true;
    }

    this.registering = true;
    this._apiService.post(this.webPage + '/meetings/' + this.program_id + '/createAttendee', this.attendeeModel)
      .subscribe(attendeeData => {
          this.registering = false;
          let confirmPage = this.confirmAttendance ? 2 : 1;
          this._router.navigate(['confirm/' + this.program_id + '/' + confirmPage]);
        }, (error) => {
          this.registering = false;
        }
      );
  }

  onDegreeChange() {
    const checkOtherField = this.attendeeModel.degree.find(item => item.abbrv === 'OTH');
    this.show_degree_other = checkOtherField !== undefined;
  }

  onSpecialtyChange() {
    const checkOtherField = this.attendeeModel.specialty.find(item => item.abbrv === 'OTH');
    this.show_specialty_other = checkOtherField !== undefined;
  }
}
