import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';

import {ConfigService} from '../config/config.service';
import {Meeting, Speaker} from '../interfaces';

@Component({
  selector: 'app-single-program',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-single-program *ngSwitchCase="'vraylar'"></vraylar-single-program>
      <migraine-single-program *ngSwitchCase="'migraine'"></migraine-single-program>
      <vuity-single-program *ngSwitchCase="'vuity'"></vuity-single-program>
    </ng-container>`
})
export class SingleProgramComponent implements OnInit {
  @Input('program') program;
  @Input('canregister') canregister;
  @Input('webPage') webPage;
  @Input('showSpeakerImage') showSpeakerImage;
  @Output() register = new EventEmitter<Meeting>();
  showModal = false;
  currentModal: string;

  constructor(protected _configService: ConfigService, private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  registerNow(program) {
    this.register.emit(program);
  }

  onSpeakerModalClick(speaker_id) {
    this.currentModal = speaker_id;
    this.showModal = !this.showModal;
    this.renderer.addClass(document.body, 'modal-open');
  }

  trackBySpeakerId(index: number, speaker: Speaker) {
    return speaker.speaker_id;
  }

  hideModal(e) {
    this.showModal = e;
  }
}

/*
Vraylar Single Program Component Class
 */
@Component({
  selector: 'vraylar-single-program',
  templateUrl: '../ui/vraylar/single-program.component.html'
})

export class VraylarSingleProgramComponent extends SingleProgramComponent {
  superscriptTradeMark(str: string) {
    return str.replace('®', '<sup>®</sup>');
  }
}

/*
migraine Single Program Component Class
 */
@Component({
  selector: 'migraine-single-program',
  templateUrl: '../ui/migraine/single-program.component.html'
})

export class MigraineSingleProgramComponent extends SingleProgramComponent {
}

@Component({
  selector: 'vuity-single-program',
  templateUrl: '../ui/vuity/single-program.component.html'
})

export class VuitySingleProgramComponent extends SingleProgramComponent {
}
