<style>
  #infoModal {
    background: rgba(0, 0, 0, 0.8) !important;
  }

  #infoModal .modal-content {
    background-color: #071d49;
    color: #ffffff;
  }

  #infoModal .modal-header {
    background-color: #071d49;
    border-color: #071d49;
  }

  #infoModal .modal-header .close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .modal-dialog {
    max-width: 640px;
  }

  .modal-header-image {
    width: 50%;
    margin: 30px auto auto;
  }

  .modal-header-image img {
    height: 55px;
  }

  .modal-body {
    padding: 15px 30px;
    text-align: center;
  }

  .modal-body a {
    color: #2f9ece;
  }

  .info-modal-xs {
    background-color: #071d49;
    color: #ffffff;
  }

  .info-modal-xs .read-more-button:hover,
  .info-modal-xs .read-less-button:hover {
    cursor: pointer;
    border-bottom: 1px solid #000000;
  }
</style>
<!-- Modal -->
<div id="infoModal" tabindex="-1" role="dialog" aria-labelledby="infoModal" aria-hidden="true"
     [ngClass]="showModal ? 'modal fade show d-none d-sm-block'  : 'modal fade hide'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-image">
          <img src="assets/migraine/images/abbvie-white.png" alt="Abbvie Logo">
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseBtnClick()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>AbbVie Inc. and Allergan plc are a <a href="https://www.abbvie.com/abbvie-allergan-overview.html" target="_blank"
          style="text-decoration: underline;">new combined company. </a>
          We will continue to honor any Allergan-provided privacy notices for personal
          information previously collected, used, and maintained by Allergan. Going forward,
          personal information will be processed by this website according to AbbVie's privacy notice<br/>
          <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank">https://privacy.abbvie/privacy-policies/us-privacy-policy.html</a>
        </p>
      </div>
    </div>
  </div>
</div>
<!-- Close modal -->
<div class="container d-block d-sm-none">
  <div class="row">
    <div class="col-12 info-modal-xs">
      <p>AbbVie Inc. and Allergan plc are a <a href="https://www.abbvie.com/abbvie-allergan-overview.html" target="_blank"
        style="text-decoration: underline;">new combined company.</a> We will continue to
        <span class="read-more-button" [ngClass]="showReadMoreButton ? 'd-inline' : 'd-none'"
              (click)="manageContent()">...[read more]
        </span>
        <span class="read-more" [ngClass]="!showReadMoreButton ? 'd-inline' : 'd-none'">
        honor any Allergan-provided privacy notices for personal
        information previously collected, used, and maintained by Allergan. Going forward,
        personal information will be processed by this website according to AbbVie's privacy notice<br/>
        <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank">https://privacy.abbvie/privacy-policies/us-privacy-policy.html</a>
        </span>
        <br/>
        <span class="read-less-button" [ngClass]="showReadLessButton ? 'd-inline' : 'd-none'"
              (click)="manageContent()">[read less]</span>
      </p>
    </div>
  </div>
</div>

