<style type="text/css">
  #vuity-footer {
    font-weight: 600;
    background: #364FCB;
    color: #fff;
  }

  #vuity-footer a{
    color: #fff;
  }

  #vuity-footer ul{
    padding-left: 0px;
  }

  #vuity-footer .abbvie-logo {
    width: 200px;
    margin-bottom: 10px;
  }

  #vuity-footer a:hover {
    color: #72cdd9
  }

  #vuity-footer .footer-content {
    padding: 45px 0
  }

  #vuity-footer .footer-content a {
    display: block;
    text-align: center;
  }

  #vuity-footer .footer-content ul {
    text-align: center;
    margin-bottom: 25px;
  }

  #vuity-footer .footer-content ul li {
    display: inline-block;
    padding: 0 20px;
  }

  #vuity-footer .footer-content ul li a {
    font-size: 16px !important;
    font-weight: 600;
    padding: 0 !important;
    border: none !important;
    background: none !important;
    color: #fff !important;
  }

  #vuity-footer .footer-content p {
    text-align: center;
    font-size: 14px;
  }

  #vuity-footer .footer-content p:last-child {
    margin-bottom: 0
  }
  .privacy-links li{
    display: inline-block;
  }

  @media only screen and (max-width: 767px) {
    #vuity-footer .footer-content {
      padding: 30px 0;
    }

    #vuity-footer .footer-content .abbvie-logo {
      width: 150px;
      margin-bottom: 15px;
    }

    #vuity-footer .footer-content ul {
      margin-bottom: 0;
    }

    #vuity-footer .footer-content ul li {
      margin-bottom: 10px;
    }

    #vuity-footer .footer-content p {
      margin-bottom: 10px
    }
  }
</style>
<footer id="vuity-footer">
  <div class="footer-content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <a href="https://www.abbvie.com" target="_blank">
            <img class="abbvie-logo" src="assets/vuity/images/abbvie-white.png"
                 alt="Abbvie Logo"/>
          </a>
          <ul class="privacy-links">
            <li>
              <a href="https://privacy.abbvie/" target="_blank">Privacy Policy</a>
            </li>
            <li>
              <a class="ot-sdk-show-settings" href="javascript:void(0)" id="ot-sdk-btn" title="Overridden by OneTrust">
                Cookies Settings
              </a>
            </li>
          </ul>
          <div>
            <p>VUITY&reg; and its design are registered trademarks of Allergan, Inc., an Abbvie company. All other trademarks are the property of their respective owners.</p>
            <p>&copy; 2021 AbbVie. All rights reserved.</p>
            <p>This information is intended for US healthcare professionals only. US-VUI-220499 10/22</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
