<style type="text/css">
  body {
    padding-bottom: 0;
  }

  #nav_bar {
    height: 38px;
    background-color: #071d49;
  }

  header#migraine_header {
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  header#migraine_header .navbar-brand {
    color: #fff;
    flex: 1 1 0;
    padding: 14px 0;
  }

  header#migraine_header .navbar-brand span {
    width: 180px;
    display: block;
    margin-left: 40px;
  }

  header#migraine_header .navbar-brand span.align-center {
    margin: auto !important;
  }

  header#migraine_header .navbar-brand img {
    width: 180px;
    display: block;
  }
</style>
<div *ngIf="route === '/index'" class="navbar navbar-dark" id="nav_bar"></div>
<header id="migraine_header">
  <div class="container">
    <div class="row">
      <!-- Brand/logo -->
      <a class="navbar-brand" [routerLink]="['/index']">
        <span [class.align-center]="route === '/index'">
          <img src="assets/migraine/images/abbvie-logo.svg" alt="Abbvie Logo">
        </span>
      </a>
    </div>
  </div>
</header>
