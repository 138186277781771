<style type="text/css">
  body {
    padding-bottom: 0;
  }

  header {
    margin-top: 15px;
  }

  picture img {
    width: 250px;
    max-width: 100%;
    height: auto;
  }

  h3.top_header {
    font-weight: 500;
  }

  @media (max-width: 500px) {
    picture img {
      width: 180px;
    }

    h3.top_header {
      font-size: 11px;
      margin-top: 10px;
    }
  }

</style>
<header class="container">
  <div class="header_section">
    <a [routerLink]="['/home']" target="_self">
      <picture>
        <img src="assets/botox/images/abbvie-logo.png" alt="Botox Logo"/>
      </picture>
    </a>
    <h3 class="top_header">Patient and Caregiver Education Events<br></h3>
  </div>
</header>
