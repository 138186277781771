import {Component, OnInit} from '@angular/core';
import {Config} from '../config/config';
import {LoadMoreObject, Meeting, SearchObject} from '../interfaces';
import {ConfigService} from '../config/config.service';
import {AttendeeService} from '../services';
import {ViewportScroller} from '@angular/common';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {AnalyticsService} from '../services/analytics.service';

@Component({
  selector: 'app-list-broadcast',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vuity-list-broadcast *ngSwitchCase="'vuity'"></vuity-list-broadcast>
    </ng-container>`
})
export class ListBroadcastComponent implements OnInit {
  livePrograms: Meeting[];
  virtualPrograms: Meeting[];
  searchModel: SearchObject = {
    page: 1,
    zip: null,
    distance: 'broadcasts'
  };
  searchParams;
  distanceErrorMessage: string;
  loading: Boolean;
  webPage = null;
  load_more_details: LoadMoreObject = {
    page: 1,
    show_load_more: false
  };

  constructor(protected _router: Router,
              protected viewportScroller: ViewportScroller,
              protected _configService: ConfigService,
              protected _attendeeService: AttendeeService,
              protected titleService: Title,
              protected _analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
  }

  trackByProgramId(index: number, program: Meeting) {
    return program.program_id;
  }
}

@Component({
  selector: 'vuity-list-broadcast',
  templateUrl: '../ui/vuity/list-broadcast.component.html'
})

export class VuityListBroadcastComponent extends ListBroadcastComponent {
  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
          setTimeout(() => {
            this.track();
          }, 2000);
        },
        (error) => this.webPage = null
      );
    this.loading = true;
    this.searchPrograms();
    this.getMeetingList();
    this._attendeeService.getLoadMoreDetails()
      .subscribe(status => {
        this.load_more_details = status;
      });
  }

  track() {
    const analyticObject = {
      'pageInfo': {
        'domain': window.location.hostname,
        'subDomain': '',
        'url': window.location.href,
        'path': window.location.pathname,
        'pageName': 'Broadcast',
        'title': this.titleService.getTitle(),
        'prefix': 'vuitylive'
      },
      'category': {
        'primaryCategory': 'Broadcast',
        'subCategory1': '',
        'subCategory2': ''
      },
      'attributes': {
        'country': 'US',
        'language': 'en-US',
        'template': '',
        'promoMatsNumber': 'US-VUI-210146',
        'globalISI': '',
        'type': 'Broadcast'
      }
    };

    this._analyticsService.updateStateObject(analyticObject);
  }

  searchPrograms() {
    this._attendeeService.clearMeetingLists()
      .subscribe(success => {
        this._attendeeService.setMeetingLists(this.webPage, this.searchModel)
          .finally(() => {
            this.loading = false;
          }).subscribe();
      });
  }

  getMeetingList() {
    let result_count = 0;
    this._attendeeService.getMeetingLists().subscribe(programs => {
      result_count++;
      this.livePrograms = programs['live'];
      this.virtualPrograms = programs['virtual'];

      if (result_count == 2 || (this.livePrograms.length > 0 && this.virtualPrograms.length > 0)) {
        this.loading = false;
      }
      this.distanceErrorMessage = '';
      if (!programs['clear_meeting_list'] && this.virtualPrograms.length === 0) {
        this.distanceErrorMessage = 'There are currently no upcoming broadcasts programs.';
      }
      this.searchParams = this._attendeeService.searchParams;
    });
  }

  register(program: Meeting): void {
    this.trackInteraction(program.program_id);
    this._router.navigate(['register/' + program.program_id]);
  }

  trackInteraction(program_id) {
    const analyticObject = {
      link: {
        name: program_id,
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: 'Register',
        name: 'Upcoming Vuity Speaker Programs',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'CTA',
        name: ''
      },
      form: {
        name: '',
        category: '',
        subCategory: '',
        fieldName: '',
        fieldValue: '',
        fieldValues: '',
        stepName: '',
        stepNumber: '',
        error: ''
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  loadMorePrograms() {
    this.searchParams.page += 1;
    const scrollHeight = this.viewportScroller.getScrollPosition();
    this._attendeeService.setMeetingLists(this.webPage, this.searchParams)
      .finally(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition(scrollHeight);
        }, 100);
      })
      .subscribe();
  }
}
