<style type="text/css">
  h5 {
    font-weight: 400;
  }

  div.header-table table {
    margin-bottom: 0;
    border: 1px solid #fff;
    border-bottom: 0;
  }

  div.body-table {
    max-height: 330px;
    overflow: auto;
  }

  .buttons .btn-register {
    background-color: #26DEC2;
  }

  .buttons .btn-default {
    color: #000;
  }

  div.body-table table {
    background-color: #fff;
    color: #000;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container {
    font-weight: 500;
  }

  button {
    border-radius: 0.2rem;
    height: 39px !important;
    font-size: 15px !important;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 400 !important;
    margin: 0 12px 0 0;
  }

  .register-message h2 {
    color: #364FCB;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }

  .panel-body {
    padding: 15px;
  }

  #register label {
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0;
    width: 100%;
    cursor: pointer;
  }

  .register_panel {
    background-color: #4D3A99;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    margin-bottom: 20px;
  }

  .register_panel table {
    font-size: 14px !important;
  }

  button.rgstr-btn-search {
    margin-top: 0;
    background-color: #26DEC2 !important;
    border-color: #26DEC2;
  }

  .target_table thead th {
    color: #fff;
  }

  .invalid {
    border-color: #dc3545;
  }

  a.email {
    font-weight: 600;
    color: #fff;
  }

  a.privacy_policy {
    font-weight: 600;
    color: #75c4d6 !important;
  }

  p.opt_in {
    font-size: 14px;
    margin: 0;
    padding-left: 10px;
  }

  .title {
    margin-bottom: 25px;
    text-align: center;
  }

  .title h1 {
    color: #26DEC2;
    font-weight: 600;
    display: inline-block;
    margin: 10px 0;
  }

  .title h2 {
    margin: 0;
    font-weight: 700;
    color: #4d4d4f;
  }

  .invalid-feedback {
    font-size: 16px;
    color: #F6AAA8 !important;
  }

  .searchtarget .form-control {
    font-size: 13px !important;
    padding: 5px;
  }

  .loading_target_icon {
    line-height: 150px;
  }

  tr.selected_target {
    background-color: #FAFFBD !important
  }

  .target-checkbox {
    position: relative;
    top: 1px;
  }

  .table-bordered tr {
    cursor: pointer;
  }

  td.inital_message {
    border-color: #4B4A4B;
    background-color: #4B4A4B;
    padding: 0;
  }

  td.inital_message div {
    height: 100%;
    line-height: 49px;
    background: #fff;
  }

  td.inital_message div p {
    margin: 0 10px;
    line-height: 2;
  }

  td.inital_message div p a {
    color: #75c4d6;
  }

  @media only screen and (max-width: 767px) {
    .register-message h2 {
      font-size: 22px
    }

    #phone_number_section {
      width: 100%;
    }
  }
</style>
<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>EVENT REGISTRATION</h1>
      </div>
    </div>
  </div>
  <div class="specialty_curve"></div>
</section>
<div class="container" id="register">
  <div *ngIf="!program || loading" class="loading container text-center"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="program && !loading" class="row">
    <div class="register-message col-12">
      <h2>For U.S. Healthcare Professionals Only </h2>
    </div>
    <div class="col-12" id="right_bar">
      <div class="row justify-content-center" *ngIf='program.location'>
        <div class="col-12"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-map-location [location]='program.location' [webPage]="webPage" [meetingLive]='program.meeting_type_live'>
          </app-map-location>
        </div>
        <div class="col-12 col-md-6"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-speaker-list *ngIf="program" [speakers]='program.speakers' [title]="'Speaker(s)'"
                            [webPage]="webPage"></app-speaker-list>
        </div>
        <div class="col-12 col-md-6" *ngIf="program.event_type_sub_code !== 'BRDPGM'"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-sf-members *ngIf="program" [rep]='program.sf_members' [title]="'Sales Representative'"
                          [webPage]="webPage"></app-sf-members>
        </div>
      </div>
    </div>
    <div class="title col-12">
      <h1>Register for Event: {{program.formatted_program_id}}<br></h1>
      <div>
        <h2>{{program.topic}}</h2>
        <h2> {{program.meeting_date_long}} at {{program.meeting_time}} {{program.timezone}}
          <span *ngIf='program.meeting_type_live'> in {{program.location.city}},
            {{program.location.state}}</span>
        </h2>
      </div>
    </div>
    <div class="col-lg-12 left_content">
      <div class="panel register_panel">
        <div class="panel-body">
          <form #f="ngForm" class="row searchtarget" name="searchtarget" novalidate role="form">
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label class="rgstr-label" for="firstname">First Name *</label>
                <input #firstname="ngModel" (change)="onFieldChange(firstname, 'First Name', true)"
                       [(ngModel)]="searchTargetModel.firstname"
                       [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }"
                       class="form-control" id="firstname" name="firstname" required type="text">
                <div *ngIf="f.submitted && firstname.invalid" class="invalid-feedback">
                  <div *ngIf="firstname.errors.required" class="text-danger">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label class="rgstr-label" for="lastname">Last Name *</label>
                <input #lastname="ngModel" (change)="onFieldChange(lastname, 'Last Name', true)"
                       [(ngModel)]="searchTargetModel.lastname"
                       [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }"
                       class="form-control" id="lastname" name="lastname" required type="text">
                <div *ngIf="f.submitted && lastname.invalid" class="invalid-feedback">
                  <div *ngIf="lastname.errors.required" class="text-danger">Last Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label class="rgstr-label" for="state">State</label>
                <select #state="ngModel" (change)="onStateChange(state, 'State')" [(ngModel)]="searchTargetModel.state"
                        class="form-control browser-default custom-select" id="state" name="state">
                  <option [value]="null"> Select State</option>
                  <option *ngFor="let state of states" [value]="state.state_alpha2"> {{state.name}} </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label class="rgstr-label" for="city">City</label>
                <select #city="ngModel" (change)="onFieldChange(city, 'City')"
                        [(ngModel)]="searchTargetModel.city" class="form-control" id="city" name="city">
                  <option [value]="null"> Select City</option>
                  <option *ngFor="let city of cities" [value]="city.city"> {{city.city}} </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label class="rgstr-label" for="license_number">License Number</label>
                <input #license_number="ngModel" (change)="onFieldChange(license_number, 'License Number')"
                       [(ngModel)]="searchTargetModel.license_number"
                       class="form-control" id="license_number" maxlength="10" name="license_number" type="text">
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label>&nbsp;</label>
                <button (click)="searchTarget(f)" class="btn btn-default btn-large rgstr-btn-search" name="btnSearch"
                        type="submit">SEARCH
                </button>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-12">
              <p>
                AbbVie adheres to all applicable federal and state laws and regulations, including disclosure
                requirements. Attendance at speaker programs is limited to active healthcare professionals. Accordingly,
                we appreciate your support in not bringing a spouse and/or guests to this promotional program.
                Additional attendance restrictions may apply based on specific state or federal requirements and where
                otherwise prohibited by law. It is Company policy to only include healthcare professionals who are
                involved in patient care consistent with our product indication(s).
              </p>
              <p>
                For any technical difficulties contact : MedForce at
                <a
                  (click)="linkInteraction('support@abbviespeakerprograms.com', 'External')"
                  class="email"
                  href="mailto:support@abbviespeakerprograms.com?subject=Registration for Vuity Event: {{program.formatted_program_id}}">support@abbviespeakerprograms.com</a>
              </p>
            </div>
          </div>
          <div *ngIf="!loading_target" class="row">
            <div class="target_table col-12">
              <div class=" header-table">
                <table class="table" width="100%">
                  <thead>
                  <tr>
                    <th width="30%">Name</th>
                    <th width="25%">FID</th>
                    <th width="45%">Address</th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div class="body-table">
                <table class="table table-bordered" width="100%">
                  <tbody>
                  <tr>
                    <td class="inital_message text-left" colspan="3">
                      <div class="pl-2"> SEARCH AND IDENTIFY YOURSELF</div>
                    </td>
                  </tr>
                  <tr *ngIf="!targetList || targetList.length == 0 && !initial">
                    <td class="inital_message text-left" colspan="3">
                      <div><p><strong>No Results </strong><br/>
                        Try a New Search, or
                        <a (click)="linkInteraction('Add new', 'Internal')"
                           [routerLink]="['/register', program.program_id, 'new']">Add New</a>
                      </p>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let target of targetList; trackBy: trackByTargetId"
                      [ngClass]="{selected_target:(selected_target_id == target.target_id)}">
                    <td *ngIf="!target.repeated" width="30%">
                      <div class="row">
                        <div class="col-2">
                          <input (change)="onTargetCheckbox($event, target)"
                                 [checked]="selected_target_id == target.target_id"
                                 class="target-checkbox"
                                 type="checkbox">
                        </div>
                        <div (click)="selectTargetForRegister(target)" class="col-10 pl-0">
                          <span>{{target.firstname}} {{target.midname}}
                            {{target.lastname}}</span>
                        </div>
                      </div>
                    </td>
                    <td (click)="selectTargetForRegister(target)" *ngIf="target.repeated" width="30%">
                    </td>
                    <td (click)="selectTargetForRegister(target)" width="25%">
                      <span *ngIf="!target.repeated">{{target.me_num}}</span></td>
                    <td (click)="selectTargetForRegister(target)" width="45%">
                      {{target.address.address1}} {{target.address.city}}, {{target.address.state}}
                      {{target.address.zip}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="targetList.length > 0" class="display_count"> Showing 1 to {{targetCount}} of {{targetCount}}
                entries
              </div>
            </div>
          </div>
          <div *ngIf="loading_target" class="loading_target_icon text-center">
            <i class="fa fa-spinner fa-spin fa-3x"> </i>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="Email">Email Address *
                <input #email="ngModel" (change)="checkEmailAddress(email)" [(ngModel)]="registerModel.email"
                       [ngClass]="{'is-invalid': errorEmail}"
                       class="form-control" email id="Email"
                       name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                       placeholder="Email" type="email">
              </label>
              <div *ngIf="errorEmail">
                <span class="text-danger">{{errorEmailMsg}}</span>
              </div>
            </div>
            <div class="col-md-12 privacy-policy">
              <p>We respect your privacy. The information pertaining to you that we collect will be used in accordance
                with our <a (click)="linkInteraction('Privacy Statement', 'External')" class="privacy_policy"
                            href="https://www.abbvie.com/privacy.html"
                            target="_blank">Privacy
                  Statement.</a> By providing your email address above, you agree and acknowledge that you would
                like to receive email communications from AbbVie, including information about this and other educational
                programs.</p>
            </div>
            <div *ngIf="showSignature" class="col-12" id="rsvp_signature">
              <p>
                <strong>Within the box below, electronically sign your registration and submit.</strong><br/>
                PC Users: use mouse or mouse pad <br/>
                Tablet/Mobile Device Users: use finger or stylus <br/>
              </p>
              <div class="wrapper" [class.valid]="validSignature">
                <app-signature-pad [options]="signaturePadOptions" (beginEvent)="drawStart()"></app-signature-pad>
                <button type="button" class="btn btn-md btn-default" (click)="clearSignature()">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-1">
          <span class="text-blue">SIGN UP TO RECEIVE TEXT MESSAGES SO YOU DON'T MISS OUT!</span>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-start">
            <input (change)="checkMessageConfirmation()" [(ngModel)]="registerModel.message_confirmation"
                   class="mt-1" name="message-confirmation" type="checkbox" value="true">
            <p class="opt_in">
              Check this box if you are interested in receiving SMS text message confirmations and reminders for this
              AbbVie event. Please note messages will be sent to the mobile phone number provided below, and standard
              data messaging rates, as determined by your cellular carrier, will apply. By opting in, you are agreeing
              to receive messages as specified above.
            </p>
          </div>
        </div>
        <div class="col-md-12 mb-2">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="mb-2" id="phone_number_section">
              <label><b>Phone Number</b></label>
              <input #phone="ngModel" (blur)="checkPhoneNumber(phone)" [(ngModel)]="registerModel.phone_number"
                     [attr.disabled]="!registerModel.message_confirmation ? 'disabled' : null"
                     [attr.required]="registerModel.message_confirmation"
                     [ngClass]="{ 'invalid': errorPhoneNumber }" [textMask]="{mask: mask}"
                     class="form-control"
                     name="phone" pattern="^[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                     type="text">
              <div *ngIf="errorPhoneMsg && errorPhoneNumber">
                <p class="text-danger">{{errorPhoneMsg}}</p>
              </div>
            </div>
            <div class="align-self-end buttons clearfix mb-2">
              <button (click)="cancelVuity()" class="btn btn-md btn-default" type="button">CANCEL</button>
              <button (click)="registerVuity()" class="btn btn-md btn-register" type="button">
                <span *ngIf="!registering">SUBMIT</span>
                <span *ngIf="registering"><i class="fa fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="errorRSVP" class="row">
        <div class="col-12">
          <p class="text-danger">{{errorMsg}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
