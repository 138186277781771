<style type="text/css">
  picture img {
    width: 150px;
    max-width: 100%;
    height: auto;
  }

  h3.botox_header {
    border-top: 1px solid #c0c0c0;
    margin: 0;
    padding-top: 1rem;
  }

  .title span {
    background-color: #337ab7;
    color: white;
    padding: 3px;
    border-radius: 3px;
    width: 280px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    max-width: 80%;
  }

  @media (max-width: 400px) {
    .botox_header {
      font-size: 12px;
    }

    picture img {
      width: 120px;
    }
  }
</style>
<section class="botox">
  <div>
    <h3 class="botox_header text-center">Treating Adult Spasticity with
      <picture>
        <img src="assets/botox/images/botox-injection.svg" alt="Botox-logo"/>
      </picture>
    </h3>
  </div>
  <div class="text_box">
    <p>Join us for an exciting webinar about BOTOX&reg; for Adult Spasticity. Hear directly from a Spasticity Specialist about
      what makes BOTOX&reg; a treatment option for Adult Spasticity, what to expect when starting treatment, and how to
      prepare for conversations with your doctor about treatment progress. You'll also be able to ask any questions
      you may have in a live Q&A. Reserve your spot today!</p>
  </div>
</section>
