import {Component, OnInit} from '@angular/core';
import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';

@Component({
  selector: 'app-index',
  template: `
    <ng-container [ngSwitch]="webPage">
      <migraine-index *ngSwitchCase="'migraine'"></migraine-index>
    </ng-container>`
})
export class IndexComponent implements OnInit {
  webPage = null;

  constructor(protected _configService: ConfigService) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
  }
}

@Component({
  selector: 'migraine-index',
  templateUrl: '../ui/migraine/index.component.html',
})
export class MigraineIndexComponent extends IndexComponent {
}
