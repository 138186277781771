<style type="text/css">
  #migraine-footer {
    font-weight: 600;
    background: #071d49;
    color: #fff;
  }

  #migraine-footer a {
    color: #fff;
  }

  #migraine-footer .abbvie-logo {
    width: 200px;
    margin-bottom: 10px;
  }

  #migraine-footer a:hover {
    color: #76c4d6
  }

  #migraine-footer .footer-content {
    padding: 45px 0
  }

  #migraine-footer .footer-content a {
    display: block;
    text-align: center;
  }

  #migraine-footer .footer-content ul {
    text-align: center;
    margin-bottom: 25px;
  }

  #migraine-footer .footer-content ul li {
    display: inline-block;
    padding: 0 20px;
  }

  #migraine-footer .footer-content ul li a {
    font-size: 16px !important;
    font-weight: 600;
    padding: 0 !important;
    border: none !important;
    background: none !important;
    color: #fff !important;
  }

  #migraine-footer .footer-content p {
    text-align: center;
    font-size: 14px;
  }

  #migraine-footer .footer-content p:last-child {
    margin-bottom: 0
  }

  .privacy-links li:first-child {
    padding-left: 0 !important;
  }

  .privacy-links li:last-child a {
    display:inline !important;
  }

  .check-icon {
    width: 30px;
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 767px) {
    #migraine-footer .footer-content {
      padding: 30px 0;
    }

    #migraine-footer .footer-content .abbvie-logo {
      width: 150px;
      margin-bottom: 15px;
    }

    #migraine-footer .footer-content ul {
      margin-bottom: 0;
    }

    #migraine-footer .footer-content ul li {
      margin-bottom: 10px;
    }

    #migraine-footer .footer-content p {
      margin-bottom: 10px
    }
  }
</style>
<footer id="migraine-footer">
  <div class="footer-content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <a href="https://www.abbvie.com" target="_blank">
            <img alt="Abbvie Logo" class="abbvie-logo" src="assets/migraine/images/abbvie-white.png"/>
          </a>
          <ul class="privacy-links">
            <li>
              <a href="https://www.abbvie.com/accessibility-statement.html " target="_blank">Accessibility Statement</a>
            </li>
            <li>
              <a href="https://www.abbvie.com/contactus.html" target="_blank">Contact Us</a>
            </li>
            <li>
              <a href="https://www.abbvie.com/termsofuse.html" target="_blank">Terms of Use</a>
            </li>
            <li>
              <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank">Privacy Notice</a>
            </li>
            <li>
              <a class="ot-sdk-show-settings" href="javascript:void(0)" id="ot-sdk-btn" title="Overridden by OneTrust">
                Cookies Settings
              </a>
            </li>
            <li>
              <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank">Your Privacy Choices</a>&nbsp;<img class="check-icon" src="assets/migraine/images/icons/privacyoptions.svg" alt="check">
            </li>
          </ul>
          <div>
            <p>&copy; 2023 AbbVie.&nbsp; All rights reserved.</p>
            <p>
              QULIPTA, UBRELVY, and their designs are trademarks of Allergan Pharmaceuticals International Limited, an AbbVie company.
            </p>
            <p>US-MULT-230744 10/23</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
