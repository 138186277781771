<style type="text/css">
  figure {
    margin: 0;
  }

  .qulipta-banner-content {
    max-width: 1080px;
  }

  .qulipta_resource_banner {
    padding: 30px 0;
    background-color: #f0f5f8;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
  }

  .qulipta_resource_banner:after {
    content: '';
    width: 100%;
    background-image: url(assets/qulipta/images/header.png);
    position: absolute;
    top: -6%;
    right: 0;
    z-index: -1;
    background-repeat-y: no-repeat;
    padding-bottom: 22%;
    background-repeat-x: no-repeat;
    background-position: top;
    background-color: #fff;
  }

  .qulipta_right_links {
    position: absolute;
    top: 30px;
    right: 0;
  }

  .qulipta_right_links li {
    text-transform: uppercase;
    background-color: #117f9a;
    padding-right: 50px;
    margin-bottom: 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    position: relative;
    z-index: 1;
    list-style: none;
  }

  .qulipta_right_links li:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    -webkit-box-shadow: inset -60px 0 50px -20px #ffffffc7;
    -moz-box-shadow: inset -60px 0 50px -20px #ffffffc7;
    -ms-box-shadow: inset -60px 0 50px -20px #ffffffc7;
    -o-box-shadow: inset -60px 0 50px -20px #ffffffc7;
    box-shadow: inset -60px 0 50px -20px #ffffffc7;
  }

  .qulipta_right_links li a {
    padding: 10px;
    text-decoration: none;
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    display: flex;
    flex-wrap: wrap;
  }

  .qulipta_right_links li:hover::after {
    box-shadow: none;
  }

  .qulipta_right_links li img {
    width: 50px;
  }

  .qulipta_right_links li h4 {
    width: 100%;
    max-width: 130px;
    color: #fff;
    padding: 0 15px;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }

  .qulipta_right_links li i {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    right: 30px;
    background: #fff;
    color: #e05c24;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .right_arrow:after {
    content: '';
    border: solid 3px #fff;
    display: block;
    border-left: transparent;
    border-bottom: transparent;
    width: 11px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .qulipta_right_links li i:after {
    width: 9px;
    height: 9px;
    border-width: 2px;
    border-color: #e05c24;
    margin-left: 6px;
    margin-top: 8px;
  }

  .qulipta_resource_banner h1 {
    color: #374550;
    text-shadow: 3px 1px 5px #e6c8bc;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-family: 'Fira Sans', sans-serif;
    font-weight: bold;
    font-size: 28px;
  }

  .qulipta_resource_banner h2 {
    width: 100%;
    max-width: 450px;
    color: #38464f;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 50px;
  }

  .qulipta_resource_sec .row {
    margin-bottom: 50px;
  }

  .qulipta_resource_sec .container > h2 {
    width: 100%;
    max-width: 750px;
    font-size: 36px;
    color: #4a545b;
    margin-bottom: 30px;
    line-height: normal;
    font-family: 'Fira Sans', sans-serif;
    font-weight: bold;
  }

  .download_resource_img_box img {
    width: 100%;
  }

  .qulipta_resource_sec h3 {
    color: #4a545b;
    font-size: 25px;
    margin-bottom: 30px;
    font-family: 'Fira Sans', sans-serif;
  }

  .qulipta_resource_sec .row.flex-row-reverse {
    justify-content: flex-end;
  }

  .download_resource_content_box h4 {
    font-size: 20px;
    color: #4c575c;
    font-family: 'Fira Sans', sans-serif;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .download_resource_content_box p {
    color: #8e9497;
    font-size: 18px;
    line-height: 25px;
    font-family: 'Fira Sans', sans-serif;
  }

  .qulipta_complete_block {
    padding-top: 60px;
  }

  .qulipta_complete_block figure img {
    margin-bottom: 15px;
  }

  .qulipta_complete_block h2 {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .qulipta_complete_block h2 span {
    font-size: inherit;
    color: #f06224;
  }

  .qulipta_complete_block h2 strong {
    display: block;
    font-weight: inherit;
  }

  .qulipta_complete_block p {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .qulipta_complete_block p span {
    display: block;
    color: #f06224;
    font-size: inherit;
  }

  .qulipta_prescribed_block > p {
    width: 100%;
    max-width: 700px;
    font-weight: 500;
  }

  .prescribed_box {
    width: 100%;
    padding-left: 70px;
    position: relative;
  }

  .prescribed_box figure {
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }

  .prescribed_content {
    width: 100%;
    max-width: 600px;
  }

  .prescribed_content h3 {
    font-size: 20px;
    color: #f06224;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .prescribed_box {
    margin-bottom: 15px;
  }

  .prescribed_box ul {
    margin: 0;
    padding: 0;
  }

  .prescribed_content ul li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: normal;
    font-weight: 500;
  }

  .prescribed_content ul li:before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    color: #f06224
  }

  ::marker {
    font-size: 0;
  }

  .qulipta_easy_steps {
    width: 100%;
    max-width: 700px;
    text-align: center;
    border-radius: 35px;
    border: solid 1px #f06224;
    padding: 25px 100px;
  }

  .qulipta_easy_steps figure img {
    width: 60px;
    margin: 0 auto 15px;
    display: block;
  }

  .qulipta_easy_steps h4 {
    font-size: 20px;
    color: #f06224;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .qulipta_easy_steps p {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 15px;
  }

  .download_btn {
    width: 100%;
    max-width: 250px;
    font-size: 18px;
    font-family: 'Fira Sans', sans-serif;
    display: inline-block;
    padding: 10px 0;
    text-transform: uppercase;
    color: #fff;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    background-image: -webkit-linear-gradient(90deg, #354550 50%, #e05c24);
    background-image: -moz-linear-gradient(90deg, #354550 50%, #e05c24);
    background-image: -ms-linear-gradient(90deg, #354550 50%, #e05c24);
    background-image: -o-linear-gradient(90deg, #354550 50%, #e05c24);
    background-image: linear-gradient(90deg, #354550 50%, #e05c24);
    position: relative;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .download_btn:hover {
    background-image: linear-gradient(90deg, #354550 100%, #e05c24);
    color: #fff;
  }

  .download_btn i {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 6px;
    right: 15px;
    background: #fff;
    color: #e05c24;
    border-radius: 100%;
  }

  .download_btn i:after {
    border-color: #e05c24;
    margin-left: 8px;
    margin-top: 9px;
  }

  ul.download_resource_list {
    padding: 0;
  }

  .download_resource_list li a {
    color: #f06224;
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 5px;
    position: relative;
    display: inline-block;
  }

  .download_resource_list li .right_arrow {
    position: absolute;
    right: -10px;
    top: 6px;
  }

  .download_resource_list li .right_arrow:after {
    border-color: #f06224;
    width: 7px;
    height: 7px;
    border-width: 2px;
  }

  .download_resource_list li a:hover {
    text-decoration: none;
  }

  @media only screen and (min-width: 1600px) {
    .qulipta_resource_banner:after {
      height: 295px;
      padding-bottom: 0;
      background-position: center;
      background-size: cover
    }
  }

  @media only screen and (max-width: 1199px) {
    .qulipta_resource_banner:after {
      background-position: 60% top;
      padding-bottom: 30%
    }
  }

  @media only screen and (max-width: 992px) {
    .qulipta_resource_banner:after {
      padding-bottom: 40%;
      background-position: 60% 40%;
    }

    .qulipta_resource_banner {
      padding-top: 0;
      margin-bottom: 15px;
    }

    .qulipta_resource_banner h1 {
      font-size: 25px;
    }

    .qulipta_resource_banner h2 {
      font-size: 22px;
    }

    .qulipta_resource_sec .row {
      margin-bottom: 15px;
    }

    .qulipta_resource_sec .container > h2 {
      font-size: 20px;
    }

    .qulipta_resource_sec h3 {
      font-size: 20px;
      margin: 0;
    }

    .download_resource_content_box h4 {
      font-size: 18px;
    }

    .download_resource_content_box p {
      font-size: 16px;
      line-height: normal;
    }

    .qulipta_right_links {
      width: calc(100% + 30px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: static;
      margin: 0;
      padding: 0;
    }

    .qulipta_right_links li {
      width: 24%;
      text-align: center;
      padding: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 6px;
    }

    .qulipta_right_links li:after {
      box-shadow: inset 0 30px 50px -20px #ffffffc7;
    }

    .qulipta_right_links li a {
      align-items: center;
      align-self: center;
      height: 100%;
    }

    .qulipta_right_links li img, .qulipta_right_links li i {
      display: none;
    }

    .qulipta_right_links li h4 {
      max-width: 100%;
      font-size: 14px;
      font-weight: normal;
      padding: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .qulipta-banner-content {
      max-width: 100%;
    }

    .qulipta_resource_banner h2 {
      font-size: 16px;
    }

    .qulipta_easy_steps {
      padding: 10px 0;
    }

    .qulipta_complete_block {
      padding-top: 80px;
    }

    .qulipta_complete_block p {
      font-size: 16px;
    }

    .qulipta_complete_block p span {
      display: inline;
    }

    .prescribed_box {
      padding-left: 45px;
    }

    .prescribed_box figure {
      width: 35px;
    }

    .qulipta_resource_banner h3 {
      font-size: 15px;
    }

    .prescribed_content ul li {
      font-size: 13px;
    }

    .qulipta_resource_sec .container > h2 {
      margin-bottom: 15px;
    }

    .download_resource_content_box {
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: 560px) {
    .qulipta_resource_banner:after {
      padding-bottom: 88%;
      background-position: 60% 40%;
      background-color: #f0f5f8;
    }

    .qulipta_resource_banner h1 {
      font-size: 22px;
      max-width: 220px;
      margin: 0
    }
  }
</style>
<qulipta-header></qulipta-header>
<main>
  <section class="qulipta_resource_banner">
    <div class="container">
      <div class="row">
        <ul class="qulipta_right_links">
          <li>
            <a target="_blank" href="https://www.mysamplecloset.com/allergan">
              <img src="assets/qulipta/images/icons/Nav-Samples-Icon.png" alt="">
              <h4>REQUEST SAMPLES</h4><i class="right_arrow"></i>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.abbvieaccess.com">
              <img src="assets/qulipta/images/icons/Nav-Access-Icon.png" alt="">
              <h4>ENROLL PATIENTS</h4><i class="right_arrow"></i>
            </a>
          </li>
          <li>
            <a routerLink="/index">
              <img src="assets/qulipta/images/icons/Nav-Speaker-Icon.png" alt="">
              <h4>SPEAKER PROGRAMS</h4><i class="right_arrow"></i>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.abbvieaccess.com/request-a-rep-form?brand=Qulipta">
              <img src="assets/qulipta/images/icons/Nav-Rep-Icon.png" alt="">
              <h4>SPEAK TO A REP</h4><i class="right_arrow"></i>
            </a>
          </li>
        </ul>
        <div class="qulipta-banner-content col-sm-12">
          <h1>QULIPTA<sup>&trade;</sup> Support & Savings</h1>

          <div class="qulipta_complete_block">
            <figure><img class="img-fluid" src="assets/qulipta/images/qulipta-complete-logo.png"
                         alt="Qulipta Complete logo" title="Qulipta Complete logo"></figure>
            <h2>You Prescribe. <strong>We Provide <span>Support.</span></strong></h2>
            <p>Qulipta Complete can help your patients <span>Start and stay on track with 3 easy steps</span></p>
          </div>
          <div class="qulipta_prescribed_block">
            <div class="prescribed_box">
              <figure><img class="img-fluid" src="assets/qulipta/images/icons/prescribe-icon.png" alt="prescribe-icon"
                           title="Prescribe">
              </figure>
              <div class="prescribed_content">
                <h3>1. Prescribe & Enroll Now</h3>
                <ul class="clearfix">
                  <li>Complete the <strong>1-page enrollment & prescription form</strong> with your patient & fax it to
                    <strong>1-866-933-4805</strong>
                  </li>
                  <li>Give your patient their welcome sheet and tell them to expect a call soon to get started</li>
                </ul>
              </div>
            </div>
            <!-- Box End -->
            <div class="prescribed_box">
              <figure><img class="img-fluid" src="assets/qulipta/images/icons/start-saving-icon.png"
                           alt="start-saving-icon"
                           title="Start Saving"></figure>
              <div class="prescribed_content">
                <h3>2. Start Saving</h3>
                <ul class="clearfix">
                  <li>Savings Card: Your patient's can start on QULIPTA for <strong>as little as $0*</strong>
                  </li>
                  <li>Home Delivery: While coverage is being determined, patient's can receive Qulipta
                    <strong>delivered to their home for $0*</strong></li>
                </ul>
              </div>
            </div>
            <!-- Box End -->
            <div class="prescribed_box">
              <figure><img class="img-fluid" src="assets/qulipta/images/icons/get-support-icon.png"
                           alt="get-support-icon"
                           title="Get Support"></figure>
              <div class="prescribed_content">
                <h3>3. Get Support</h3>
                <ul class="clearfix">
                  <li> QULIPTA Complete Access Specialists will help you and your patient's <strong>identify next steps
                    to getting their prescribed treatment,</strong> including PA support
                  </li>
                  <li>QULIPTA Complete Support Specialists can answer your patient's questions about QULIPTA and
                    <strong>help them establish a daily routine to achieve their personal goals*</strong></li>
                </ul>
              </div>
            </div>
            <p>
              QULIPTA Complete Support Specialists are provided by AbbVie and do not work under the direction of
              healthcare professionals(HCP) or give medical advice. They are trained to direct patients to their HCP for
              treatment-related advice, including further referrals.
            </p>
          </div>
          <div class="qulipta_easy_steps">
            <h4>QULIPTA Complete Enrollment and Prescription Form</h4>
            <p>Complete the <strong>1-page enrollment & prescription form</strong> with your patient & fax it to
              <strong>1-866-933-4805</strong></p>
            <a class="download_btn" [href]="enrollment_link" target="_blank" title="Download">
              Download <i class="right_arrow"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="qulipta_resource_sec">
    <div class="container">
      <h2>Download resources to support your office and your patients</h2>
      <h3>HCP Tools</h3>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="download_resource_img_box">
            <figure>
              <img src="assets/qulipta/images/QULIPTA-dosing-card.png" alt="QULIPTA-dosing-card img"
                   title="QULIPTA-dosing-card" class="img-fluid">
            </figure>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="download_resource_content_box">
            <h4>QULIPTA Dosing Card</h4>
            <p>QULIPTA comes in 3 dose strengths for migraine prevention you can tailor to your patients. Learn more
              about dosing consideration and how to prescribe.</p>
            <a class="download_btn" href="#" title="Download">Download <i class="right_arrow"></i></a>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 mt-md-3 mt-lg-0">
          <div class="download_resource_content_box">
            <h4>Downloadable templates to help your patients get started on QULIPTA</h4>
            <p>These are for informational purposes only and are not intended to provide reimbursement or legal advice.
              The information presented here does not guarantee payment or coverage.</p>
            <ul class="download_resource_list">
              <li><a href="#">Sample Appeals Letter <i class="right_arrow"></i></a></li>
              <li><a href="#">Letter of medical Necessity Sample <i class="right_arrow"></i></a></li>
              <li><a href="#">Formulary Exception Letter <i class="right_arrow"></i></a></li>
              <li><a href="#">Tiered Exception Sample Letter <i class="right_arrow"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Row End -->
      <h3>Patient Resources</h3>
      <div class="row flex-row-reverse">
        <div class="col-lg-3 col-md-6">
          <div class="download_resource_img_box">
            <figure>
              <img src="assets/qulipta/images/Patient-brochure.png" alt="brochure" class="img-fluid">
            </figure>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="download_resource_content_box">
            <h4>Patient Brochure</h4>
            <p>Download a brochure to help patients better understand QULIPTA and how it works to prevent migraine
              attacks.</p>
            <a class="download_btn" href="#" title="Download">Download <i class="right_arrow"></i></a>
          </div>
        </div>
      </div>
      <!-- Box END -->
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="download_resource_img_box">
            <figure>
              <img src="assets/qulipta/images/QULIPTA-leave-behind.png" alt="QULIPTA-leave-behind" class="img-fluid">
            </figure>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="download_resource_content_box">
            <h4>QULIPTA Complete Overview leave behind</h4>
            <p>QULIPTA Offers a resource that introduces QULIPTA Complete to you and your office staff. it explains the
              patient benefits of the QULIPTA savings program & the steps needed to enroll.</p>
            <a class="download_btn" href="#" title="Download">Download <i class="right_arrow"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Container End -->
  </section>
</main>
<qulipta-safety-info></qulipta-safety-info>
