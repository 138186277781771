<style type="text/css">
  h5 {
    font-weight: 400;
  }

  div.header-table table {
    margin-bottom: 0;
    border: 1px solid #fff;
    border-bottom: 0;
  }

  .invalid {
    border-color: #dc3545;
  }

  .container {
    font-weight: 500;
  }

  .logos {
    margin-bottom: 20px;
  }

  button {
    border-radius: 0;
    padding-left: 12px;
    padding-right: 12px;
    width: 80px;
    font-weight: 400 !important;
  }

  .panel-body {
    padding: 15px;
  }

  .event_banner {
    background-color: #071D49;
    margin-bottom: 30px;
  }

  .event_banner h1 {
    color: #fff;
    font-size: 32px;
  }

  .buttons .btn-register {
    background-color: #071D49;
  }

  #register label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
    cursor: pointer;
  }

  .register_panel {
    background-color: #071D49;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    margin-bottom: 20px;
  }

  .register_panel table {
    font-size: 14px !important;
  }

  a.privacy_policy {
    font-weight: 600;
    color: #03AEA9 !important;
  }

  .privacy-policy {
    font-size: small;
  }

  span.opt_in {
    font-size: 14px;
  }

  .title {
    margin-bottom: 25px;
    text-align: center;
  }

  .title h1 {
    color: #071D49;
    font-weight: 700;
    display: inline-block;
    margin: 10px 0;
  }

  .title h2 {
    margin: 0;
    font-weight: 700;
    color: #4d4d4f;
  }

  .error,
  .invalid-feedback {
    font-size: 16px;
    color: #F6AAA8 !important;
  }

  .register-form .form-control {
    font-size: 13px !important;
    padding: 5px;
  }

  .display_block {
    display: block;
  }

  .buttons {
    width: 100%;
    text-align: center;
  }

  .buttons button {
    width: 100px;
  }
</style>

<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>EVENT REGISTRATION</h1>
      </div>
    </div>
  </div>
</section>
<section class="logos">
  <div class="row">
    <div class="col-6 text-right">
      <img src="assets/migraine/images/qulipta-logo.png" alt="Qulipta Logo">
    </div>
    <div class="col-6 ubrelvylogo">
      <img src="assets/migraine/images/ubrelvy-logo.png" alt="Ubrelvy Logo">
    </div>
  </div>
</section>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program || loading"><i class="fa fa-spinner fa-spin"> </i></div>
  <div class="row" *ngIf="program && !loading">
    <div class="col-12" id="right_bar">
      <div class="row" *ngIf='program.location'>
        <div class="col-12"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-map-location [location]='program.location' [webPage]="webPage" [meetingLive]='program.meeting_type_live'>
          </app-map-location>
        </div>
        <div class="col-12 col-md-6"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-speaker-list *ngIf="program" [speakers]='program.speakers' [title]="'Speaker(s)'" [webPage]="webPage">
          </app-speaker-list>
        </div>
        <div class="col-12 col-md-6"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-sf-members *ngIf="program" [rep]='program.sf_members' [title]="'Sales Representative'"
                          [webPage]="webPage"></app-sf-members>
        </div>
      </div>
    </div>
    <div class="title col-12">
      <h1>Register for Event: {{program.formatted_program_id}}<br></h1>
      <div>
        <h2>{{program.topic}}</h2>
        <h2> {{program.meeting_date_long}} at {{program.meeting_time}} {{program.timezone}}
          <span *ngIf='program.meeting_type_live'> in {{program.location.city}},
            {{program.location.state}}</span>
        </h2>
      </div>
    </div>
    <div class="col-lg-12 left_content">
      <form name="form" class="register-form" (ngSubmit)="registerMigraine(f)" #f="ngForm" novalidate>
        <div class="panel register_panel">
          <div class="panel-body">
            <p class="text-right m-0">* Mandatory Field</p>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label for="firstname" class="rgstr-label">First Name *</label>
                  <input id="firstname" class="form-control" [(ngModel)]="attendeeModel.firstname"
                         name="firstname" type="text" required pattern ="^[A-Za-z\-']+$" #firstname="ngModel" ngModel
                         [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }">
                  <div *ngIf="f.submitted && firstname.invalid" class="invalid-feedback">
                    <div *ngIf="firstname.errors.required">First Name is required</div>
                    <div *ngIf="firstname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2 col-lg-2">
                <div class="form-group">
                  <label for="midname" class="rgstr-label">MI</label>
                  <input id="midname" class="form-control" [(ngModel)]="attendeeModel.midname" name="midname"
                         type="text" #midname="ngModel" pattern="[a-zA-Z]{1}" [maxLength]="1" ngModel
                         [ngClass]="{ 'is-invalid': f.submitted && midname.invalid }">
                  <div *ngIf="f.submitted && midname.invalid" class="invalid-feedback">
                    <div *ngIf="midname.errors.pattern">Enter valid MI</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label for="lastname" class="rgstr-label">Last Name *</label>
                  <input id="lastname" class="form-control" [(ngModel)]="attendeeModel.lastname" name="lastname"
                         type="text" required pattern ="^[A-Za-z\-']+$" #lastname="ngModel" ngModel
                         [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }">
                  <div *ngIf="f.submitted && lastname.invalid" class="invalid-feedback">
                    <div *ngIf="lastname.errors.required">Last Name is required</div>
                    <div *ngIf="lastname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label for="affiliation" class="rgstr-label">Affiliation *</label>
                  <input id="affiliation" class="form-control" [(ngModel)]="attendeeModel.affiliation"
                          name="affiliation" type="text" required #affiliation="ngModel" ngModel
                          [ngClass]="{ 'is-invalid': f.submitted && affiliation.invalid }">
                  <div *ngIf="f.submitted && affiliation.invalid" class="invalid-feedback">
                    <div *ngIf="affiliation.errors.required">Affiliation is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="address1" class="rgstr-label">Street Address 1*</label>
                  <input id="address1" class="form-control" [(ngModel)]="attendeeModel.address1" name="address1"
                         type="text" required (ngModelChange)="validateAddress(attendeeModel.address1)" #address1="ngModel" ngModel
                         [ngClass]="{ 'is-invalid': f.submitted && address1.invalid }" ngx-google-places-autocomplete 
                         (onAddressChange)="handleAddressChange($event)" (input)="resetFields()">
                  <div *ngIf="f.submitted && addressErrors" class="error">
                    <div *ngFor="let error of addressErrors">{{ error }}</div>
                  </div>
                  <div *ngIf="f.submitted && address1.invalid" class="invalid-feedback">
                    <div *ngIf="address1.errors.required">Street Address is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="address2" class="rgstr-label">Street Address 2</label>
                  <input id="address2" class="form-control" [(ngModel)]="attendeeModel.address2" name="address2" type="text" #address2="ngModel" ngModel>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label for="city" class="rgstr-label">City *</label>
                  <input id="city" class="form-control readonly-field" [(ngModel)]="attendeeModel.city" name="city" type="text"
                         required #city="ngModel" ngModel [ngClass]="{ 'is-invalid': f.submitted && city.invalid }"
                         (ngModelChange)="validateCity(attendeeModel.city)" readonly>
                  <div *ngIf="f.submitted && city.invalid" class="invalid-feedback">
                    <div *ngIf="city.errors.required">City is required</div>
                  </div>
                  <div *ngIf="f.submitted && cityErrorMessage" class="error">{{cityErrorMessage}}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2 col-lg-2">
                <div class="form-group">
                  <label for="state" class="rgstr-label">State *</label>
                  <select class="form-control browser-default custom-select readonly-field" name="state" id="state" required
                          [(ngModel)]="attendeeModel.state" #state="ngModel" ngModel
                          [ngClass]="{ 'is-invalid': f.submitted && state.invalid }" (mousedown)="false"
                          (keydown)="$event.preventDefault()" readonly>
                    <option [value]="null"> Select State</option>
                    <option *ngFor="let state of states" [value]="state.state_alpha2"> {{state.name}} </option>
                  </select>
                  <div *ngIf="f.submitted && state.invalid" class="invalid-feedback">
                    <div *ngIf="state.errors.required">State is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-5 col-lg-5">
                <div class="form-group">
                  <label for="zip" class="rgstr-label">Zip *</label>
                  <input id="zip" class="form-control readonly-field" [(ngModel)]="attendeeModel.zip" name="zip" type="text"
                         required #zip="ngModel" ngModel maxlength="10" pattern="^(?!00000)[0-9]{5}(?:-[0-9]{4})?$"
                         [ngClass]="{ 'is-invalid': f.submitted && zip.invalid }" readonly>
                  <div *ngIf="f.submitted && zip.invalid" class="invalid-feedback">
                    <div *ngIf="zip.errors.required">ZIP code is required</div>
                    <div *ngIf="zip.errors?.pattern">Invalid: enter 5 or 9 numbers and cannot be 00000</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="email" class="rgstr-label">Email *</label>
                  <input id="email" class="form-control" [(ngModel)]="attendeeModel.email" name="email"
                         type="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #email="ngModel"
                         ngModel
                         [ngClass]="{ 'is-invalid': f.submitted && email.invalid }">
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.pattern">Please enter valid Email</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input type="tel" class="form-control" name="phone" id="phone" [(ngModel)]="attendeeModel.phone"
                         #phone="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
                         [textMask]="{mask: mask}" pattern="\(\d{3}\) \d{3}\-\d{4}"/>
                  <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                    <div *ngIf="phone.errors.pattern">Please enter valid Phone Number</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="form-group">
                  <label for="degree" class="rgstr-label">Degree *</label>
                  <angular2-multiselect id="degree" class="browser-default" name="degree"
                                        required #degree="ngModel" [data]="degrees"
                                        [(ngModel)]="attendeeModel.degree"
                                        [settings]="degeeDropdownSettings" (onSelect)="onDegreeChange()"
                                        (onDeSelect)="onDegreeChange()"
                                        (onSelectAll)="onDegreeChange()" (onDeSelectAll)="onDegreeChange()"
                                        (onFilterSelectAll)="onDegreeChange()" (onFilterDeSelectAll)="onDegreeChange()">
                  </angular2-multiselect>
                  <div *ngIf="f.submitted && degree.invalid" class="invalid-feedback display_block">
                    <div>Degree is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4" *ngIf="show_degree_other">
                <div class="form-group">
                  <label for="degreeother" class="rgstr-label">Enter Degree</label>
                  <input id="degreeother" class="form-control" [(ngModel)]="attendeeModel.degreeother"
                         name="degreeother" type="text" required #degreeother="ngModel" ngModel
                         [ngClass]="{ 'is-invalid': f.submitted && degreeother.invalid }">
                  <div *ngIf="f.submitted && degreeother.invalid" class="invalid-feedback">
                    <div *ngIf="degreeother.errors.required">Degree Other is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <label for="licensestate" class="rgstr-label">License State</label>
                  <select class="form-control browser-default custom-select" name="licensestate" id="licensestate"
                          [(ngModel)]="attendeeModel.licensestate" ngModel>
                    <option [value]="null"> Select License State</option>
                    <option *ngFor="let licensestate of licensestates" [value]="licensestate.state_alpha2">
                      {{licensestate.name}} </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <label for="statelicense" class="rgstr-label">State License #</label>
                  <input id="statelicense" class="form-control" [(ngModel)]="attendeeModel.statelicense"
                          name="statelicense" type="text" ngModel>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <div class="form-group">
                  <label for="specialty" class="rgstr-label">Specialty</label>
                  <angular2-multiselect id="specialty" class="browser-default"
                                        name="specialty" ngModel [data]="specialties"
                                        [(ngModel)]="attendeeModel.specialty"
                                        [settings]="SpecialtydropdownSettings" (onSelect)="onSpecialtyChange()"
                                        (onDeSelect)="onSpecialtyChange()" (onSelectAll)="onSpecialtyChange()"
                                        (onDeSelectAll)="onSpecialtyChange()" (onFilterSelectAll)="onSpecialtyChange()"
                                        (onFilterDeSelectAll)="onSpecialtyChange()">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-12 col-sm-4" *ngIf="show_specialty_other">
                <div class="form-group">
                  <label for="specialtyother" class="rgstr-label">Enter Specialty</label>
                  <input id="specialtyother" class="form-control" [(ngModel)]="attendeeModel.specialtyother"
                         name="specialtyother" type="text" required #specialtyother="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && specialtyother.invalid }">
                  <div *ngIf="f.submitted && specialtyother.invalid " class="invalid-feedback">
                    <div *ngIf="specialtyother.errors.required">Specialty Other is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="form-group">
                  <label for="npi" class="rgstr-label">NPI #</label>
                  <input id="npi" class="form-control" [(ngModel)]="attendeeModel.npi" name="npi" type="text"
                          #npi="ngModel" pattern="\d{10}" [maxLength]="10" ngModel
                          [ngClass]="{ 'is-invalid': f.submitted && npi.invalid }">
                  <div *ngIf="f.submitted && npi.invalid" class="invalid-feedback">
                    <div *ngIf="npi.errors.pattern">Please enter valid NPI Number</div>
                  </div>
                </div>
              </div>
              <div class="col-12 privacy-policy">
                <p>AbbVie may collect your personal data through your online and offline interactions with us, including
                    your contact, demographic, geolocation, and professional data. We may also collect your online usage data 
                    automatically through cookies and similar technologies. We use this data for several purposes, such as to comply 
                    with our legal obligations, to perform a contract with you, to provide you with and improve our programs, 
                    services, and products, to customize your experiences, and for research and analytics. We 
                    retain your personal data for as long as necessary to fulfill these purposes or to comply with our 
                    record retention obligations.  We do not sell your personal data, but we may use and disclose it to marketing 
                    and advertising partners to deliver you ads based on your interests inferred from your activity across 
                    other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out 
                    of the use or disclosure of your personal data for online targeted advertising or for website 
                    analytics, go to your <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy
                      Choices</a> on our website. For more information on the data categories we collect, the 
                    purposes for their collection, our disclosures to third parties, your data subject rights, and 
                    our data retention criteria, visit our <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" 
                    target="_blank" class="privacy_policy">Privacy Notice</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-1">
            <span class="text-blue">SIGN UP TO RECEIVE TEXT MESSAGES SO YOU DON'T MISS OUT!</span>
          </div>
          <div class="col-md-12">
              <p>
                  Check the box below if you are interested in receiving SMS text messages confirmations and reminders 
                  for this AbbVie event.</p></div>
          <div class="col-1 text-right">
            <input type="checkbox" name="message-confirmation" value="true" ngModel
                   [(ngModel)]="attendeeModel.message_confirmation" (change)="checkMessageConfirmation()">
          </div>
          <div class="col-11">
              <span class="opt_in">
                I consent to receive automated and recurring text messages from "AbbVie", including services updates and reminders notifications 
                about this AbbVie event to the below mobile number. Message and data rates apply. I am not required to consent as a condition of 
                receiving goods or services. Reply HELP for help; reply STOP to opt out at any time. For Terms and Conditions visit 
                <a href="https://protect-us.mimecast.com/s/K0plC5yMM0H00D49CzM-l-?domain=privacy.abbvie" target="_blank" class="mobile_tc">mobile T&C</a>.
              </span>
            <label for="confirmationPhone"><b>Mobile Phone Number</b></label>
            <input type="text" class="col-4 form-control" name="confirmationPhone" id="confirmationPhone"
                   [(ngModel)]="attendeeModel.phone_number"
                   [attr.disabled]="!attendeeModel.message_confirmation ? 'disabled' : null"
                   [attr.required]="attendeeModel.message_confirmation"
                   [ngClass]="{ 'invalid': errorPhoneNumber }" #phoneNumber="ngModel"
                   (blur)="checkPhoneNumber(phoneNumber)" pattern="^[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                   [textMask]="{mask: mask}">
            <div *ngIf="errorPhoneNumber">
              <p class="text-danger">{{errorPhoneMsg}}</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button type="submit" class="btn btn-lg btn-register pull-right">
            <span *ngIf="!registering">SUBMIT</span>
            <span *ngIf="registering"><i class="fa fa-spinner fa-spin"></i></span>
          </button>
          <button type="button" class="btn btn-lg btn-default pull-right" (click)="cancel()">CANCEL</button>
        </div>
      </form>
    </div>
  </div>
</div>
