<style type="text/css">
  label {
    position: relative;
    width: 100%;
    display: inline-block
  }

  h5 {
    padding: 0;
    margin: 0;
  }

  .success_message {
    font-size: 22px;
    font-weight: 500;
  }

  label {
    width: 100%;
  }

  .grey_btn {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f;
  }

  a.privacy_policy {
    font-weight: 600;
    color: #03AEA9 !important;
  }

</style>
<div class="future-contact-height ng-scope container">
  <div *ngIf="submitted" class="success_message">
    Thank you for your interest in learning more about {{webPage.toUpperCase()}}. An Abbvie Sales Representative will be in contact.
  </div>
  <form *ngIf="!submitted" class="form-inline future_contact" name="form" role="form" #f="ngForm" novalidate>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>Please leave your information below to be contacted by an AbbVie Sales Representative<span *ngIf="webPage !== 'migraine'"> in the future</span>.</h2>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <label for="firstname">First Name
                <span style="color: red;">*</span>
                <input class="form-control" type="text" placeholder="First Name" name="firstname" [(ngModel)]="contactModel.firstname" #firstname="ngModel" required>
              </label>
            </div>
            <div class="col">
              <label for="lastname">Last Name
                <span style="color: red;">*</span>
                <input class="form-control" type="text" placeholder="Last Name" name="lastname" [(ngModel)]="contactModel.lastname" #lastname="ngModel" required>
              </label>
            </div>
	  </div>
          <div class="row">
            <div class="col">
              <label for="email">Email
                <span style="color: red;">*</span>
                <input class="form-control ng-valid-email" type="email" placeholder="Email" name="email" [(ngModel)]="contactModel.email" #email="ngModel" required email>
              </label>
            </div>
            <div class="col">
              <label for="phone">Mobile Phone
                <span style="color: red;">*</span>
                <input name="phone" class="form-control ng-valid-mask" type="text" [(ngModel)]="contactModel.phone" #phone="ngModel" required placeholder="(___) ___-____" [textMask]="{mask: phoneMask}" (focus)="togglePlaceholder(phone)" (focusout)="togglePlaceholder(phone)">
              </label>
            </div>
            <div class="col">
              <label for="office_zipcode">ZIP Code
                <span style="color: red;">*</span>
                <input name="office_zipcode" class="form-control ng-valid-mask" type="text" [(ngModel)]="contactModel.office_zipcode" #office_zipcode="ngModel" required placeholder="xxxxx" maxlength="10">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center col-12">
      <h5 *ngIf="((f.submitted || firstname.touched) && firstname.invalid)" class="text-danger">First Name cannot be empty.</h5>
      <h5 *ngIf="((f.submitted || lastname.touched) && lastname.invalid)" class="text-danger">Last Name cannot be empty.</h5>
      <h5 *ngIf="(f.submitted || email.touched) && email.errors && email.errors.required" class="text-danger">Email cannot be empty.</h5>
      <h5 *ngIf="(f.submitted || email.touched) && (email.errors && email.errors.email)" class="text-danger">Email must be a valid email address</h5>
      <h5 *ngIf="((f.submitted || phone.touched) && phone.invalid)" class="text-danger">Phone cannot be empty.</h5>
      <h5 *ngIf="((f.submitted || office_zipcode.touched) && office_zipcode.invalid)" class="text-danger">Zip Code cannot be empty.</h5>
    </div>
    <div class="col-12 row no_opt_in">
      <div class="col-12">
        <div class="checkbox" style="cursor:pointer;">
          <label for="opt_in_privacy" style="width: auto; cursor:pointer; font-weight: normal;">
            <input type="checkbox" id="opt_in_privacy" name="opt_in_privacy" [(ngModel)]="contactModel.opt_in_privacy" checked /> 
            I consent to receive automated and recurring text messages from "AbbVie", including service updates and reminder
            notifications about this AbbVie event to the below mobile number. Message and data rates apply. I am not required to
            consent as a condition of receiving goods or services. Reply HELP for help; reply STOP to opt out at any time. For Terms
            and Conditions visit mobile
            <a href="https://privacy.abbvie/us-mobile-terms-and-conditions.html" target="_blank" class="privacy_policy">T&C</a>.
          </label>
          <p>
            AbbVie may collect your personal data through your online and offline interactions with us, including your contact,
            demographic, geolocation, and professional data. We may also collect your online usage data automatically through
            cookies and similar technologies. We use this data for several purposes, such as to comply with our legal obligations,
            to perform a contract with you, to provide you with and improve our programs, services, and products, to customize your
            experiences, and for research and analytics. We retain your personal data for as long as necessary to fulfill these
            purposes or to comply with our record retention obligations. We do not sell your personal data, but we may use and
            disclose it to marketing and advertising partners to deliver you ads based on your interests inferred from your activity
            across other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out of
            the use or disclosure of your personal data for online targeted advertising or for website analytics, go to your 
            <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy Choices</a> on our website. 
            For more information on the data categories we collect, the purposes for their collection, our
            disclosures to third parties, your data subject rights, and our data retention criteria, visit our
            <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank" class="privacy_policy">Privacy Notice</a>.
          </p>
        </div>
      </div>
      <div class="col-12 nopadding">
        <div class="text-right" style="margin-right: -6px;cursor:pointer;">
          <button type="submit" class="btn" [ngClass]="webPage === 'migraine' ? 'grey_btn' : 'btn-warning'" (click)="submitContact(f)">Submit</button>
        </div>
      </div>
    </div>
  </form>
</div>
