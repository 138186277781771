<style type="text/css">
  body {
    padding-bottom: 0;
  }

  header#ubrelvy_header {
    background-color: #3b4748;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  header#ubrelvy_header .navbar-brand {
    color: #fff;
    flex: 1 1 0;
    padding: 14px 0;
  }

  header#ubrelvy_header .navbar-brand span {
    width: 180px;
    display: block;
  }

  header#ubrelvy_header .navbar {
    width: 100%;
    padding: 0 15px;
    justify-content: space-between;
  }

  @media only screen and (max-width: 992px) {
    header#ubrelvy_header .navbar-brand span {
      width: 150px;
    }
  }

</style>
<header id="ubrelvy_header">
  <div class="container">
    <div class="row">
      <nav class="navbar navbar-expand-lg navbar-dark">
        <!-- Brand/logo -->
        <a class="navbar-brand" [routerLink]="['/index']">
					    <span>
					  		<img src="assets/ubrelvy/images/ubrelvy-logo.svg" alt="Ubrelvy Logo">
					  	</span>
        </a>
      </nav>
    </div>
  </div>
</header>
