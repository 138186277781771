import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-savings-information',
  template: `
    <ng-container [ngSwitch]="webPage">
      <migraine-savings-information *ngSwitchCase="'migraine'"></migraine-savings-information>
    </ng-container>`
})
export class SavingsInformationComponent implements OnInit {
  webPage = null;
  hasExpertConnect = false;

  constructor(
    private _configService: ConfigService,
    protected router: Router
  ) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data) => {
          this.webPage = data.client.webpage;
          this.hasExpertConnect = data.client.has_expert_connect;
        },
        (error) => this.webPage = null
      );
  }
}

@Component({
  selector: 'migraine-savings-information',
  templateUrl: '../ui/migraine/savings-information.component.html'
})

export class MigraineSavingsInformationComponent extends SavingsInformationComponent {
  currentRoute: string;

  ngOnInit() {
    super.ngOnInit();
    this.currentRoute = this.router.url;
  }
}

@Component({
  selector: 'ubrelvy-savings-information',
  templateUrl: '../ui/ubrelvy/savings-information.component.html'
})

export class UbrelvySavingsInformationComponent extends SavingsInformationComponent {

}
