<style type="text/css">
  body {
    padding-bottom: 0;
  }

  header#qulipta-header {
    background-color: #38464f;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  header#qulipta-header .navbar-brand {
    color: #fff;
    flex: 1 1 0;
    padding: 14px 0;
  }

  header#qulipta-header .navbar-brand span {
    width: 180px;
    display: block;
  }

  header#qulipta-header .navbar {
    width: 100%;
    padding: 0 15px;
    justify-content: space-between;
  }

  header#qulipta-header nav .navbar-collapse {
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  header#qulipta-header .navbar-nav {
    margin-top: 0;
  }

  header#qulipta-header .navbar-dark .navbar-nav .nav-link {
    color: #fff;
    display: block;
    font-size: 15px;
    line-height: 1em;
    overflow: hidden;
    padding: 5px 0 25px;
    margin-right: 25px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
  }

  header#qulipta-header .navbar-dark .navbar-nav li:last-child .nav-link {
    margin-right: 0
  }

  header#qulipta-header nav .navbar-collapse ul li a:after {
    content: "";
    width: 0;
    height: 5px;
    background-color: #e05c24;
    bottom: 0;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  header#qulipta-header nav .navbar-collapse ul li.active a:after, header#qulipta-header nav .navbar-collapse ul li:hover a:after {
    width: 100%
  }

  .navbar-dark .navbar-toggler {
    color: #fff;
    background: #e05c24;
    border-color: #e05c24;
    opacity: 1;
  }

  @media only screen and (max-width: 992px) {
    header#qulipta-header .navbar-brand span {
      width: 150px;
    }

    header#qulipta-header .navbar-nav {
      padding: 15px 0;
    }

    header#qulipta-header nav .navbar-collapse ul li.active a:after, header#qulipta-header nav .navbar-collapse ul li:hover a:after {
      width: 0;
    }

    header#qulipta-header .navbar-dark .navbar-nav .active > .nav-link, header#qulipta-header nav .navbar-collapse ul li:hover a {
      color: #e05c24;
    }

    header#qulipta-header .navbar-dark .navbar-nav .nav-link {
      font-size: 16px;
    }

    header#qulipta-header .navbar-dark .navbar-nav .nav-link {
      padding: 5px 0 15px;
    }
  }
</style>
<header id="qulipta-header">
  <div class="container">
    <div class="row">
      <nav class="navbar navbar-expand-lg navbar-dark justify-content-between">
        <!-- Brand/logo -->
        <a class="navbar-brand" [routerLink]="['/index']">
          <span>
            <img src="assets/qulipta/images/qulipta-logo.png" class="img-fluid" alt="logo" title="Qulipta">
          </span>
        </a>

        <button class="navbar-toggler hidden-md-up pull-right" type="button"
                (click)="isCollapsed = !isCollapsed">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
          <ul class="navbar-nav">
            <li routerLinkActive="active" class="nav-item">
              <a class="nav-link" routerLink="/qulipta/resources">Support & Savings</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
