<style>
  .modal-dialog {
    transform: translate(0, 2%) !important;
    -webkit-transform: translate(0, 2%) !important;
    max-width: 550px;
  }

  .modal-content {
    min-height: calc(100vh - 75px);
    background-size: cover;
    background-color: #2e2f2f;
    background-image: url('assets/migraine/images/serena-williams.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .modal-body {
    padding: 15px 30px;
    color: #ffffff;
  }

  .modal-body a {
    color: #004a98;
  }

  .modal-body h2 {
    color: #ffffff;
    font-weight: 600;
    margin: 0;
  }

  .modal-body p {
    font-weight: 400;
  }

  .modal-body h2.color_blue {
    color: #7cc2e0;
  }

  .modal-body button.close {
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    margin: 0;
    padding: 2px;
    height: auto !important;
  }

  .modal-body .see_more_btn {
    color: gray;
    background-color: #7cc2e0;
    border-color: #7cc2e0;
    width: 100%;
    margin: 0;
    border-radius: 2px;
    font-weight: 600;
  }
</style>
<!-- Modal -->
<div id="importantNewsModal" tabindex="-1" role="dialog" aria-labelledby="importantNewsModal" aria-hidden="true"
     [ngClass]="showModal ? 'modal fade show'  : 'modal fade hide'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-10 col-sm-8">
            <h2 class="color_blue">DID YOU HEAR?</h2>
            <h2>SERENA WILLIAMS PARTNERS WITH UBRELVY</h2>
            <p class="d-none d-sm-block">
              UBRELVY is proud to partner with world tennis champion Serena Williams, who is living with migraine.
              Serena needs a migraine treatment that she can take anytime, anywhere.
            </p>
            <a [href]="importantNewsLink" target="_blank" class="btn see_more_btn d-none d-sm-block">SEE MORE SERENA</a>
          </div>
          <div class="col-2 col-sm-4">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseBtnClick()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="row d-block d-sm-none">
          <div class="col-12">
            <p>
              UBRELVY is proud to partner with world tennis champion Serena Williams, who is living with migraine.
              Serena needs a migraine treatment that she can take anytime, anywhere.
            </p>
            <a [href]="importantNewsLink" target="_blank" class="btn see_more_btn">SEE MORE SERENA</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Close modal -->
