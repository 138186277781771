import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'custom-modal',
  styles: [`
    #custom_modal_content {
      padding-bottom: 60px;
    }

    #custom_modal_content .modal-header {
      border: 0;
    }

    #custom_modal_content .modal-title {
      color: #555;
      font-size: 1.85em;
      text-transform: uppercase;
      text-align: center;
    }

    .orange_line {
      display: block;
      width: 80px;
      border-bottom: 2px solid #f36f27;
      height: 2px;
      margin: 10px auto;
    }

    .modal-body {
      font-size: 0.9em;
      padding-left: 35px;
      padding-right: 35px;
      text-align: center;
    }

    button.close {
      width: 100%;
      text-align: right;
      margin: 20px 20px 0 0;
      font-size: 1.9em;
      color: #000;
    }`],
  template: `
    <div id="custom_modal_content">
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-header">
        <h4 class="modal-title w-100" [innerHTML]="header_content"></h4>
      </div>
      <span class="orange_line container"> &nbsp; </span>
      <div class="modal-body" *ngIf="body_content" [innerHTML]="body_content">

      </div>
    </div>
  `
})
export class CustomModal {
  @Input('body_content') body_content: string;
  @Input('header_content') header_content: string;
  @Input('redirect_url') redirect_url?: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  closeModal() {
    this.activeModal.dismiss('Cross click');
    if (this.redirect_url) {
      window.location.href = this.redirect_url;
    }
  }
}
