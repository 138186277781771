<!-- Modal -->
<div [ngClass]="showModal && speaker.speaker_id == currentModal ? 'modal fade show'  : 'modal fade hide'" aria-hidden="true" aria-labelledby="speakerModal" id="#speakerModal" role="dialog"
     tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Speaker Bio</h5>
        <button (click)="onCloseBtnClick()" aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="speaker-intro clearfix">
          <div *ngIf="speaker.imageUrl" class="float-left speaker-image-big">
            <img [src]="speaker.imageUrl" class="ng-scope" width="210"/>
          </div>
          <div class="float-left inner-intro">
            <div><b>{{speaker.name}}</b></div>
            <div style="margin-bottom: 10px;">{{speaker.degree}}</div>
            <div>{{speaker.institution}}</div>
          </div>
        </div>

        <div *ngIf="speaker.bio" class='speaker-bio'>
          {{speaker.bio}}
        </div>
        <div *ngIf="!speaker.bio" class="text-center text-muted no-description">
          <p>There is no bio for this speaker.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Close modal -->
