<style type="text/css">
  .specialty_sec {
    background-color: #364FCB;
    padding: 60px 0 180px;
    position: relative;
    margin-bottom: 50px;
  }

  .specialty_sec h1 {
    font-size: 34px;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .specialty_sec h1 span {
    font-size: inherit;
    color: #26DEC2;
    display: block;
  }

  .specialty_col h2 {
    color: #fff;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .specialty_col div > ul > li {
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;
  }

  .specialty_col ul li:last-child {
    margin-bottom: 0;
  }

  .specialty_col ul li input {
    width: 0;
    height: 0;
    vertical-align: middle;
    display: none;
  }

  .specialty_col ul li label {
    padding-left: 25px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    vertical-align: middle;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    width: 100%;
  }

  .specialty_col ul li label:before {
    content: '';
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: solid 2px #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .specialty_col ul li input:checked + label:before {
    border-color: #fff;
    background: #26DEC2;
  }

  .specialty_col ul li ul {
    margin-bottom: 0;
    max-width: 450px;
    justify-content: space-between;
  }

  .specialty_col ul li ul li {
    width: auto;
    margin-bottom: 0;
  }

  ::marker {
    font-size: 0;
  }

  .specialty_col form {
    width: 100%;
  }

  .specialty_col form .form-group {
    width: 100%;
    max-width: 300px;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }

  .specialty_sec ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .specialty_col form .form-group input {
    height: 40px;
    padding: 0 5px;
    margin-right: 10px;
    flex: 2 1 0;
  }

  .specialty_col form .form-group .btn-info {
    font-weight: 600 !important;
    flex: 1 1 0;
    font-size: 14px;
    margin: 0;
    height: 40px !important;
    border-radius: .2rem !important;
  }

  @media only screen and (min-width: 991px) {
    .specialty_sec {
      padding: 60px 0 120px;
    }

    .specialty_sec h1 {
      max-width: 450px;
      font-size: 48px;
      line-height: 54px;
      margin-bottom: 0
    }
  }

  @media only screen and (max-width: 767px) {
    .specialty_sec {
      padding-bottom: 80px;
      margin-bottom: 30px
    }

    .specialty_sec h1 {
      font-size: 25px;
      line-height: 30px;
    }

    .specialty_col h2 {
      font-size: 16px
    }

    .specialty_col div > ul > li {
      margin-bottom: 10px
    }

    .specialty_col ul li label {
      font-size: 14px
    }
  }
</style>
<main>
  <section class="specialty_sec">
    <div class="container">
      <div class="row">
        <aside class="col-md-12 col-lg-6">
          <h1>
            Join Us for an upcoming vuity&reg; speaker program <span>Register Now!</span>
          </h1>
        </aside>
        <aside class="col-md-12 col-lg-6 specialty_col">
          <form class="search-form" name="searchForm" (ngSubmit)="onSearchSubmit(f)"
                #f="ngForm" novalidate>
            <div>
              <h2>Search within</h2>
              <ul>
                <li>
                  <ul class="d-flex">
                    <li *ngFor="let distance of distanceList; let first = first; let last = last">
                      <input type="radio" [(ngModel)]="searchModel.distance" [value]="distance" (change)="onRadioChange()"
                             [id]="distance" class="ng-pristine ng-untouched ng-valid ng-not-empty" name="distance">
                      <label class="radio-inline ng-binding ng-scope" [for]="distance"
                             [ngClass]="{ first: first, last: last }">{{distance}} miles
                      </label>
                    </li>
                  </ul>
                </li>
                <li>
                  <input type="radio" [(ngModel)]="searchModel.distance" value="Virtual" (change)="onRadioChange()"
                         id="Virtual" class="ng-pristine ng-untouched ng-valid ng-not-empty" name="distance">
                  <label class="radio-inline ng-binding ng-scope" for="Virtual">Virtual Programs</label>
                </li>
              </ul>
            </div>
            <div class="form-group zip_section">
              <input class="form-control" placeholder="Zip Code" [(ngModel)]="searchModel.zip" [value]="searchModel.zip"
                     name="zip" #zip="ngModel" type="text" [ngClass]="{ 'is-invalid': f.submitted && zip.invalid }"
                     [required]="searchModel.distance.toString() != 'Virtual' && searchModel.distance.toString() != 'broadcasts'"
                     pattern="(^\d{5}$)|(^\d{5}-\d{4}$)" (change)="onZipChange(zip)" autofocus="autofocus"/>
              <button class="btn btn-info btn-sm">Search</button>
            </div>
            <div *ngIf="(zip.errors && zip.errors.pattern) || invalidzip" class="text-danger">
              The zip code you entered is invalid. Please check and try again.
            </div>
            <div *ngIf="f.submitted && zip.invalid" class="text-danger error-text">
              <div *ngIf="zip.errors.required">Zip code cannot be empty</div>
            </div>
          </form>
        </aside>
      </div>
    </div>
    <div class="specialty_curve">
    </div>
  </section>
</main>
