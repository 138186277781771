<style type="text/css">
  .program_list .online_programs {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .box_container {
    margin-top: 20px;
    border: 0;
    padding: 5px 5px 20px;
    border-bottom: 2px solid #979797;
  }

  .register_button button {
    border-width: 0;
    border-radius: 4px;
    margin: 6px;
    padding: 8px 20px;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
    color: #fff;
  }

  .title span {
    background-color: #337ab7;
    color: white;
    padding: 3px;
    border-radius: 3px;
    width: 280px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    max-width: 80%;
  }

  h3.location {
    margin-top: 24px;
  }
</style>
<app-ondemand-program></app-ondemand-program>
<section class="program_list justify-content-between">
  <div class="loading container text-center" *ngIf="loading"><i class="fa fa-spinner fa-spin"> </i></div>
  <div id="program_list" *ngIf="programList && !loading" class="mt-4">
    <div class="row">
      <div class="col-12 online_programs" *ngIf="virtualPrograms">
        <div class="title">
          <img src="assets/botox/images/online.png" alt="" width="40">
          <span class="ml-2 ml-sm-4">LIVE ONLINE PROGRAMS</span>
        </div>
        <div *ngIf="virtualPrograms && virtualPrograms.length == 0">
          <h3 style="color:red">There are no online programs available at this time. Please check back soon!</h3>
        </div>
        <div *ngFor="let program of virtualPrograms" class="box_container">
          <div class="row inline-list">
            <div class="col-12 col-md-3">
              <h3 class="mb-0"> Title: </h3>
              <p class="m-0"> {{program.topic}} </p>
            </div>
            <div class="col-12 col-md-3">
              <h3 class="mb-0"> Date/Time: </h3>
              <p class="m-0"> {{program.meeting_date}} </p>
              <p class="m-0">{{program.meeting_time}} {{program.timezone}}</p>
              <p class="m-0"> {{program.alternate_time_zones}}</p>
              <h3 class="mb-0 location"> Location: </h3>
              <ng-container
                *ngIf='(program.event_type == "Webcast" && program.meeting_type_name != "Virtual"); else showVirtualLocation'>
                <p class="m-0"> {{program.location.name}}</p>
                <p class="m-0">{{program.location.address}}</p>
                <p class="m-0">{{program.location.city}}, {{program.location.state}} {{program.location.zipcode}}</p>
              </ng-container>
              <ng-template #showVirtualLocation>
                <p class="m-0">Virtual Program</p>
              </ng-template>
            </div>
            <div class="col-12 col-md-3">
              <h3 class="mb-0"> Speaker: </h3>
              <div *ngFor="let speaker of program.speakers">
                <div> {{speaker.name}}<span *ngIf="speaker.degree">, {{speaker.degree}}</span></div>
                <br>
              </div>
            </div>
            <div class="col-12 col-md-3 text-center">
              <div class="register_button">
                <button class="btn btn-success w-100 ml-0" (click)="register(program)">Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
