<style>
  #cookie_consent_section {
    background: #761F76;
    z-index: 101;
    padding: 5px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #cookie_consent_section p {
    text-align: justify;
    margin: 5px;
    color: #ffffff;
    font-size: 13px;
  }

  #cookie_consent_section p a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;
  }

  #cookie_consent_section .read-more-button:hover,
  #cookie_consent_section .read-less-button:hover {
    cursor: pointer;
    border-bottom: 1px solid #000000;
  }

  #cookie_consent_section #button_section button {
    border-color: #03AEA9 !important;
    background-color: #03AEA9 !important;
    color: #fff;
  }

  #cookie_consent_section .content_bottom {
    display: block;
    margin-top: 10px;
  }
</style>
<div id="cookie_consent_section" [ngClass]="showCookieConsent ? 'd-block' : 'd-none'">
  <div class="container">
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <p class="d-none d-sm-block">This website uses cookies and other technologies to personalize content and to show
          you more personalized ads
          (for example, Google Ads and Facebook) on this and other websites, as well as provide you with social media
          features <span class="read-more-button" [ngClass]="showReadMoreButton ? 'd-inline' : 'd-none'"
                         (click)="manageContent()">...[read more]</span>
          <span class="read-more" [ngClass]="!showReadMoreButton ? 'd-inline' : 'd-none'">on this website (such as, Facebook, Twitter, LinkedIn).
            By clicking “Accept” you understand that you are directing AbbVie to
            disclose your personal information, including internet tracking data
            (like cookies and the IP address you used to access this website), to these third parties
            for the purposes stated above.
            <span class="content_bottom">You can still use this website if you do not click “Accept,” but your experience may be different.</span>
            <span class="content_bottom">For more information, including a list of the categories
            of third parties to whom we disclose your personal information, please refer to our
            <a href="https://www.abbvie.com/privacy.html" target="_blank">Privacy Policy</a>
            or for additional options please check your browser’s settings.
              <span class="read-less-button"
                    [ngClass]="showReadLessButton ? 'd-inline' : 'd-none'"
                    (click)="manageContent()">[read less]</span>
            </span>
          </span>
        </p>
        <p class="d-block d-sm-none">This website uses cookies and other technologies to personalize content and to show
          you
          more personalized ads
          <span class="read-more-button" [ngClass]="showReadMoreButton ? 'd-inline' : 'd-none'"
                (click)="manageContent()">...[read more]</span>
          <span class="read-more" [ngClass]="!showReadMoreButton ? 'd-inline' : 'd-none'">
          (for example, Google Ads and Facebook) on this and other websites, as well as provide you with social media
          features on this website (such as, Facebook, Twitter, LinkedIn).
            By clicking “Accept” you understand that you are directing AbbVie to
            disclose your personal information, including internet tracking data
            (like cookies and the IP address you used to access this website), to these third parties
            for the purposes stated above.
            <span class="content_bottom">You can still use this website if you do not click “Accept,” but your experience may be different.</span>
            <span class="content_bottom">For more information, including a list of the categories
            of third parties to whom we disclose your personal information, please refer to our
            <a href="https://www.abbvie.com/privacy.html" target="_blank">Privacy Policy</a>
            or for additional options please check your browser’s settings.
             <span class="read-less-button" [ngClass]="showReadLessButton ? 'd-inline' : 'd-none'"
                   (click)="manageContent()">[read less]</span>
            </span>
          </span>

        </p>
      </div>
      <div id="button_section" class="col-md-2 col-sm-12">
        <button class="btn" (click)="onAcceptBtnClick()">Accept</button>
      </div>
    </div>
  </div>
</div>
