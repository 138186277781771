<style type="text/css">
  .safety_information {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 101;
    max-height: 95px;
    background: #3b4143;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .safety_information.active {
    max-height: 100vh;
  }

  .safety_info_button {
    position: relative;
  }

  .safety_info_button a::before {
    content: '';
    width: 2px;
    height: 45px;
    background: #889492;
    position: absolute;
    left: -25px;
    top: -10px;
  }

  .safety_info_button img {
    width: 22px;
    height: 22px;
  }

  .safety_info_button img[alt='minus'] {
    display: none;
  }

  .safety_information.active .safety_info_button img[alt='minus'] {
    display: block;
  }

  .safety_information.active .safety_info_button img[alt='plus'] {
    display: none;
  }

  .safety_info_aside {
    padding-right: 15px;
  }

  .safety_information strong {
    font-weight: 700;
  }

  .safety_information .safety_info_top {
    padding: 2px 0;
    margin-bottom: 10px;
  }

  .safety_info_top a {
    display: block;
    margin-right: 5px;
    text-decoration: underline;
    color: #fff;
    font-size: 14px;
  }

  .safety_information ul {
    margin-bottom: 0;
    margin-top: 10px;
    list-style: none;
    padding: 0;
  }

  .safety_info_bottom_text ul li {
    position: relative;
    padding-left: 15px;
  }

  .safety_info_bottom_text ul li:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 7px;
  }

  .safety_information ul + p {
    margin-top: 10px;
  }

  .safety_info_bottom_text {
    margin-bottom: 10px
  }

  .safety_info_bottom p, .safety_info_bottom li {
    font-weight: 300;
    color: #fff;
    font-size: 14px;
    margin: 0;
  }

  .safety_info_bottom p {
    margin-bottom: 10px;
  }

  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .safety_info_bottom a {
    color: #fff;
    text-decoration: underline;
  }

  .safety_info_button a {
    text-decoration: none;
    display: block;
  }

  @media only screen and (max-width: 560px) {
    .safety_information ul {
      flex-wrap: wrap;
    }

    .safety_information.active {
      overflow: auto;
    }

    .safety_information ul li {
      width: 100%;
    }

    .safety_info_button a::before {
      left: -20px;
    }
  }
</style>
<section class="safety_information" [class.active]="active" id="safety_information">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between safety_info_top">
          <ul class="d-flex">
            <li>
              <a href="https://media.allergan.com/products/Ubrelvy_pi.pdf" target="_blank">
                Prescribing Information
              </a>
            </li>
          </ul>
        </div>
        <div class="safety_info_bottom d-flex">
          <aside class="safety_info_aside">
            <div class="safety_info_bottom_text">
              <p><strong>IMPORTANT SAFETY INFORMATION</strong></p>
              <p>
                <strong>Contraindication:</strong>
                Concomitant use of strong CYP3A4 inhibitors (eg, ketoconazole, itraconazole, clarithromycin).
              </p>
              <p>
                <strong>Adverse Reactions:</strong>
                The most common adverse reactions were nausea (4% vs 2% placebo) and somnolence (3% vs 1% placebo).
              </p>
              <p><strong>Drug Interactions:</strong></p>
              <ul>
                <li>Strong CYP3A4 Inducers: Should be avoided as concomitant use will result in reduction of ubrogepant
                  exposure.
                </li>
                <li>Dose modifications are recommended when using the following:
                  <p class="margin-bottom-0"><span>‐</span> Moderate or weak CYP3A4 inhibitors and inducers </p>
                  <p class="margin-bottom-0"><span>‐</span> BCRP and/or P-gp only inhibitors</p>
                </li>
              </ul>
              <p><strong>Dosage and Administration:</strong></p>
              <ul>
                <li>The recommended dose is 50 mg or 100 mg taken orally, as needed.</li>
                <li>If needed, a second dose may be administered at least 2 hours after the initial dose.</li>
                <li>The maximum dose in a 24-hour period is 200 mg. The safety of treating more than 8 migraines in a
                  30-day period has not been established.
                </li>
                <li>Severe hepatic or severe renal impairment: Recommended dose is 50 mg; if needed, a second 50 mg dose
                  may be taken at least 2 hours after the initial dose.
                </li>
                <li>Avoid use in patients with end-stage renal disease.</li>
              </ul>
              <p><strong>INDICATION</strong></p>
              <p>UBRELVY&reg; (ubrogepant) is indicated for the acute treatment of migraine
                with or without aura in adults. UBRELVY is not indicated for the preventive treatment of migraine.</p>
              <p>
                <strong>Please see full
                  <a href="https://media.allergan.com/products/Ubrelvy_pi.pdf" target="_blank">
                    Prescribing Information
                  </a>.
                </strong>
              </p></div>
          </aside>
          <aside>
            <div class="safety_info_button">
              <a href="javascript:void(0)" (click)="active = !active">
                <img src="assets/ubrelvy/images/icons/plus.svg" alt="plus">
                <img src="assets/ubrelvy/images/icons/minus.svg" alt="minus">
              </a>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</section>
<ubrelvy-footer></ubrelvy-footer>
