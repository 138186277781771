<style>
  .specialty_sec {
    background-color: #364FCB;
    padding: 60px 0 180px;
    position: relative;
    margin-bottom: 50px;
  }


  .specialty_sec h1 {
    font-size: 34px;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .specialty_sec h1 span {
    font-size: inherit;
    color: #26DEC2;
    padding: 30px 0;
    display: block;
  }

  .upcoming_sec h2 {
    color: #364FCB;
    font-size: 28px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase
  }

  .upcoming_sec p {
    color: #4d4d4f;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }

  .upcoming_box {
    padding: 30px;
    background: #e6eaea;
    margin-bottom: 30px
  }

  .upcoming_box ul {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 0;
  }

  .loadmore_upcoming_event {
    text-align: center;
    display: block;
  }

  .loadmore_upcoming_event p {
    text-align: center !important;
    text-transform: uppercase;
    font-size: 20px !important;
  }

  .loadmore_upcoming_event button {
    min-width: 300px;
    background: #4D3A99;
    color: #fff;
    display: inline-block;
    padding: 15px 0;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
  }

  .btn-register {
    background-color: #4D3A99;
  }

  @media only screen and (min-width: 992px) {
    .specialty_sec {
      padding: 60px 0 120px;
    }

    .specialty_sec h1 {
      font-size: 48px;
      line-height: 54px;
      margin-bottom: 0
    }

    .upcoming_sec h2 {
      font-size: 32px
    }

    .upcoming_sec p {
      font-size: 22px;
      margin-top: 0;
    }

    .upcoming_box ul li > aside {
      flex: 2 1 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .specialty_sec {
      padding-bottom: 30px;
      margin-bottom: 30px
    }

    .specialty_sec h1 {
      font-size: 25px;
      line-height: 30px;
    }

    .specialty_curve:before {
      height: 25% !important;
    }

    .upcoming_sec h2 {
      font-size: 22px
    }

    .upcoming_sec p,
    .upcoming_box ul li > aside h3 {
      font-size: 16px
    }

    .upcoming_box h4 {
      font-size: 14px;
      margin: 0;
    }

    .upcoming_box ul {
      margin-bottom: 10px
    }

    .upcoming_box h4 strong {
      margin-bottom: 5px
    }

    .upcoming_box p {
      font-size: 13px
    }

    .upcoming_box {
      padding: 15px
    }

    .loadmore_upcoming_event a {
      font-size: 14px;
      margin-bottom: 10px
    }

    .loadmore_upcoming_event p {
      font-size: 13px
    }
  }

</style>
<section class="specialty_sec">
  <div class="container">
    <div class="row">
      <aside class="col-md-12 col-lg-6">
        <h1>
          Join Us for an upcoming vuity&reg; speaker program <span>Register Now!</span>
        </h1>
      </aside>
    </div>
    <div class="specialty_curve">
    </div>
  </div>
</section>
<section class="upcoming_sec">
  <div *ngIf="loading" class="loading container text-center"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="!loading" class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2>For U.S. Healthcare Professionals Only </h2>
        <h2>Upcoming Vuity Speaker Programs </h2>
        <p>Learn about Vuity from an expert</p>
        <div class="upcoming_box">
          <div *ngIf="distanceErrorMessage" class="col-12">
            <h3 class="text-center" style="color:red"> {{distanceErrorMessage}} </h3>
          </div>
          <ul>

            <ng-container *ngFor="let program of virtualPrograms; trackBy: trackByProgramId">
              <vuity-single-program (register)="register($event)" *ngIf="program" [canregister]="true"
                                    [program]="program" [webPage]="webPage"></vuity-single-program>
            </ng-container>
          </ul>
          <div class="loadmore_upcoming_event">
            <div *ngIf="!loading" class="row load_more_row text-center">
              <div (click)="loadMorePrograms()" *ngIf="load_more_details.show_load_more && (livePrograms.length > 0 || virtualPrograms.length > 0)" class="col-12"
                   id="load_more_button">
                <button class="btn btn-register">LOAD MORE</button>
              </div>
            </div>
            <p>
              <strong>To register for an educational event over the phone, please call: (888) 591-3998</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
