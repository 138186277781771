import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';

import {ConfigService} from '../config/config.service';
import {AttendeeService} from '../services/index';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Meeting} from '../interfaces/index';
import {AnalyticsService} from '../services/analytics.service';

@Component({
  selector: 'app-header',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-header *ngSwitchCase="'vraylar'"></vraylar-header>
      <migraine-header *ngSwitchCase="'migraine'"></migraine-header>
      <vuity-header *ngSwitchCase="'vuity'"></vuity-header>
      <botox-header *ngSwitchCase="'botox'"></botox-header>
    </ng-container>`
})
export class HeaderComponent implements OnInit {

  webPage = null;
  program_id: String;

  constructor(protected router: Router,
              protected cdr: ChangeDetectorRef,
              protected _configService: ConfigService,
              protected _attendeeService: AttendeeService,
              protected _renderer2: Renderer2,
              protected _analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this._configService.getWebPage()
      .subscribe((data) => {
          this.webPage = data;
        },
        (error) => this.webPage = null
      );
  }

}

@Component({
  selector: 'botox-header',
  templateUrl: '../ui/botox/header.component.html',
  encapsulation: ViewEncapsulation.None
})

export class BotoxHeaderComponent extends HeaderComponent {
  ngOnInit() {
    super.ngOnInit();
    this._analyticsService.initiate(this._renderer2);
  }
}

@Component({
  selector: 'vraylar-header',
  templateUrl: '../ui/vraylar/header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class VraylarHeaderComponent extends HeaderComponent {
}

@Component({
  selector: 'migraine-header',
  templateUrl: '../ui/migraine/header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class MigraineHeaderComponent extends HeaderComponent {
  route = '';

  ngOnInit() {
    super.ngOnInit();
    this.route = this.router.url;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.route = event.urlAfterRedirects;
      this.cdr.detectChanges();
    });
  }
}


@Component({
  selector: 'vuity-header',
  templateUrl: '../ui/vuity/header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class VuityHeaderComponent extends HeaderComponent {
  redirectRoute = '/home';

  ngOnInit() {
    super.ngOnInit();
    this.updateLogoUrl(this.router.url);
    this._analyticsService.initiate(this._renderer2);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects;
      this.updateLogoUrl(url);
      this.cdr.detectChanges();
    });
  }

  updateLogoUrl(url: string) {
    if (url === '/home' || url === '/broadcast') {
      this.redirectRoute = url;
    } else if (url.includes('register') || url.includes('confirm')) {
      const params = url.split('/');
      this._attendeeService.getCurrentProgram(params[2])
        .subscribe((program: Meeting) => {
          if (program.event_type_sub_code === 'BRDPGM') {
            this.redirectRoute = '/broadcast';
          } else {
            this.redirectRoute = '/home';
          }
          this.cdr.detectChanges();
        });
    }
  }
}

@Component({
  selector: 'ubrelvy-header',
  templateUrl: '../ui/ubrelvy/header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class UbrelvyHeaderComponent extends HeaderComponent {
}

@Component({
  selector: 'qulipta-header',
  templateUrl: '../ui/qulipta/header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class QuliptaHeaderComponent extends HeaderComponent {
  isCollapsed = true;
}
