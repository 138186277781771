<style type="text/css">
  #vraylar_footer {
    background-color: #a9adac;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  #vraylar_footer p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0;
  }

  .with-trademark {
    position: relative;
    padding-right: 6px;
    margin-right: 3px;
  }

  .with-trademark i {
    font-style: normal;
    position: absolute;
    right: 0;
    font-size: 11px;
  }

  .privacy-links a {
    text-decoration: underline;
    padding: 0 10px 0 0 !important;
    border: none !important;
    background: none !important;
    color: #fff !important;
    font-size: 14px !important;
  }

  .check-icon {
    width: 30px;
    height: 14px;
  }

  .privacy-links a.privacy-choice{
    padding: 0px  !important;
  }
</style>
<footer id="vraylar_footer">
  <div class="container-full">
    <div class="row">
      <div class="col-12 footer-inner-wrap">
        <p class="privacy-links">
          <a href="https://www.abbvie.com/accessibility-statement.html" target="_blank">
            Accessibility Statement 
          </a>
          <a href="https://www.abbvie.com/contactus.html" target="_blank">
            Contact Us
          </a>
          <a href="https://www.abbvie.com/termsofuse.html" target="_blank">
            Terms Of Use
          </a>
          <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank">
            Privacy Notice
          </a>
          <a class="ot-sdk-show-settings" href="javascript:void(0)" id="ot-sdk-btn"
             title="Overridden by OneTrust">Cookies Settings</a>
          <span class="check-icon-align">
            <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy-choice">Your Privacy Choices</a>&nbsp;<img
            class="check-icon" src="assets/vraylar/images/privacyoptions.svg" alt="check">
          </span>
        </p>
        <p>Licensed from Gedeon Richter Plc.</p>
        <p>© 2023 AbbVie. All rights reserved.</p>
        <p class="with-trademark">VRAYLAR<sup>&reg;</sup> and its design are trademarks of Allergan Pharmaceuticals
            International Limited, an AbbVie company.</p>
        <p>US-VRA-230217 10/23</p>
      </div>
    </div>
  </div>
</footer>
