<style type="text/css">
  .self-center {
    margin: 0 auto;
  }

  a {
    color: #4d4d4f !important;
  }
</style>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="program">
    <div>
      <h1 *ngIf="attendee_status == 2">Thank you for attending the VRAYLAR<sup>&reg;</sup> Speaker Program.<br></h1>
      <h1 *ngIf="attendee_status == 1">Thank you for registering for the VRAYLAR<sup>&reg;</sup> Speaker Program.<br></h1>
      <h1 *ngIf="attendee_status == 0">Thank you for your response for the VRAYLAR<sup>&reg;</sup> Speaker Program. <br></h1>
      <h5 class="text-center" *ngIf="attendee_status != 2"><span>If you have any questions, please call <a href="tel:1-888-335-8430"
                                                                                 class="d-inline-block">1-888-335-8430.</a></span>
      </h5>
    </div>
    <div class="row" *ngIf="attendee_status != 0">
      <div class=" col-12 col-sm-6 self-center">
        <vraylar-single-program [program]="program" [canregister]="false" *ngIf="program"></vraylar-single-program>
      </div>
    </div>
  </div>
</div>
