<style type="text/css">
  .cards_sec {
    width: 100%;
    display: block;
    background: #72cdd9;
    padding: 50px 0
  }

  .cards_sec > .row {
    max-width: 1100px;
    margin: 0 auto
  }

  .cards_sec aside {
    padding: 0 30px;;
    margin-top: 0;
    margin-bottom: 50px
  }

  .cards_sec aside > div {
    height: 100%;
    background: #fff;
    box-shadow: 0 4px 15px #151515;
    -webkit-box-shadow: 0 4px 15px #151515;
    -moz-box-shadow: 0 4px 15px #151515;
    -ms-box-shadow: 0 4px 15px #151515;
    -o-box-shadow: 0 4px 15px #151515;
    padding: 25px;
    text-align: center;
    position: relative;
  }

  .cards_sec aside > div:hover {
    -webkit-box-shadow: 0 7px 20px #151515;
    -moz-box-shadow: 0 7px 20px #151515;
    -ms-box-shadow: 0 7px 20px #151515;
    -o-box-shadow: 0 7px 20px #151515;
    box-shadow: 0 7px 20px #151515;
  }

  .cards_sec aside figure {
    width: 120px;
    height: 120px;
    margin: 0 auto 30px;
    border-radius: 100%;
    background: #fff;
  }

  .cards_sec aside figure img {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 0;
  }

  .cards_sec aside h2 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 30px;
    color: #4d4d4f;
  }

  .cards_sec aside p {
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #4d4d4f;
  }

  .cards_sec aside a.arrow_card {
    width: 60px;
    height: 60px;
    background: #42b957;
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    line-height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cards_sec .cards_box:hover a.arrow_card {
    background: #78cce7
  }

  .right_arrow {
    margin-left: -2px
  }

  .right_arrow:after {
    content: '';
    border: solid 3px #fff;
    display: block;
    border-left: transparent;
    border-bottom: transparent;
    width: 11px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .orders_sec {
    width: 100%;
    padding: 30px 0
  }

  .orders_sec .row {
    max-width: 1000px;
    margin: 0 auto
  }

  .orders_sec aside {
    margin-bottom: 15px
  }

  .orders_sec aside:last-child {
    margin-bottom: 0
  }

  aside.orders_card > div a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 10px 15px;
    border: solid 2px #72cdd9;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  aside.orders_card > div a:hover {
    background: #72cdd9;
  }

  aside.orders_card > div a:hover h3 {
    color: #fff;
  }

  aside.orders_card > div a:hover img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    -moz-filter: brightness(0) invert(1);
    -ms-filter: brightness(0) invert(1);
    -o-filter: brightness(0) invert(1);
  }

  aside.orders_card > div figure {
    width: 40px;
    margin: 0;
    height: auto;
    position: relative;
  }

  aside.orders_card > div h3 {
    flex: 4;
    font-size: 16px;
    padding: 0 30px;
    text-transform: uppercase;
    color: #4d4d4f;
  }

  aside.orders_card > div figure:after {
    content: '';
    width: 2px;
    height: 70%;
    background: #eeefed;
    position: absolute;
    top: 8px;
    right: -14px;
  }

  aside.orders_card > div .window_icon {
    width: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
  }

  .orders_sec .orders_card a:hover {
    text-decoration: none;
  }


  @media only screen and (min-width: 992px) {
    .cards_sec aside {
      margin-top: -150px;
      margin-bottom: 0
    }

    .cards_sec aside figure img {
      margin-top: -90px
    }
  }

  @media only screen and (max-width: 767px) {
    .cards_sec {
      padding: 20px 0
    }

    .cards_sec aside figure {
      width: 90px;
      height: 90px;
      margin-bottom: 10px
    }

    .cards_sec aside h2 {
      margin-bottom: 5px;
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 992px) {
    .orders_sec .row aside {
      margin-bottom: 0
    }
  }

  @media only screen and (max-width: 767px) {
    .orders_sec {
      padding: 30px 0
    }

    aside.orders_card > div h3 {
      font-size: 14px
    }

    aside.orders_card > div figure {
      width: 30px
    }

  }
</style>
<div class="callout_banner">
</div>
<section class="cards_sec">
  <div class="container">
    <div class="row">
      <aside class="col-md-12 offset-lg-4 col-lg-4">
        <div class="cards_box">
          <figure><img class="img-fluid" src="assets/ubrelvy/images/icons/system_arrow.png" alt="Register Program">
          </figure>
          <h2>Register For A Program Now!</h2>
          <a routerLink="/index" class="arrow_card">
            <i class="right_arrow"></i>
          </a>
        </div>
      </aside>
    </div>
  </div>
</section>
<section class="orders_sec">
  <div class="container">
    <div class="row">
      <aside class="orders_card col-sm-12 col-md-6 col-lg-4">
        <div>
          <a href="https://www.mysamplecloset.com/allergan" target="_blank">
            <figure><img class="img-fluid" src="assets/ubrelvy/images/icons/orders.png" alt="Order Samples Now">
            </figure>
            <h3>Order Samples Now!</h3>
            <img class="img-fluid window_icon" src="assets/ubrelvy/images/icons/window-icon.svg" alt="window Icon">
          </a>
        </div>
      </aside>
      <aside class="orders_card col-sm-12 col-md-6 col-lg-4">
        <div>
          <a href="https://www.ubrelvyhcp.com/dosing" target="_blank">
            <figure><img class="img-fluid" src="assets/ubrelvy/images/icons/dosing-capsules.png"
                         alt="Order Samples Now">
            </figure>
            <h3>Dosing Information</h3>
            <img class="img-fluid window_icon" src="assets/ubrelvy/images/icons/window-icon.svg" alt="window Icon">
          </a>
        </div>
      </aside>
      <aside class="orders_card col-sm-12 col-md-6 col-lg-4">
        <div>
          <a href="https://www.allerganaccess.com/request-a-rep-form?brand=Ubrelvy" target="_blank">
            <figure><img class="img-fluid" src="assets/ubrelvy/images/icons/request-representative.png"
                         alt="Order Samples Now"></figure>
            <h3>Request a Representative</h3>
            <img class="img-fluid window_icon" src="assets/ubrelvy/images/icons/window-icon.svg" alt="window Icon">
          </a>
        </div>
      </aside>
    </div>
  </div>
</section>
