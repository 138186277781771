import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Config} from '../config/config';

import {APIService, AttendeeService} from '../services';
import {ConfigService} from '../config/config.service';
import {AnalyticsService} from '../services/analytics.service';

import {Meeting} from '../interfaces';
import {LoadMoreObject} from '../interfaces/search';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-list',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-list *ngSwitchCase="'vraylar'"></vraylar-list>
      <migraine-list *ngSwitchCase="'migraine'"></migraine-list>
      <vuity-list *ngSwitchCase="'vuity'"></vuity-list>
      <botox-list *ngSwitchCase="'botox'"></botox-list>
    </ng-container>`
})
export class ListComponent implements OnInit {
  livePrograms: Meeting[];
  virtualPrograms: Meeting[];
  webPage = null;
  externalWebUrl = null;
  loading: Boolean;
  programList: Boolean = false;
  searchParams;
  distanceErrorMessage: string;
  load_more_details: LoadMoreObject = {page: 1, show_load_more: false};


  constructor(protected _router: Router,
              protected _configService: ConfigService,
              protected _attendeeService: AttendeeService,
              protected _apiService: APIService,
              protected viewportScroller: ViewportScroller,
              protected _analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );

  }

  getMeetingList() {
    let result_count = 0;
    // this.loading = true;
    this.programList = false;
    this._attendeeService.getMeetingLists().subscribe(programs => {
      result_count++;
      this.livePrograms = programs['live'];
      this.virtualPrograms = programs['virtual'];
      if (result_count == 2 || (this.livePrograms.length > 0 && this.virtualPrograms.length > 0)) {
        this.loading = false;
        this.programList = true;
      }
      this.searchParams = this._attendeeService.searchParams;
    });
  }


  register(program: Meeting): void {
    this._attendeeService.setCurrentProgram(program);
    if (!program.external) {
      this._router.navigate(['register/' + program.program_id]);
    } else {
      if (this.externalWebUrl) {
        let site_name = this.webPage.charAt(0).toUpperCase() + this.webPage.slice(1);
        let url = this.externalWebUrl + '?programNumber=' + program.program_id + '&postalCode=0&SiteName=' + site_name + '&Vendor=Medforce';
        window.location.href = url;
      } else {
        alert('This is external program and registration will open on production only');
      }
    }
  }

  trackByProgramId(index: number, program: Meeting) {
    return program.program_id;
  }
}

@Component({
  selector: 'botox-list',
  templateUrl: '../ui/botox/list.component.html'
})
export class BotoxListComponent extends ListComponent {
  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.externalWebUrl = data.client.externalUri;
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );

    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.loading = status;
      });
    this.getMeetingList();
  }

  getMeetingList() {
    let result_count = 0;
    // this.loading = true;
    this.programList = false;
    this._attendeeService.getMeetingLists().subscribe(programs => {
      result_count++;
      this.virtualPrograms = programs['virtual'];
      if (result_count == 2 || this.virtualPrograms.length > 0) {
        this.loading = false;
        this.programList = true;
      }
      this.searchParams = this._attendeeService.searchParams;
    });
  }
}

@Component({
  selector: 'vraylar-list',
  templateUrl: '../ui/vraylar/list.component.html'
})
export class VraylarListComponent extends ListComponent {
  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.externalWebUrl = data.client.externalUri;
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.loading = status;
      });

    this.getMeetingList();
    this._attendeeService.getLoadMoreDetails()
      .subscribe(status => {
        this.load_more_details = status;
      });
  }

  getMeetingList() {
    let result_count = 0;
    // this.loading = true;
    this.programList = false;
    this._attendeeService.getMeetingLists().subscribe(programs => {
      result_count++;
      this.livePrograms = programs['live'];
      this.virtualPrograms = programs['virtual'];

      if (result_count == 2 || (this.livePrograms.length > 0 && this.virtualPrograms.length > 0)) {
        this.loading = false;
        this.programList = true;
      }
      this.distanceErrorMessage = '';
      if (!programs['clear_meeting_list']) {
        this.getDistanceErrorMessage(this._attendeeService.searchDistance, this.livePrograms, this.virtualPrograms);
      }
      this.searchParams = this._attendeeService.searchParams;
    });
  }

  getDistanceErrorMessage(searchDistance, livePrograms, virtualPrograms): void {
    if (searchDistance != 'Virtual' && searchDistance != undefined && livePrograms && livePrograms.length == 0) {
      this.distanceErrorMessage = 'There are no upcoming programs currently scheduled within a ' + searchDistance +
        ' mile radius of the zip code you entered. Please increase the mile radius to expand your search, or join us for a Virtual program.';
    } else if (searchDistance == 'Virtual' && virtualPrograms && virtualPrograms.length == 0) {
      this.distanceErrorMessage = 'There is currently no upcoming virtual program.';
    }
  }

  submitProbabletarget(target) {
    target.phones = [{
      'type': 'cell',
      'phone': target.phone
    }];
    target.opt_in_privacy = (target.opt_in_privacy == true) ? 1 : 0;
    this._apiService.post(this.webPage + '/futureContact', target)
      .subscribe(results => {
          console.log(results);
        },
        error => {
          console.log(error);
        });
  }

  register(program: Meeting): void {
    this._router.navigate(['register/' + program.program_id]);
  }

  loadMorePrograms() {
    this.searchParams.page += 1;
    const scrollHeight = this.viewportScroller.getScrollPosition();
    this._attendeeService.setMeetingLists(this.webPage, this.searchParams)
      .finally(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition(scrollHeight);
        }, 100);
      })
      .subscribe(success => {
        },
        (error) => {
        }
      );
  }
}

@Component({
  selector: 'migraine-list',
  templateUrl: '../ui/migraine/list.component.html'
})

export class MigraineListComponent extends ListComponent {
  importantNewsLink = '';

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.externalWebUrl = data.client.externalUri;
          this.webPage = data.client.webpage;
          if (data.client.has_important_news_link) {
            this.importantNewsLink = data.client.has_important_news_link;
          }
        },
        (error) => this.webPage = null
      );
    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.loading = status;
      });

    this.getMeetingList();
    this._attendeeService.getLoadMoreDetails()
      .subscribe(status => {
        this.load_more_details = status;
      });
  }

  getMeetingList() {
    let result_count = 0;
    // this.loading = true;
    this.programList = false;
    this._attendeeService.getMeetingLists().subscribe(programs => {
      result_count++;
      this.livePrograms = programs['live'];
      this.virtualPrograms = programs['virtual'];

      if (result_count == 2 || (this.livePrograms.length > 0 && this.virtualPrograms.length > 0)) {
        this.loading = false;
        this.programList = true;
      }
      this.distanceErrorMessage = '';
      if (!programs['clear_meeting_list']) {
        this.getDistanceErrorMessage(this._attendeeService.searchDistance, this.livePrograms, this.virtualPrograms);
      }
      this.searchParams = this._attendeeService.searchParams;
    });
  }

  getDistanceErrorMessage(searchDistance, livePrograms, virtualPrograms): void {
    if (searchDistance != 'Virtual' && searchDistance != 'broadcasts'
      && searchDistance != undefined && livePrograms && livePrograms.length == 0) {
      this.distanceErrorMessage = 'There are no upcoming programs currently scheduled within a ' + searchDistance +
        '-mile radius of the zip code you entered. Please increase the mile radius to expand your search, or join us for a virtual program.';
    } else if ((searchDistance == 'Virtual' || searchDistance == 'broadcasts') && virtualPrograms && virtualPrograms.length == 0) {
      this.distanceErrorMessage = 'There are currently no upcoming ' + searchDistance.toLowerCase() + ' programs.';
    }
  }

  submitProbabletarget(target) {
    target.phones = [{
      'type': 'cell',
      'phone': target.phone
    }];
    target.opt_in_privacy = (target.opt_in_privacy == true) ? 1 : 0;
    this._apiService.post(this.webPage + '/futureContact', target)
      .subscribe(results => {
        },
        error => {
          console.log(error);
        });
  }

  register(program: Meeting): void {
    this._router.navigate(['register/' + program.program_id]);
  }

  loadMorePrograms() {
    this.searchParams.page += 1;
    const scrollHeight = this.viewportScroller.getScrollPosition();
    this._attendeeService.setMeetingLists(this.webPage, this.searchParams)
      .finally(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition(scrollHeight);
        }, 100);
      })
      .subscribe(success => {
        },
        (error) => {
        }
      );
  }
}

@Component({
  selector: 'vuity-list',
  templateUrl: '../ui/vuity/list.component.html'
})

export class VuityListComponent extends ListComponent {
  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.externalWebUrl = data.client.externalUri;
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
    this._attendeeService.isDataLoading()
      .subscribe(status => {
        this.loading = status;
      });

    this.getMeetingList();
    this._attendeeService.getLoadMoreDetails()
      .subscribe(status => {
        this.load_more_details = status;
      });
  }

  getMeetingList() {
    let result_count = 0;
    this.programList = false;
    this._attendeeService.getMeetingLists().subscribe(programs => {
      result_count++;
      this.livePrograms = programs['live'];
      this.virtualPrograms = programs['virtual'];

      if (result_count == 2 || (this.livePrograms.length > 0 && this.virtualPrograms.length > 0)) {
        this.loading = false;
        this.programList = true;
      }
      this.distanceErrorMessage = '';
      if (!programs['clear_meeting_list']) {
        this.getDistanceErrorMessage(this._attendeeService.searchDistance, this.livePrograms, this.virtualPrograms);
      }
      this.searchParams = this._attendeeService.searchParams;
    });
  }

  getDistanceErrorMessage(searchDistance, livePrograms, virtualPrograms): void {
    if (searchDistance != 'Virtual' && searchDistance != 'broadcasts'
      && searchDistance != undefined && livePrograms && livePrograms.length == 0) {
      this.distanceErrorMessage = 'There are no upcoming programs currently scheduled within a ' + searchDistance +
        '-mile radius of the zip code you entered. Please increase the mile radius to expand your search, or join us for a virtual program.';
    } else if ((searchDistance == 'Virtual' || searchDistance == 'broadcasts') && virtualPrograms && virtualPrograms.length == 0) {
      this.distanceErrorMessage = 'There are currently no upcoming ' + searchDistance.toLowerCase() + ' programs.';
    }
  }

  register(program: Meeting): void {
    this.trackInteraction(program.program_id);
    this._router.navigate(['register/' + program.program_id]);
  }

  trackInteraction(program_id) {
    const analyticObject = {
      link: {
        name: program_id,
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: 'Register',
        name: 'Upcoming Vuity Speaker Programs',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'CTA',
        name: ''
      },
      form: {
        name: '',
        category: '',
        subCategory: '',
        fieldName: '',
        fieldValue: '',
        fieldValues: '',
        stepName: '',
        stepNumber: '',
        error: ''
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  loadMorePrograms() {
    this.searchParams.page += 1;
    const scrollHeight = this.viewportScroller.getScrollPosition();
    this._attendeeService.setMeetingLists(this.webPage, this.searchParams)
      .finally(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition(scrollHeight);
        }, 100);
      })
      .subscribe(success => {
        },
        (error) => {
        }
      );
  }
}
