<style type="text/css">
body {
  padding-bottom: 0;
}

#nav_bar {
  height: 38px;
  background-color: #761F76;
  color: #ffffff;
}

#nav_bar a {
  text-decoration: underline;
}

#nav_bar p{
  margin: 0 auto;
}

header {
  margin-bottom: 15px;
}

header .logos {
  padding: 1rem 0 0 0;
}

.varylar_logo img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.allergan_logo img {
  width: auto;
  height: 70px;
}

.sponsored {
  font-size: 11px;
  color: #4d4d4f;
  font-weight: 400;
}

@media only screen and (max-width: 465px) {

  #nav_bar {
    height: 52px;
  }
}

</style>
<div class="navbar navbar-dark" id="nav_bar">
  <p>
    <b>Please see full </b>
    <a href="https://www.allergan.com/assets/pdf/vraylar_pi" target="_blank">
      Prescribing Information</a>
    <b>, including Boxed Warnings.</b>
  </p>
</div>
<header class="container">
  <div class="row logos">
    <div class="col-6">
      <a href="http://www.vraylarhcp.com/" target="_blank">
        <picture class="varylar_logo">
                            <!--[if IE 9]>
       <video style="display: none;">
          <![endif]-->
                            <!--[if IE 9]>
       </video>
       <![endif]-->
          <img src="assets/vraylar/images/vraylar-logo.png" alt="Vraylar Logo" >
        </picture>
    </a>
    </div>
    <div class="col-6 text-right justify-content-end row" id="header_downloads">
      <div>
        <div class="sponsored text-left">
          Sponsored by
        </div>
        <div class="allergan_logo">
          <img class="allergan-component--header_company_logo-space" src="assets/vraylar/images/abbvie-logo.png" alt="AbbVie Logo" />
    </div>
        </div>
      </div>
    </div>
</header>
