import {Inject, Renderer2, Injectable } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { ConfigService } from '../config/config.service';
@Injectable()
export class AnalyticsService {

  private _webPage: string;

  constructor(@Inject(DOCUMENT) private document: Document,
    private _configService: ConfigService
  ) {
  }

  initiate(renderer: Renderer2): HTMLScriptElement {
    this._configService.getWebPage()
      .subscribe((data) => {
        this._webPage = data;
      });
  
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = `../../assets/${this._webPage}/analytics.js`;
    renderer.appendChild(this.document.head, script);
    return script;
  }

  updateStateObject(obj: any) {
    (<any>this.document.defaultView).updateState(obj);
  }

  updateActionObject(obj: any) {
    (<any>this.document.defaultView).updateAction(obj);
  }
}
