<section class="speakers" id="{{webPage}}_speakers">
  <div class="right_bar_header">
    <div class="row">
      <div class="col-3 marker">
        <i class="fa fa-user-md"></i>
        <img src="assets/images/icon_pat.png" class="" alt="Icon pat call center" />
      </div>
      <div class="col-9 title"> {{title}} </div>
    </div>
  </div>
  <div class="content">
    <div *ngFor='let speaker of speakers'>
        <app-speaker-info [speaker]='speaker' [webPage]="webPage"></app-speaker-info>
    </div>
  </div>
</section>
