<style type="text/css">
  .program_list .ondemand_programs {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .box_container {
    margin-top: 20px;
    border: 0;
    padding: 5px 5px 20px;
  }

  .register_button button {
    border-width: 0;
    border-radius: 4px;
    margin: 6px;
    padding: 8px 20px;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
    color: #fff;
  }

  .title span {
    background-color: #337ab7;
    color: white;
    padding: 3px;
    border-radius: 3px;
    width: 280px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    max-width: 80%;
  }

  h3.location {
    margin-top: 24px;
  }

  @media screen and (max-width: 480px) {
    .h3 {
      font-size: 14px !important;
    }
  }
</style>
<section class="program_list justify-content-between" *ngIf="program && program.meeting_id">
    <div id="program_list" class="mt-4">
      <div class="row">
        <div class="col-12 ondemand_programs">
          <div class="title">
            <img src="assets/botox/images/play.png" alt="" width="40">
            <span class="ml-2 ml-sm-4">ON DEMAND VIDEO</span>
          </div>
          <div class="box_container">
            <div class="row inline-list">
              <div class="col-12 col-md-9">
                <div>
                  <h3 class="mb-0">BOTOX® for Adult Spasticity: Understanding Treatment and “What to Expect” with Botox® for Adult Spasticity</h3>
                </div>
              </div>
              <div class="col-12 col-md-3 text-center">
                <div class="register_button">
                  <button class="btn btn-success w-100 ml-0" (click)="playVideo(program)">Watch Video</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-watch-video-modal *ngIf="showModal" [program]="selectedProgram" (closeModalEvent)="closeModal()"></app-watch-video-modal>