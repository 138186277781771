import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

import * as $ from 'jquery';

import {Meeting, Attendee} from '../interfaces/index';

import {ConfigService} from '../config/config.service';
import {APIService, AttendeeService} from '../services/index';
import {Config} from '../config/config';
import {Meta, Title} from '@angular/platform-browser';
import {SignaturePadComponent} from '../signature-pad/signature-pad.component';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-attendee-search',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-attendee-search *ngSwitchCase="'vraylar'"></vraylar-attendee-search>
    </ng-container>`
})
export class AttendeeSearchComponent implements OnInit {
  webPage;
  programType;
  program_id: String;
  program: Meeting;
  attendeeList: Attendee[] = [];
  attendee;

  initial: Boolean = true;
  loading;
  loading_attendee: Boolean = false;
  selected_attendee_id: number = null;
  meeting_unavailable: Boolean = false;

  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  states: Object[];
  cities: Object[];

  //status flags;
  signing = false;
  signing_failed = false;
  errorMsg = "Attendance Signature failed. Please try again.";
  errorRSVP = false;
  addNewLink: String;

  searchAttendeeModel = {
    firstname: null,
    lastname: null,
    state: null,
    city: null,
    license_number: null
  };

  showSignature = false;
  validSignature = false;
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': window.innerWidth > 768 ? 500 : 350,
    'canvasHeight': 170,
    'backgroundColor': 'rgb(255, 255, 255)'
  };

  constructor(
    protected _activeRouter: ActivatedRoute,
    protected _router: Router,
    protected _configService: ConfigService,
    protected _apiService: APIService,
    protected _attendeeService: AttendeeService,
    protected titleService: Title,
    protected renderer: Renderer2,
    private meta: Meta
  ) { }

  ngOnInit() {
    this._configService.getWebPage()
      .subscribe((data) => {
        this.webPage = data;
        //this.getStates();
      },
        (error) => this.webPage = null
      );
    this._configService.getConfig()
      .subscribe((data: Config) => {
        if (data.client.has_meta_description) {
          this.meta.updateTag({ name: 'description', content: data.client.registration_meta_description })
        }
      });
  }

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getWebPage()
        .subscribe((data) => {
          this.webPage = data;
          this.getStates();

          this._activeRouter.params
            .subscribe(params => {
              this.program_id = params['program_id'];
              this.loading = true;
              this._attendeeService.getCurrentProgram(this.program_id, {confirming_attendance: true})
                .subscribe((program: Meeting) => {
                  this.program = program;
                  this.program_id = this.program.program_id;
                  this.loading = false;
                  this.addNewLink = '/attend/' + this.program_id + '/new/1';
                  observer.next(true);
                }, error => {
                  this.loading = false;
                  this.meeting_unavailable = true;
                })
            },
              error => {
                console.log(error);
                observer.error(false);
              })
        },
          (error) => {
            this.webPage = null;
            observer.error(false);
          }
        );
    })
  }

  clearSignature() {
    this.signaturePad.clear();
    this.validSignature = false;
  }

  drawStart() {
    this.validSignature = true;
  }

  getStates() {
    this._apiService.get(this.webPage + '/getStates', [])
      .subscribe(states => {
        this.states = states;
      }
        , (error) => { console.log('Problem getting states') }
      );
  }

  getCities(state) {
    this.searchAttendeeModel.city = null;
    this.cities = [];
    if (state.value == null || state.value == "null") {
      return;
    }
    this._apiService.get(this.webPage + '/getCities/' + state.value, [])
      .subscribe(cities => {
          this.cities = cities;
        }
        , (error) => { console.log('Problem getting cities') }
      );
  }

  getAddressList = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        if (term.length < 2) {
          return [];
        } else {
          this._apiService.getGeoAddresses(text$)
            .subscribe(function(data) {
              return data
            })
        }
      })
    )

  cancel() {
    this._router.navigate(['home']);
  }

  attend() {
    //Check if target is selected
    if (!this.attendee || !this.attendee.attendee_id) {
      this.errorMsg = "Please search and select yourself from above.";
      this.errorRSVP = true;
      return;
    }

    if (this.signaturePad.isEmpty()) {
      return;
    }
    this.attendee.rsvp_signature = this.signaturePad.toDataURL().split('data:image/png;base64,')[1];
    this.attendee.attended = true;
    this.signing = true;

    this._attendeeService.signAttendance(this.program_id, this.attendee)
      .subscribe(status => {
          let attendee_status = 2;
          this.signing = false;
          this._router.navigate(['confirm/' + this.program_id + '/' + attendee_status]);
        },
        error => {
          this.signing = false;
          $("html, body").animate({ scrollTop: 0 }, "slow");
          if (error.message) {
            this.errorMsg = error.message;
          }
        });
  }

  onAttendeeCheckbox(event, attendee) {
    this.resetAttendee();
    if (event.target.checked) {
      this.selectAttendeeForSignIn(attendee);
    }
  }

  resetAttendee() {
    this.showSignature = false;
    this.selected_attendee_id = null;
    this.attendee = null;
  }

  selectAttendeeForSignIn(attendee) {
    this.attendee = attendee;
    this.selected_attendee_id = attendee.attendee_id;
    this.showSignature = true;
  }

  searchAttendee(form) {
    if (!form.valid) {
      return;
    }

    this.initial = false;
    this.loading_attendee = true;
    this.showSignature = false;
    this.searchAttendeeModel.state = this.searchAttendeeModel.state == "null" ? null : this.searchAttendeeModel.state;
    this.searchAttendeeModel.city = this.searchAttendeeModel.city == "null" ? null : this.searchAttendeeModel.city;
    this._attendeeService.searchMeetingAttendees(this.program_id, this.searchAttendeeModel)
      .subscribe(data => {
          this.attendeeList = data;
          this.loading_attendee = false;
        },
        (error) => {
          this.loading_attendee = false;
          console.log('Failed to load Attendee');
        })
  }

  trackByAttendeeId(index: number, attendee: Attendee) {
    return attendee.attendee_id;
  }
}

/*
Vraylar Register Component Class
 */
@Component({
  selector: 'vraylar-attendee-search',
  templateUrl: '../ui/vraylar/attendee-search.component.html'
})

export class VraylarAttendeeSearchComponent extends AttendeeSearchComponent {
    ngOnInit() {
    this.initializeClient().subscribe(() => {
      },
      error => {
        console.log(error);
      });
  }
}