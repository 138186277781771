import {Component, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-speaker-info',
  templateUrl: './speaker-info.component.html'
})

export class SpeakerInfoComponent implements OnInit {

  @Input('speaker') speaker;
  @Input('webPage') webPage;

  showModal = false;
  currentModal: string;

  constructor(private renderer: Renderer2) {
  }

  onSpeakerModalClick(speaker_id) {
    this.currentModal = speaker_id;
    this.showModal = !this.showModal;
    this.renderer.addClass(document.body, 'modal-open');
  }

  hideModal(e) {
    this.showModal = e;
  }

  ngOnInit() {
  }
}
