<style type="text/css">
  .modal-title {
    text-align: unset !important;
  }

  label {
    width: 100%;
    display: inline;
  }

  #submit_buttons {
    width: 100%;
  }

  #submit_buttons .btn {
    border-radius: 0;
  }

  #submit_buttons .btn-warn {
    margin: 8px;
    background-color: #bfbfbf !important;
    border-color: #bfbfbf;
  }

  #submit_buttons .btn-primary {
    background: #5f63b3;
    color: #fff !important;
  }

  .invalid_emails {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .agree_check, .agree_text {
    margin: 0;
  }

  .agree_text {
    max-width: 95%;
    font-size: 14px;
  }

  .error,
  .invalid-feedback {
    /* font-size: 16px; */
    color: #dc3545 !important;
  }

  .invalid-feedback {
    display: block !important;
  }

  @media only screen and (max-width: 450px) {
    .agree_text {
      max-width: 90%;
    }
  }
</style>
<!-- Modal -->
<div id="watchVideoModal" tabindex="-1" role="dialog" aria-labelledby="watchVideoModal" aria-hidden="true"  class="modal show d-block">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!registered">Registration Form</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click) = "close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 alert alert-danger" id="failed_register" *ngIf="register_failed"> {{errorMsg}} </div>
        <p *ngIf="!registered">
          *Indicates a required field
        </p>
        <form role="form" name="watchVideo" class="watchVideo" #f="ngForm" novalidate (ngSubmit)="registerBotox(f.form)" *ngIf="!registered">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="firstname">First Name *</label>
              <input type="text" class="form-control" name="firstname" [(ngModel)]="registerModel.firstname" pattern ="^[A-Za-z\-']+$" #firstname="ngModel"
                id="firstname" required [ngClass]="{ 'is-invalid': (firstname.dirty || firstname.touched) && firstname.invalid}">
              <div *ngIf="(firstname.dirty || firstname.touched) && firstname.invalid" class="invalid-feedback">
                <div *ngIf="firstname.errors.required">First Name is required</div>
                <div *ngIf="firstname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="lastname">Last Name *</label>
              <input type="text" class="form-control" id="lastname" name="lastname" [(ngModel)]="registerModel.lastname" pattern ="^[A-Za-z\-']+$" #lastname="ngModel"
               required [ngClass]="{ 'is-invalid': lastname.invalid && (lastname.dirty || lastname.touched)}">
              <div *ngIf="(lastname.dirty || lastname.touched) && lastname.invalid" class="invalid-feedback">
                <div *ngIf="lastname.errors.required">Last Name is required</div>
                <div *ngIf="lastname.errors?.pattern">{{'Invalid - !@#$%^&*()_+=`[]{}\|;:",.<>/?1234567890 special characters and numbers not allowed'}}</div>
              </div>
            </div>
            <div class="form-group col-sm-6">
                <label for="email">Email Address*</label>
                <input type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" name="email" id="email" [(ngModel)]="registerModel.email"
                       #email="ngModel"
                       [ngClass]="{ 'is-invalid': (email.dirty || email.touched) && (email.invalid ||  (email.valid && confirm_email.valid && registerModel.email != registerModel.confirm_email) || different_email) }"
                       required email/>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                  <div *ngIf="email.errors.required">Is missing and required</div>
                  <div *ngIf="email.errors.email || email.errors.pattern">Email must be a valid email address</div>
                  <div *ngIf="different_email">Confirm Email must match Email Address</div>
                </div>
                <div *ngIf="email.valid && confirm_email.valid && registerModel.email && registerModel.confirm_email && registerModel.email != registerModel.confirm_email" class="invalid_emails">
                  <div>Email addresses are not same</div>
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label for="email">Confirm Email Address*</label>
                <input type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" name="confirm_email" id="confirm_email"
                       [(ngModel)]="registerModel.confirm_email" #confirm_email="ngModel"
                       [ngClass]="{ 'is-invalid': (confirm_email.dirty || confirm_email.touched) && (confirm_email.invalid || (email.valid && confirm_email.valid && registerModel.email != registerModel.confirm_email)) }"
                       required email/>
                <div *ngIf="confirm_email.invalid && (confirm_email.dirty || confirm_email.touched)" class="invalid-feedback">
                  <div *ngIf="confirm_email.errors.required">Is missing and required</div>
                  <div *ngIf="confirm_email.errors.email || different_email">Confirm Email must match Email Address
                  </div>
                </div>
                <div *ngIf="email.valid && confirm_email.valid && registerModel.email && registerModel.confirm_email && registerModel.email != registerModel.confirm_email" class="invalid_emails">
                  <div>Email addresses are not same</div>
                </div>
              </div>
            
            <div class="col-12 privacy-policy mt-4 mb-4">
              <p class="agree_text p-text read_less" [ngClass]="{ 'd-none': readMore}"><label><b>Privacy Notice: </b></label>
                AbbVie may collect your personal data through your online and offline interactions with us, including your contact data. 
                We may also collect your online usage data... <a href="javascript: void(0);" (click)="showReadMore()" class="privacy_policy">Expand for more information</a></p>
                <p class="agree_text p-text" [ngClass]="{ 'd-none': !readMore}"><label><b>Privacy Notice: </b></label>AbbVie may collect your personal data through your online and offline interactions with us, including your contact data. 
                  We may also collect your online usage data automatically through cookies and similar technologies. We use this data for several purposes, 
                  such as to comply with our legal obligations, to perform a contract with you, to provide you with and improve our programs, 
                  services, and products, to customize your experiences, and for research and analytics. We 
                  retain your personal data for as long as necessary to fulfill these purposes or to comply with our 
                  record retention obligations.  We do not sell your personal data, but we may use and disclose it to marketing 
                  and advertising partners to deliver you ads based on your interests inferred from your activity across 
                  other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out 
                  of the use or disclosure of your personal data for online targeted advertising or for website 
                  analytics, go to your <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy
                    Choices</a> on our website. For more information on the data categories we collect, the 
                  purposes for their collection, our disclosures to third parties, your data subject rights, and 
                  our data retention criteria, visit our <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" 
                  target="_blank" class="privacy_policy">Privacy Notice</a>.
              </p>
              <p class="agree_text mt-3">
                Through my submission of the program enrollment form, I consent to the collection, use, and disclosure of my personal
                health data, as described in the Privacy Notice above and in AbbVie’s Privacy Notice in the
                <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html#h03 " target="_blank">“How We May Disclose
                Personal Data”</a> section. My consent is required to process sensitive personal data under certain privacy laws,
                and I have the right to withdraw my consent by visiting <a href="https://abbviemetadata.my.site.com/AbbvieDSRM"
                target="_blank">“Your Privacy Choices”</a> on AbbVie’s website.
              </p>
            </div>
            <div class="form-group text-center" id="submit_buttons" *ngIf="!registered">
              <button type="button" class="btn btn-warn" (click) = "close()">Cancel</button>
              <button type="submit" class="btn btn-success" [disabled]="!f.form.valid || (registerModel.email != registerModel.confirm_email)">
                <span *ngIf="!registering">Submit</span>
                <i class="fa fa-spinner fa-spin" *ngIf="registering"> </i>
              </button>
            </div>
          </div>
        </form>
        <video *ngIf="registered && videoSrc" controls controlsList="nodownload" width="100%">
          <source [src]="videoSrc" type="video/mp4">
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
  </div>
</div>
<!-- Close modal -->

