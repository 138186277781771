import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import * as $ from 'jquery';

import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';
import {Meeting} from '../interfaces/index';
import {AttendeeService} from '../services/index';
import {Meta, Title} from '@angular/platform-browser';
import {AnalyticsService} from '../services/analytics.service';
import moment from 'moment';

@Component({
  selector: 'app-confirmation',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-confirmation *ngSwitchCase="'vraylar'"></vraylar-confirmation>
      <migraine-confirmation *ngSwitchCase="'migraine'"></migraine-confirmation>
      <vuity-confirmation *ngSwitchCase="'vuity'"></vuity-confirmation>
      <botox-confirmation *ngSwitchCase="'botox'"></botox-confirmation>
    </ng-container>`
})
export class ConfirmationComponent implements OnInit {
  @Input('program') program;
  program_id = null;
  webPage = null;
  googleCalLink = null;
  attendee_status = null;
  attendee_id = null;

  constructor(
    protected _activeRouter: ActivatedRoute,
    protected _router: Router,
    protected _configService: ConfigService,
    protected _attendeeService: AttendeeService,
    private meta: Meta,
    protected titleService: Title,
    protected _analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
          if (data.client.has_meta_description) {
            this.meta.updateTag({name: 'description', content: data.client.confirmation_meta_description});
          }
        },
        (error) => this.webPage = null
      );

  }

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getConfig()
        .subscribe((data) => {
            this.webPage = data.client.webpage;
            this._activeRouter.params
              .subscribe(params => {
                  this.program_id = params['program_id'];
                  this.attendee_status = params['status'];

                  this._attendeeService.getCurrentProgram(this.program_id)
                    .subscribe((program: Meeting) => {
                      this.program = program;
                      this.program_id = this.program.program_id;
                      this.attendee_id = this._attendeeService.attendee_id;
                      observer.next(true);
                    }, error => {
                      this._router.navigate(['home']);
                    });
                },
                error => {
                  console.log(error);
                  observer.error(false);
                });
          },
          (error) => {
            this.webPage = null;
            observer.error(false);
          }
        );
    });
  }

  showCalendarOptions() {
    const eventCalendarElement = $('.event-calendar-options');
    if (eventCalendarElement.is(':visible')) {
      eventCalendarElement.slideUp();
      $('#botox_footer').css({'margin-top': '0px'});
    } else {
      eventCalendarElement.slideDown();
      $('#botox_footer').css({'margin-top': '125px'});
    }
  }

  getCalendar() {
    this._attendeeService.getCalendar(this.program_id)
      .subscribe((data) => {
        let a = document.createElement('a');
        a.setAttribute('id', 'dow_cal');
        document.body.appendChild(a);
        $('#dow_cal').hide();
        let blob = new Blob([data], {type: 'text/calendar'});
        const filename = 'Event-' + this.program['formatted_program_id'];

        if (window.navigator.userAgent.toLowerCase().indexOf('trident') > -1) {
          navigator.msSaveBlob(blob, filename + '.ics');
        } else {
          let url = window.URL.createObjectURL(blob);
          // window.location.href = url;
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
  }

  doPrint() {
    window.print();
  }

  goHome() {
    this._router.navigate(['home']);
  }
}

@Component({
  selector: 'botox-confirmation',
  templateUrl: '../ui/botox/confirmation.component.html'
})
export class BotoxConfirmationComponent extends ConfirmationComponent {
  ngOnInit() {
    this.initializeClient().subscribe((success) => {
      $('html,body').animate({scrollTop: 0}, 0);

      const startTime = moment(this.program.meeting_orig_datetime_utc).format('YYYYMMDD[T]HHmmss[Z]');
      const duration = moment(this.program.meeting_end_datetime).diff(moment(this.program.meeting_orig_datetime), 'seconds');
      const endTime = moment(this.program.meeting_orig_datetime_utc).add(duration, 'seconds').format('YYYYMMDD[T]HHmmss[Z]');
      const location = this.program['template_type'] == 'live' || this.program['template_type'] == 'hybrid' ? this.program.location.name + ', ' + this.program.location.address +
      ', ' + this.program.location.city + ', ' + this.program.location.state + ' ' + this.program.location.zipcode : '';

      this.googleCalLink = 'http://www.google.com/calendar/event?action=TEMPLATE&dates=' + startTime + '%2F' + endTime +
        '&text=' + this.program.formatted_program_id + ': Spasticity Webinar&location=' + location;
    });
  }
}

@Component({
  selector: 'vraylar-confirmation',
  templateUrl: '../ui/vraylar/confirmation.component.html'
})
export class VraylarConfirmationComponent extends ConfirmationComponent {

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
    });
  }
}

@Component({
  selector: 'migraine-confirmation',
  templateUrl: '../ui/migraine/confirmation.component.html'
})

export class MigraineConfirmationComponent extends ConfirmationComponent {

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
    });
  }
}

@Component({
  selector: 'vuity-confirmation',
  templateUrl: '../ui/vuity/confirmation.component.html'
})

export class VuityConfirmationComponent extends ConfirmationComponent {

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
      this.track();
    });
  }

  track() {
    const analyticObject = {
      'pageInfo': {
        'domain': window.location.hostname,
        'subDomain': '',
        'url': window.location.href,
        'path': window.location.pathname,
        'pageName': 'Register Event: Confirmation',
        'title': this.titleService.getTitle(),
        'prefix': 'vuitylive'
      },
      'category': {
        'primaryCategory': 'Register',
        'subCategory1': 'Confirmation',
        'subCategory2': this.program_id.toString()
      },
      'attributes': {
        'country': 'US',
        'language': 'en-US',
        'template': '',
        'promoMatsNumber': 'US-VUI-210146',
        'globalISI': '',
        'type': 'Register'
      }
    };

    this._analyticsService.updateStateObject(analyticObject);
  }
}
