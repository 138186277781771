<style>
  .qulipta_safety_info {
    width: 100%;
    background-color: #f0f5f8;
    padding: 10px 0
  }

  .qulipta_safety_info_content p {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
  }

  .qulipta_safety_info_content ul li:last-child p {
    margin-bottom: 0
  }

  .qulipta_safety_info_content h5 {
    color: #f06224;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px
  }

  .qulipta_safety_info_content h4 {
    color: #4c575c;
    font-weight: 600;
    text-transform: uppercase;
  }

  .qulipta_safety_info_content h5 a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }

  @media only screen and (max-width: 767px) {
    .qulipta_safety_info {
      padding: 20px 0;
    }

    .qulipta_safety_info_content h5 {
      font-size: 16px;
    }

    .qulipta_safety_info_content p {
      font-size: 14px;
      line-height: 20px;
    }
  }
</style>
<section class="qulipta_safety_info">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="qulipta_safety_info_content">
          <p>*Terms and Conditions apply. This benefit covers QULIPTA&trade; (atogepant). Eligibility: Available to
            patients with commercial prescription insurance coverage for QULIPTA who meet eligibility criteria. The
            program is not available to cash-paying patients or patients receiving prescription reimbursement under any
            federal, state, or government-funded insurance programs (for example,Medicare (including Part D],
            Medicare Advantage, Medigap, Medicaid, TRICARE, Department of Defense, or Veterans Affairs programs) or
            where prohibited by law or by the patient's health insurance provider. If at any time a patient begins
            receiving prescription drug coverage under any such federal, state, or government-funded healthcare program,
            patient will no longer be able to use the QULIPTA Complete Savings Card and patient must call 1.855. QULIPTA
            to stop participation. Under this program, an eligible patient whose insurer has imposed coverage
            restrictions that have not been satisfied may receive up to a maximum of two 30-day fills at no charge for
            an FDA-approved indication while coverage is pending. This payment may not be applied towards any
            out-of-pocket limits or other deductibles under patient's insurance coverage. Patients residing in or
            receiving treatment in certain states may not be eligible. Patients may not seek reimbursement for value
            received from QULIPTA Complete from any third-party payers. Offer subject to change or discontinuance
            without notice. Restrictions, including monthly maximums, may apply. This assistance offer is not health
            insurance. By redeeming this card, you acknowledge that you are an eligible patient and that you understand
            and agree to comply with the terms and conditions of this offer. To learn about AbbVie's privacy practices
            and your privacy choices, visit http://www.abbvie.com/privacy.html
          </p>
          <p>
            Eligible patients must have commercial insurance, a valid prescription for QULIPTA for an FDA-approved
            indication, a denial of insurance coverage based on a prior authorization request along with a confirmation
            of appeal. Continued eligibility for the program requires the submission of an appeal of the coverage denial
            every 180 days. Program provides QULIPTA at no charge to patients for up to two years or until they receive
            insurance coverage approval, whichever occurs earlier, and is not contingent on purchase requirements of any
            kind. Program is not available to patients whose medications are reimbursed in whole or in part by Medicare,
            Medicaid, TRICARE, or any other federal or state program. Offer subject to change or discontinuance without
            notice. This is not health insurance and program does not guarantee insurance coverage. No claims for
            payment may be submitted to any third party for product dispensed by program. Limitations may apply.</p>
          <div>
            <h5>Indication</h5>
            <p>
              QULIPTA (atogepant) is indicated for the preventive treatment of migraine in adults with <15 migraine days
              per month.
            </p>

            <h5>Important Safety Information</h5>
            <h4>ADVERSE REACTIONS</h4>
            <p>The most common adverse reactions (≥ 5% and at least twice the rate of placebo) are nausea and
              constipation.</p>

            <h4>DOSAGE AND ADMINISTRATION:</h4>
            <p>The recommended dose is 30mg or 60mg once daily. Concomitant strong CYP3A4 Inhibitors: Recommended dose
              is 10mg once daily. Concomitant CYP3A4 Inducers: Recommended dose is 60mg once daily. Concomitant OATP
              Inhibitors: Recommended dose is 10mg or 30mg once daily. Severe Renal Impairment: Recommended dose is 10mg
              or 30mg once daily. End-Stage Renal Disease: Recommended dose is 10mg once daily.</p>

            <h5>Please see full
              <a href="https://www.rxabbvie.com/pdf/qulipta.pdf" target="_blank">Prescribing Information</a>.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<qulipta-footer></qulipta-footer>
