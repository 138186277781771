import { Injectable } from '@angular/core';

@Injectable()

export class AddressService {

  getFormattedAddress(place) {
    let location_obj = {
      'address1': '',
      'city': '',
      'state': '',
      'street_number': '',
      'route': '',
      'country': '',
      'zip': ''
    };

    for (let i in place.address_components) {
      let item = place.address_components[i];

      location_obj['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf("route") > -1) {
        location_obj['address1'] = item['long_name']
      } else if (item['types'].indexOf("locality") > -1) {
        location_obj['city'] = item['long_name']
      } else if (item['types'].indexOf("administrative_area_level_3") > -1) {
        location_obj['city'] = item['long_name']
      } else if (item['types'].indexOf("neighborhood") > -1) {
        location_obj['city'] = item['long_name']
      } else if (item['types'].indexOf("sublocality_level_1") > -1) {
        location_obj['city'] = item['long_name']
      } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
        location_obj['state'] = item['short_name']
      } else if (item['types'].indexOf("street_number") > -1) {
        location_obj['street_number'] = item['long_name']
      } else if (item['types'].indexOf("route") > -1) {
        location_obj['route'] = item['long_name']
      } else if (item['types'].indexOf("country") > -1) {
        location_obj['country'] = item['short_name']
      } else if (item['types'].indexOf("postal_code") > -1) {
        location_obj['zip'] = item['long_name']
      }
    }

    if (location_obj['country'] !== 'US') {
      location_obj['state'] = '';
    }
    return location_obj;
  }
}