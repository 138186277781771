<style type="text/css">
  body {
    padding-bottom: 0;
  }

  header#vuity_header {
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  header#vuity_header .navbar-brand {
    color: #fff;
    flex: 1 1 0;
    padding: 10px 0;
  }

  header#vuity_header .navbar-brand span {
    display: block;
  }

  header#vuity_header .navbar-brand img {
    width: 180px;
    display: block;
    margin-left: 15px;
  }
</style>
<header id="vuity_header">
  <div class="container">
    <div class="row">
      <!-- Brand/logo -->
      <a class="navbar-brand" [routerLink]="[redirectRoute]">
        <span>
          <img src="assets/vuity/images/vuity-logo.png" alt="Vuity Logo">
        </span>
      </a>
    </div>
  </div>
</header>
