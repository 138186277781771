<style type="text/css">
  .self-center {
    margin: 0 auto;
  }

  .title {
    font-weight: bold;
    color: #4d4d4f;
    text-decoration: underline;
    text-align: center;
    font-size: 24px;
  }

  button {
    width: 80%;
    margin: 15px 0;
  }

  .logos {
    margin-bottom: 20px;
  }

  .box_header_container {
    margin-top: 0 !important;
  }

  .box_header_container .box_header h4 {
    line-height: 30px;
    height: 30px;
  }

  .box_header_container .box_header {
    background-color: #071D49;
    max-width: none;
    font-size: 32px;
  }

  .event_banner {
    background-color: #071D49;
    margin-bottom: 30px;
  }

  .event_banner h1 {
    color: #fff;
    font-size: 32px;
  }

  .text-secondary {
    color: #4d4d4f !important;
    font-size: 24px;
  }

  .box_container {
    background-color: #e6eaea;
    padding: 0;
    border: none;
  }

  .box_content {
    min-height: 385px;
    overflow: auto;
    padding: 20px;
    color: #000;
  }

  .register_button button {
    border-width: 0;
    border-radius: 4px;
    margin: 0;
    border-color: #071D49;
    background-color: #071D49;
    color: #fff;
    padding: 8px 20px;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
  }

  .single-program .box_content label,
  .single-program .topic {
    color: #000;
  }

  .single-program .topic {
    margin-bottom: 5px;
  }

  .single-program .topic-audience {
    font-size: small;
  }

  .single-program .speaker {
    margin-bottom: 5px;
  }

  .btn-register {
    background-color: #071D49;
  }

</style>
<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>YOU ARE REGISTERED!</h1>
      </div>
    </div>
  </div>
</section>
<section class="logos">
  <div class="row">
    <div class="col-6 text-right">
      <img src="assets/migraine/images/qulipta-logo.png" alt="Qulipta Logo">
    </div>
    <div class="col-6 ubrelvylogo">
      <img src="assets/migraine/images/ubrelvy-logo.png" alt="Ubrelvy Logo">
    </div>
  </div>
</section>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="program && (attendee_status == 1)">
    <div>
      <h2 class="title">Thank you for registering for a AbbVie Speaker Program Event<br></h2>
      <h5 class="text-center text-secondary"><span>If you have any questions, please call (888) 616-0377.</span></h5>
    </div>
    <div class="row">
      <div class=" col-12 col-sm-6 self-center">
        <div class="box_container single-program">
          <div class="box_header_container">
            <div class="box_header text-center">
              <h4> {{program.event_type_sub_code == "BRDPGM" ? "Broadcast" :
                (!program.meeting_type_live ? "Virtual" : program.event_heading)}} </h4>
            </div>
          </div>
          <div class="box_content">
            <div class="text-center">
              <div class="topic">{{program.topic}}</div>
              <div class="topic-audience" *ngIf="program.topic_audience">This program is designed for
                a {{program.topic_audience}} audience
              </div>
            </div>
            <div>
              <label>DATE AND TIME</label>
              <div>{{program.meeting_date}}</div>
              <div>{{program.meeting_time}} {{program.timezone}}</div>
            </div>
            <div>
              <label>LOCATION</label>
              <div *ngIf="program.meeting_type_live; else notShowLocation">
                <div> {{program.location.name}}</div>
                <div>{{program.location.address}}</div>
                <div>
                  <span *ngIf="program.location.city">{{program.location.city}}, </span>{{program.location.state}}
                  {{program.location.zipcode}}
                </div>
              </div>
              <ng-template #notShowLocation>
                <div>
                  Virtual Program
                </div>
              </ng-template>
            </div>
            <div>
              <label>SPEAKER</label>
              <ng-container *ngFor="let speaker of program.speakers;">
                <div class="speaker">
                  <div>{{speaker.name}}, {{speaker.degree}}</div>
                  <div>{{speaker.institution}}</div>
                </div>
              </ng-container>
            </div>
            <div>
              <label>SALES REP</label>
              <div *ngIf="program.sf_members.email">
                <div>{{program.sf_members.lastname}}
                  , {{program.sf_members.firstname}} {{program.sf_members.midname}} </div>
                <div>{{program.sf_members.email}}</div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 self-center text-center">
        <a [routerLink]="['/home']">
          <button type="button" class="btn btn-md btn-register">
            REGISTER FOR ANOTHER PROGRAM
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
