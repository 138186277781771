<style type="text/css">
  #ubrelvy-footer {
    font-weight: 600;
    background: #363c3e;
    color: #fff;
  }

  #ubrelvy-footer a {
    color: #fff;
  }

  #ubrelvy-footer .abbvie-logo {
    width: 200px;
    margin-bottom: 10px;
  }

  #ubrelvy-footer a:hover {
    color: #72cdd9
  }

  #ubrelvy-footer .footer-content {
    padding: 45px 0
  }

  #ubrelvy-footer .footer-content a {
    display: block;
    text-align: center;
  }

  #ubrelvy-footer .footer-content ul {
    text-align: center;
    margin-bottom: 25px;
    padding: 0;
  }

  #ubrelvy-footer .footer-content ul li {
    display: inline-block;
    padding: 0 20px;
  }

  #ubrelvy-footer .footer-content ul li a {
    font-size: 16px;
    font-weight: 600
  }

  #ubrelvy-footer .footer-content p {
    text-align: center;
    font-size: 14px;
  }

  #ubrelvy-footer .footer-content p:last-child {
    margin-bottom: 0
  }

  @media only screen and (max-width: 767px) {
    #ubrelvy-footer .footer-content {
      padding: 30px 0;
    }

    #ubrelvy-footer .footer-content .abbvie-logo {
      width: 150px;
      margin-bottom: 15px;
    }

    #ubrelvy-footer .footer-content ul {
      margin-bottom: 0;
    }

    #ubrelvy-footer .footer-content ul li {
      margin-bottom: 10px;
    }

    #ubrelvy-footer .footer-content p {
      margin-bottom: 10px
    }
  }
</style>
<footer id="ubrelvy-footer">
  <div class="footer-content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <a href="https://www.abbvie.com" target="_blank">
            <img class="abbvie-logo" src="assets/ubrelvy/images/abbvie-white.png"
                 alt="Abbvie Logo"/>
          </a>
          <ul>
            <li><a href="https://privacy.abbvie/" target="_blank">Privacy Policy</a></li>
          </ul>
          <div>
            <p>UBRELVY&reg; and its design are registered trademarks of Allergan Pharmaceuticals International Limited,
              an AbbVie company.</p>
            <p>&copy; 2021 AbbVie. All rights reserved.</p>
            <p>MBD149730 09/21</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
