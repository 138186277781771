<style type="text/css">
.program_list {
  margin-top: 25px;
  margin-bottom: 10px;
}

.load_more_row {
  justify-content: center;
}

#load_more_button button {
  border-width: 0;
  border-color: #761F76;
  background-color: #761F76;
  color: #fff;
  padding: 8px 20px;
  font-size: 14px;
  width: 200px;
}
</style>
<section class="program_list row justify-content-between">
  <div class="loading container text-center" *ngIf="loading"> <i class="fa fa-spinner fa-spin"> </i> </div>
  <div id="program_list" *ngIf="!loading" class="col-12">
    <div class="row">
      <div class="col-12" *ngIf="distanceErrorMessage">
          <h3 style="color:red" class="text-center"> {{distanceErrorMessage}}  </h3>
      </div>

      <div *ngFor="let program of livePrograms; trackBy: trackByProgramId" class="col-12 col-sm-6">
        <vraylar-single-program [program]="program" [canregister]="true" (register)="register($event)" [webPage]="webPage" *ngIf="program"> </vraylar-single-program>
      </div>

      <div *ngFor="let program of virtualPrograms; trackBy: trackByProgramId" class="col-12 col-sm-6">
        <vraylar-single-program [program]="program" [canregister]="true" (register)="register($event)" [webPage]="webPage" *ngIf="program"> </vraylar-single-program>
      </div>

    </div>
    <div class="row load_more_row text-center" *ngIf="!loading">
      <div id="load_more_button" class="col-12" (click)="loadMorePrograms()"
           *ngIf="load_more_details.show_load_more && (livePrograms.length > 0 || virtualPrograms.length > 0)">
        <button class="btn btn-success">LOAD MORE</button>
      </div>
    </div>
  </div>
</section>
<app-future-contact *ngIf="programList && !loading" (submitProbabletarget)="submitProbabletarget($event)"></app-future-contact>
