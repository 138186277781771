<div id="{{webPage}}_container">
  <div class="navbar navbar-dark" id="top_bar"></div>
  <div>
    <app-header *ngIf="!isResourcesPage"></app-header>
    <div id="main_container">
      <app-important-news *ngIf="hasImportantNews"></app-important-news>
      <app-info-modal *ngIf="hasInfoModal"></app-info-modal>
      <router-outlet></router-outlet>
      <app-safety-info *ngIf="!isResourcesPage"></app-safety-info>
    </div>
    <app-cookie-consent *ngIf="hasCookieConsentForm && !isResourcesPage"></app-cookie-consent>
  </div>
</div>
