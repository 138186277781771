import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

import {ConfigService} from '../config/config.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-safety-info',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-safety-info *ngSwitchCase="'vraylar'"></vraylar-safety-info>
      <migraine-safety-info *ngSwitchCase="'migraine'"></migraine-safety-info>
      <vuity-safety-info *ngSwitchCase="'vuity'"></vuity-safety-info>
      <botox-safety-info *ngSwitchCase="'botox'"></botox-safety-info>
    </ng-container>`
})
export class SafetyInfoComponent implements OnInit {
  webPage = null;
  safety_detail = false;
  current_page;

  original_container_height = 0;
  original_safety_height = 0;
  safety_info_height = 170;
  logo_height = 180;
  nav_bar_height = 40;

  constructor(
    protected _configService: ConfigService,
    private router: Router) {
  }

  ngOnInit() {
    let vm = this;
    this._configService.getWebPage()
      .subscribe((data) => {
          this.webPage = data;
        },
        (error) => this.webPage = null
      );
    this.current_page = this.router.url;
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.current_page = event.url;
        }
      }
    );
  }

  alterSafetyDetail(flag) {
    window.removeEventListener('scroll', this.scroll, true);
    this.setOriginalHeights();
    this.safety_detail = flag;
    if (this.safety_detail) {
      let windowHeight = $(window).height();
      $('html,body').animate({scrollTop: this.original_container_height + this.logo_height - 10 + this.nav_bar_height}, 0);

      $('#safety_information').height('auto');
      $('#main_container').height('auto');

      $('#safety_information').addClass('scrolledTop');
      $('#safety_information').removeClass('scrolledBottom');

    } else {
      $('html,body').animate({scrollTop: 0}, 0);

      $('#safety_information').height(this.safety_info_height);
      $('#main_container').height(this.original_container_height + this.original_safety_height);

      $('#safety_information').addClass('scrolledBottom');
      $('#safety_information').removeClass('scrolledTop');
    }

    setTimeout(() => {
      window.addEventListener('scroll', this.scroll, true);
    }, 1000);
  }

  scroll(e) {
    if (!this.original_container_height) {
      let currentHeight = $('#main_container').height();
      $('#main_container').height('auto');
      this.original_container_height = $('#main_container').height();
      $('#main_container').height(currentHeight);
    }
    if (!this.original_safety_height) {
      let currentHeight = $('#safety_information').height();
      $('#safety_information').height('auto');
      this.original_safety_height = $('#safety_information').height();
      $('#safety_information').height(currentHeight);
    }

    if ($(document).scrollTop() < this.original_container_height - 10 - this.nav_bar_height - this.logo_height) {
      this.safety_detail = false;
      $('#safety_information').addClass('scrolledBottom');
      $('#safety_information').removeClass('scrolledTop');

      $('#main_container').height(this.original_container_height + this.original_safety_height);
      $('#safety_information').height(this.safety_info_height);

    } else if ($(document).scrollTop() > this.original_container_height + 10 - this.nav_bar_height - this.logo_height) {

      this.safety_detail = true;

      $('#safety_information').height('auto');
      $('#main_container').height('auto');

      $('#safety_information').removeClass('scrolledBottom');
      $('#safety_information').addClass('scrolledTop');

    }
  }

  setOriginalHeights() {
    if (!this.original_container_height) {
      let currentHeight = $('#main_container').height();
      $('#main_container').height('auto');
      this.original_container_height = $('#main_container').height();
      $('#main_container').height(currentHeight);
    }
    if (!this.original_safety_height) {
      let currentHeight = $('#safety_information').height();
      $('#safety_information').height('auto');
      this.original_safety_height = $('#safety_information').height();
      $('#safety_information').height(currentHeight);
    }
  }
}

@Component({
  selector: 'botox-safety-info',
  templateUrl: '../ui/botox/safety-info.component.html'
})

export class BotoxSafetyInfoComponent extends SafetyInfoComponent {
  ngOnInit() {
    let vm = this;
    // set page height
    this.safety_info_height = 280;
    let original_container_height = $('#main_container').outerHeight();
    if (original_container_height) {
      this.original_container_height = original_container_height;
    }
    this.original_safety_height = $('#safety_information').outerHeight();

    $('#main_container').css({
      'height': this.original_container_height + this.original_safety_height,
      'overflow': 'hidden'
    });
    $('#safety_information').height(this.safety_info_height);

    window.addEventListener('scroll', function (e) {
      vm.scroll(e);
    });

  }
}

@Component({
  selector: 'vraylar-safety-info',
  templateUrl: '../ui/vraylar/safety-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VraylarSafetyInfoComponent extends SafetyInfoComponent {
	ngOnInit() {
		let vm = this;
    this.safety_info_height = 180;
    this.logo_height = 0;
    this.nav_bar_height = 0;
    let original_height = $('#main_container').outerHeight();
    this.original_safety_height = $('#safety_information').outerHeight();
    $('#main_container').css({
      'height': original_height + this.original_safety_height,
      'overflow': 'hidden'
    });
    $('#safety_information').height(this.safety_info_height);

    window.addEventListener('scroll', function (e) {
      vm.scroll(e);
    });
  }

  scroll(e) {
    if (!this.original_container_height) {
      let currentHeight = $('#main_container').height();
      $('#main_container').height('auto');
      this.original_container_height = $('#main_container').height();
      $('#main_container').height(currentHeight);
    }
    if (!this.original_safety_height) {
      let currentHeight = $('#safety_information').height();
      $('#safety_information').height('auto');
      this.original_safety_height = $('#safety_information').height();
      $('#safety_information').height(currentHeight);
    }
    let footerHeight = $("#vraylar_footer").height();
    let indicationUsageHeight = $('.indications-usage').height();

    let scrollBottomCollapsedCalc = (this.original_container_height - this.safety_info_height - footerHeight - indicationUsageHeight);
    let scrolltopCollapsedCalc = (this.safety_info_height + footerHeight);

    if (($(document).scrollTop() < scrollBottomCollapsedCalc)) {
      this.safety_detail = false;
      $('#safety_information').addClass('scrolledBottom');
      $('#safety_information').removeClass('scrolledTop');

      $('#main_container').height(this.original_container_height + this.original_safety_height);
      $('#safety_information').height(this.safety_info_height);

    } else if($(document).scrollTop() > scrolltopCollapsedCalc) {
      this.safety_detail = true;

      $('#safety_information').height('auto');
      $('#main_container').height('auto');

      $('#safety_information').removeClass('scrolledBottom');
      $('#safety_information').addClass('scrolledTop');

    }
  }
}

@Component({
  selector: 'migraine-safety-info',
  templateUrl: '../ui/migraine/safety-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MigraineSafetyInfoComponent extends SafetyInfoComponent {
  active = false;
}


@Component({
  selector: 'vuity-safety-info',
  templateUrl: '../ui/vuity/safety-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VuitySafetyInfoComponent extends SafetyInfoComponent {
}

@Component({
  selector: 'ubrelvy-safety-info',
  templateUrl: '../ui/ubrelvy/safety-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UbrelvySafetyInfoComponent extends SafetyInfoComponent {
  active = false;
}

@Component({
  selector: 'qulipta-safety-info',
  templateUrl: '../ui/qulipta/safety-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class QuliptaSafetyInfoComponent extends SafetyInfoComponent {
  active = false;
}
