import {Component, OnInit, Renderer2} from '@angular/core';
import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';

@Component({
  selector: 'app-info-modal',
  template: `
    <ng-container [ngSwitch]="webPage">
      <migraine-info-modal *ngSwitchCase="'migraine'"></migraine-info-modal>
      <vraylar-info-modal *ngSwitchCase="'vraylar'"></vraylar-info-modal>
    </ng-container>`
})
export class InfoModalComponent implements OnInit {
  webPage = null;

  constructor(
    protected renderer: Renderer2,
    protected _configService: ConfigService) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'migraine-info-modal',
  templateUrl: '../ui/migraine/info-modal.component.html',
})
export class MigraineInfoModalComponent extends InfoModalComponent {
  showModal = true;
  showReadMoreButton = true;
  showReadLessButton = false;

  onCloseBtnClick() {
    this.showModal = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  manageContent() {
    this.showReadLessButton = this.showReadMoreButton;
    this.showReadMoreButton = !this.showReadMoreButton;
  }
}

@Component({
  selector: 'vraylar-info-modal',
  templateUrl: '../ui/vraylar/info-modal.component.html',
})
export class VraylarInfoModalComponent extends InfoModalComponent {
  showModal = true;
  showReadMoreButton = true;
  showReadLessButton = false;

  onCloseBtnClick() {
    this.showModal = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  manageContent() {
    this.showReadLessButton = this.showReadMoreButton;
    this.showReadMoreButton = !this.showReadMoreButton;
  }
}
