<style type="text/css">
  #qulipta-footer {
    width: 100%;
    background: #16758f;
    background-image: -webkit-linear-gradient(85deg, #374550 35%, #29596a 50%, #16758f);
    background-image: -moz-linear-gradient(85deg, #374550 35%, #29596a 50%, #16758f);
    background-image: -ms-linear-gradient(85deg, #374550 35%, #29596a 50%, #16758f);
    background-image: -o-linear-gradient(85deg, #374550 35%, #29596a 50%, #16758f);
    background-image: linear-gradient(85deg, #374550 35%, #29596a 50%, #16758f);
    font-weight: 600;
    color: #fff;
  }

  #qulipta-footer a {
    color: #fff;
  }

  #qulipta-footer .abbvie-logo {
    width: 200px;
    margin-bottom: 10px;
  }

  #qulipta-footer a:hover {
    color: #72cdd9
  }

  #qulipta-footer .footer-content {
    padding: 45px 0
  }

  #qulipta-footer .footer-content a {
    display: block;
    text-align: center;
  }

  #qulipta-footer .footer-content ul {
    text-align: center;
    margin-bottom: 25px;
    padding: 0;
  }

  #qulipta-footer .footer-content ul li {
    display: inline-block;
    padding: 0 20px;
  }

  #qulipta-footer .footer-content ul li a {
    font-size: 16px;
    font-weight: 600
  }

  #qulipta-footer .footer-content p {
    text-align: center;
    font-size: 14px;
  }

  #qulipta-footer .footer-content p:last-child {
    margin-bottom: 0
  }

  @media only screen and (max-width: 767px) {
    #qulipta-footer .footer-content {
      padding: 30px 0;
    }

    #qulipta-footer .footer-content .abbvie-logo {
      width: 150px;
      margin-bottom: 15px;
    }

    #qulipta-footer .footer-content ul {
      margin-bottom: 0;
    }

    #qulipta-footer .footer-content ul li {
      margin-bottom: 10px;
    }

    #qulipta-footer .footer-content p {
      margin-bottom: 10px
    }
  }
</style>
<footer id="qulipta-footer">
  <div class="footer-content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <a href="https://www.abbvie.com" target="_blank">
            <img class="abbvie-logo" src="assets/qulipta/images/abbvie-white.png"
                 alt="Abbvie Logo"/>
          </a>
          <ul>
            <li><a href="https://privacy.abbvie/" target="_blank">Privacy Policy</a></li>
          </ul>
          <div>
            <p>QULIPTA&trade; and its design are trademarks of Allergan Pharmaceuticals International Limited, an AbbVie
              company.</p>
            <p>&copy; 2021 AbbVie. All rights reserved.</p>
            <p>ATP145671 10/21</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
