<style type="text/css">
  .safety_information {
    width: 100%;
    height: 240px;
    background: #fff;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-top: 1px solid #fff;
    padding-top: 25px;
  }

  .safety_information.active {
    height: 100%;
  }

  .safety_info_button {
    position: relative;
  }

  .safety_info_button a::before {
    content: '';
    width: 2px;
    height: 45px;
    background: #889492;
    color: #000;
    position: absolute;
    left: -25px;
    top: -10px;
  }

  .safety_info_button img {
    width: 22px;
    height: 22px;
  }

  .safety_info_button img[alt='minus'] {
    display: none;
  }

  .safety_information.active .safety_info_button img[alt='minus'] {
    display: block;
  }

  .safety_information.active .safety_info_button img[alt='plus'] {
    display: none;
  }

  .safety_info_aside {
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .safety_information strong {
    font-weight: 700;
  }

  .safety_info_top a {
    display: block;
    margin-right: 5px;
    text-decoration: underline;
    color: #000;
    font-size: 14px;
  }

  .safety_info_aside h3 {
    margin: 0 0 15px 0;
  }

  #ubrelvy h3, .blue-heading {
    color: #76c4d6;
  }

  #qulipta h3, .orange-heading {
    color: #ef5e31;
  }

  #ubrelvy .underline {
    border-bottom: 4px solid #76c4d6;
  }

  #qulipta .underline {
    border-bottom: 4px solid #ef5e31;
  }

  .safety_info_aside hr {
    border-color: white;
  }

  .safety_information ul {
    margin-bottom: 0;
    margin-top: 10px;
    list-style: disc;
    padding-left: 15px;
  }

  .safety_info_bottom_text ul li {
    position: relative;
    padding-left: 15px;
  }

  .safety_info_bottom_text ul li:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 7px;
  }

  .safety_information ul + p {
    margin-top: 10px;
  }

  .safety_info_bottom p, .safety_info_bottom li {
    font-weight: 300;
    font-size: 14px;
    margin: 0;
  }

  .safety_info_bottom p {
    margin-bottom: 10px;
  }

  /*.align_text_bottom {
    position: absolute;
  }*/

  .safety_info_bottom a {
    color: #76c4d6;
    text-decoration: underline;
  }

  .safety_info_button a {
    text-decoration: none;
    display: block;
  }

  @media only screen and (max-width: 560px) {
    .safety_information ul {
      flex-wrap: wrap;
    }

    .safety_information.active {
      overflow: auto;
    }

    .safety_information ul li {
      width: 100%;
    }

    .safety_info_button a::before {
      left: -20px;
    }

    .safety_info_aside .border-right {
      border: none !important;
    }

    .align_text_bottom {
      position: static;
    }
  }
</style>
<section class="safety_information" [class.active]="active" id="safety_information">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="safety_info_bottom d-flex">
          <aside class="safety_info_aside">
            <div class="row">
              <div class="col-md-6 border-right" id="qulipta">
                <h3><strong>QULIPTA&reg; (atogepant)</strong></h3>
                <p class="orange-heading underline"><strong>INDICATION</strong></p>
                <p>QULIPTA&reg; (atogepant) is indicated for the preventive treatment of migraine in adults.</p>
                <p class="orange-heading"><strong>IMPORTANT SAFETY INFORMATION</strong></p>
                <p class="orange-heading"><strong>CONTRAINDICATIONS</strong></p>
                <p>QULIPTA is contraindicated in patients with a history of hypersensitivity
                  to atogepant or any of the components of QULIPTA.</p>
                <p><strong class="orange-heading">WARNINGS AND PRECAUTIONS</strong></p>
                <p>Cases, including anaphylaxis, dyspnea, rash, pruritus,
                urticaria, and facial edema, have been reported with
                use of QULIPTA. Hypersensitivity reactions can occur
                days after administration. If a hypersensitivity reaction
                occurs, discontinue QULIPTA and institute appropriate
                therapy.</p>
                <p class="orange-heading"><strong>ADVERSE REACTIONS</strong></p>
                <p>
                  The most common adverse reactions (at least 4% and greater than placebo)
                  are nausea, constipation, and fatigue/somnolence.</p>
                <p><strong class="orange-heading">Dosage form and strengths:</strong>
                  QULIPTA is available in 10 mg, 30 mg, and 60 mg tablets.</p>
                <p>
                  <strong>Please see accompanying full Prescribing Information or visit
                    <a class="blue-heading" href="https://www.rxabbvie.com/pdf/qulipta_pi.pdf" target="_blank">
                      https://www.rxabbvie.com/pdf/qulipta_pi.pdf</a>
                  </strong>
                </p>
                <p class="align_text_bottom">
                  <i>
                    The Phase 3 studies for UBRELVY and QULIPTA that supported product approval had no patients on concomitant medication that acted on the CGRP pathway.
                  </i>
                </p>
              </div>
              <div class="col-md-6" id="ubrelvy">
                <h3><strong>UBRELVY&reg; (ubrogepant)</strong></h3>
                <p class="blue-heading underline"><strong>INDICATION</strong></p>
                <p>UBRELVY&reg; (ubrogepant) is indicated for the acute treatment of migraine with or without aura in adults. UBRELVY is not indicated
                  for the preventive treatment of migraine.</p>
                <p class="blue-heading"><strong>IMPORTANT SAFETY INFORMATION</strong></p>
                <P class="blue-heading"><strong>CONTRAINDICATIONS</strong></P>
                <p>
                  <strong class="blue-heading">Drug Interactions:</strong>
                  UBRELVY is contraindicated with concomitant use of strong CYP3A4 inhibitors (eg, ketoconazole, itraconazole, clarithromycin).
                </p>
                <p>
                  <strong class="blue-heading">Hypersensitivity Reactions:</strong>
                  UBRELVY is contraindicated in patients with a history of serious hypersensitivity to ubrogepant or any ingredient of the product.
                  Cases, including anaphylaxis, dyspnea, facial or throat edema, rash, urticaria, and pruritus, have been reported. Hypersensitivity
                  reactions can occur minutes, hours, or days after administration. Most reactions were not serious, and some led to discontinuation.
                  If a serious or severe reaction occurs, discontinue UBRELVY and institute appropriate therapy.
                </p>
                <p><strong class="blue-heading">ADVERSE REACTIONS</strong></p>
                <p>
                  The most common adverse reactions were nausea (4% vs 2% placebo) and somnolence (3% vs 1% placebo).
                </p>
                <p>
                  <strong>Please see accompanying full Prescribing Information or visit
                    <a class="blue-heading" href="https://www.rxabbvie.com/pdf/ubrelvy_pi.pdf" target="_blank">
                      https://www.rxabbvie.com/pdf/ubrelvy_pi.pdf</a>
                  </strong>
                </p>
              </div>
            </div>
          </aside>
          <aside>
            <div class="safety_info_button">
              <a href="javascript:void(0)" (click)="active = !active">
                <img src="assets/migraine/images/icons/plus.svg" alt="plus">
                <img src="assets/migraine/images/icons/minus.svg" alt="minus">
              </a>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
