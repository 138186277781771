<style type="text/css">
  .safety_information {
    position: fixed;
    bottom: 0px;
    background: #fff;
    z-index: 100;
    padding: 0;
  }

  .safety_information .container{
    font-size: 16px;
  }

  .container {
    padding-right: 30px;
  }

  .safety_header {
    background-color: #761F76;
    color: white;
  }

  .safety_detail .safety_header {
    background-color: white;
    color: #761F76;
  }

  .safety_detail .safety_header h4 {
    color: #761F76;
  }

  .safety_header h4 {
    color: white;
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
  }

  .safety_header span {
    font-weight: 600;
    transition: color .2s ease;
    cursor: pointer;
    font-size: 14px;
  }

  .scrolledTop {
    position: relative !important;
    height: auto;
  }

  .scrolledTop h4 {
    color: white;
  }

  .scrolledBottom .safety_header, .scrolledTop .safety_header {
    background-color: #761F76;
    color: white;
  }

  .scrolledBottom {
    position: fixed !important;
    height: auto;
  }

  .scrolledBottom h4 {
    color: white;
  }

  a {
    color: #761F76;
    font-weight: bold;
    text-decoration: underline;
  }

  ul {
    margin: 10px 0;
    padding-left: 15px !important;
  }

  #safety_information .privacy-links a:nth-last-child(n+2) {
    margin-right: 15px;
  }

  .purple-text {
    color: #761F76 !important;
  }

  ul .dashed { list-style-type: '- '; }
</style>
<section class="container indications-usage">
  <div class="row">
    <div class="col-12 text-center">
      <p>
        <a class="purple-text" href="http://www.allerganaccess.com/" target="_blank">
          Click here to get more information or samples
        </a>
      </p>
    </div>
    <div class="col-12 text-left">
      <p><b class="purple-text">INDICATIONS AND USAGE</b></p>
      <p class="content">
        VRAYLAR (cariprazine) is indicated in adults as adjunctive therapy to antidepressants for the treatment of major
        depressive disorder (MDD), for the treatment of depressive episodes associated with bipolar I disorder (bipolar
        depression), for the acute treatment of manic or mixed episodes associated with bipolar I disorder, and for the
        treatment of schizophrenia.
      </p>
    </div>
  </div>
</section>
<section class="safety_information container-fluid" id="safety_information">
  <div class="safety_header" id="safety_header_information">
    <div class="d-flex flex-row container">
      <h4 class="m-1 flex-grow-1">IMPORTANT SAFETY INFORMATION</h4>
      <div class="p-2 d-flex flex-row-reverse justify-content-end">
        <ng-template [ngIf]="safety_detail">
          <span (click)="alterSafetyDetail(false)">Collapse <i
          class="fa fa-chevron-down"></i></span>
        </ng-template>
        <ng-template [ngIf]="!safety_detail">
          <span (click)="alterSafetyDetail(true)">Expand <i class="fa fa-chevron-up"></i></span>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="purple-text">
          <b>WARNINGS: INCREASED MORTALITY IN ELDERLY PATIENTS WITH DEMENTIA-RELATED PSYCHOSIS and SUICIDAL THOUGHTS
            AND BEHAVIORS</b>
        </p>
        <ul>
          <li>
            <b>Elderly patients with dementia-related psychosis treated with antipsychotic drugs are at an increased
              risk
              of death. VRAYLAR is not approved for
              treatment of patients with dementia-related psychosis.</b>
          </li>
          <li>
            <b>Antidepressants increased the risk of suicidal thoughts and behaviors in pediatric and young adult
              patients in short-term studies. Closely monitor
              antidepressant-treated patients for clinical worsening, and for emergence of suicidal thoughts and
              behaviors. Safety and effectiveness of VRAYLAR
              have not been established in pediatric patients.</b>
          </li>
        </ul>
        <p><b class="purple-text">Contraindication:</b> VRAYLAR is contraindicated in patients with known
          hypersensitivity. Reactions have included rash, pruritus, urticaria, and reactions suggestive of
          angioedema.</p>
        <p><b class="purple-text">Cerebrovascular Adverse Reactions, Including Stroke:</b> In clinical trials with
          antipsychotic drugs, elderly patients with dementia had a higher incidence of
          cerebrovascular adverse reactions, including fatalities, vs placebo. VRAYLAR is not approved for the
          treatment of patients with dementia-related psychosis.</p>
        <p><b class="purple-text">Neuroleptic Malignant Syndrome (NMS):</b> NMS, a potentially fatal symptom complex,
          has been reported with antipsychotic drugs. NMS may cause hyperpyrexia,
          muscle rigidity, delirium, and autonomic instability. Additional signs may include elevated creatine
          phosphokinase, myoglobinuria (rhabdomyolysis), and acute renal
          failure. Manage with immediate discontinuation, intensive symptomatic treatment, and monitoring.</p>
        <p><b class="purple-text">Tardive Dyskinesia (TD):</b> Risk of developing TD (a syndrome of potentially
          irreversible, involuntary, dyskinetic movements) and the likelihood it will become
          irreversible may increase with the duration of treatment and the cumulative dose. The syndrome can develop
          after a relatively brief treatment period, even at low
          doses, or after treatment discontinuation. If signs and symptoms of TD appear, drug discontinuation should
          be considered.</p>
        <p><b class="purple-text">Late-Occurring Adverse Reactions:</b> Adverse reactions may first appear several weeks
          after initiation of VRAYLAR, probably because plasma levels of cariprazine and its
          major metabolites accumulate over time. As a result, the incidence of adverse reactions in short-term trials
          may not reflect the rates after longer-term exposures.
          Monitor for adverse reactions, including extrapyramidal symptoms (EPS) or akathisia, and patient response
          for several weeks after starting VRAYLAR and after each
          dosage increase. Consider reducing the dose or discontinuing the drug.</p>
        <p><b class="purple-text">Metabolic Changes:</b> Atypical antipsychotics, including VRAYLAR, have caused metabolic changes, such
          as:</p>
        <ul>
          <li><b>Hyperglycemia and Diabetes Mellitus:</b> Hyperglycemia, in some cases associated
            with ketoacidosis, hyperosmolar coma, or death, has been reported in patients
            treated with atypical antipsychotics. Assess fasting glucose before or soon after initiation of treatment,
            and monitor periodically during long-term treatment.
          </li>

          <li><b>Dyslipidemia:</b> Atypical antipsychotics cause adverse alterations in lipids.
            Before or soon after starting an antipsychotic, obtain baseline fasting lipid profile and
            monitor periodically during treatment.
          </li>

          <li><b>Weight Gain:</b> Weight gain has been observed with VRAYLAR. Monitor weight at
            baseline and frequently thereafter.
          </li>
        </ul>
        <p><b class="purple-text">Leukopenia, Neutropenia, and Agranulocytosis:</b> Leukopenia/neutropenia have been
          reported with antipsychotics, including VRAYLAR. Agranulocytosis (including fatal cases) has been reported with other
          antipsychotics. Monitor complete blood count in patients with pre-existing low white blood cell count (WBC)/absolute 
          neutrophil count or history of drug-induced leukopenia/neutropenia. Discontinue VRAYLAR at the first sign of a clinically 
          significant decline in WBC and in severely neutropenic patients.</p>
        <p><b class="purple-text">Orthostatic Hypotension and Syncope:</b> Atypical antipsychotics cause orthostatic
          hypotension and syncope, with the greatest risk during initial titration and with
          dose increases. Monitor orthostatic vital signs in patients predisposed to hypotension and in those with
          cardiovascular/cerebrovascular diseases.</p>
        <p><b class="purple-text">Falls:</b> VRAYLAR may cause somnolence, postural hypotension, motor and sensory
          instability, which may lead to falls and, consequently, fractures or other injuries. For
          patients with diseases, conditions, or medications that could exacerbate these effects, complete fall risk
          assessments when initiating antipsychotics and recurrently for
          patients on long-term therapy.</p>
        <p><b class="purple-text">Seizures:</b> Use VRAYLAR with caution in patients with a history of seizures or with
          conditions that lower the seizure threshold.</p>
        <p><b class="purple-text">Potential for Cognitive and Motor Impairment:</b> Somnolence was reported with
          VRAYLAR. Caution patients about performing activities requiring mental alertness
          (eg, operating hazardous machinery or a motor vehicle).</p>
        <p><b class="purple-text">Body Temperature Dysregulation:</b> Use VRAYLAR with caution in patients who may
          experience conditions that increase body temperature (eg, strenuous exercise,
          extreme heat, dehydration, or concomitant anticholinergics)</p>
        <p><b class="purple-text">Dysphagia:</b> Esophageal dysmotility and aspiration have been associated with
          antipsychotics. Antipsychotic drugs, including VRAYLAR, should be used cautiously in
          patients at risk for aspiration.</p>
        <p><b class="purple-text">Drug Interactions:</b> Strong CYP3A4 inhibitors increase VRAYLAR concentrations, so
          VRAYLAR dose reduction is recommended. Concomitant use with CYP3A4 inducers
          is not recommended.</p>
        <p><b class="purple-text">Adverse Reactions:</b> The most common adverse reactions in clinical trials (≥5% and
          at least twice the rate of placebo) are listed below: </p>
        <ul>
          <li><b>Adjunctive Treatment of Major Depressive Disorder:</b><br>
            <ul class="dashed">
              <li>In 6-week, fixed-dose trials the incidences within the recommended doses (VRAYLAR 1.5 mg/day +
              antidepressant therapy [ADT] or 3 mg/day + ADT vs placebo + ADT) were akathisia (7%, 10% vs 2%), nausea (7%,
              6% vs 3%), and insomina (9%, 10% vs 5%).</li>
              <li>In one 8-week flexible-dose trial, incidences within the doses (VRAYLAR 1-2 mg/day + antidepressant
              therapy [ADT] or 2-4.5 mg/day + ADT vs placebo + ADT) were akathisia (8%, 23% vs 3%), restlessness (8%, 8%
              vs 3%), fatigue (7%, 10% vs 4%), constipation (2%, 5% vs 2%), nausea (7%, 13% vs 5%), increased appetite
              (2%, 5% vs 2%), dizziness (4%, 5% vs 2%), insomnia (14%, 16% vs 8%), and extrapyramidal symptoms (12%, 18% vs
              5%).</li>
            </ul>
          </li>
          <li><b>Bipolar Mania:</b> The incidences within the recommended dose range (VRAYLAR 3–6 mg/day vs placebo)
            were EPS (26% vs 12%), akathisia (20% vs 5%), vomiting (10% vs 4%), dyspepsia (7% vs 4%), somnolence (7% vs
            4%), and restlessness (7% vs 2%).
          </li>
          <li><b>Bipolar Depression:</b> The incidences within the recommended doses (VRAYLAR 1.5 mg/day or 3 mg/day vs
            placebo) were nausea (7%, 7% vs 3%), akathisia (6%, 10% vs 2%), restlessness (2%, 7% vs 3%), and EPS (4%,
            6% vs 2%).
          </li>
          <li><b>Schizophrenia:</b> The incidences within the recommended dose range (VRAYLAR 1.5-3 mg/day and 4.5-6
            mg/day vs placebo) were EPS (15%, 19% vs 8%) and akathisia (9%, 13% vs 4%).
          </li>
        </ul>
        <p class="purple-text">
          <b>Please see full </b>
          <a href="https://www.allergan.com/assets/pdf/vraylar_pi" target="_blank" class="purple-text">
            Prescribing Information</a>
          <b>, including Boxed Warnings.</b>
        </p>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
