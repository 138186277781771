import {Component, Inject, Renderer2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import 'rxjs/add/operator/filter';
import moment from 'moment';

import {GoogleAnalyticsService} from './services/index';
import {Config} from './config/config';
import {ConfigService} from './config/config.service';
import {CookieService} from 'ngx-cookie-service';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class AppComponent {
  webPage = null;
  hasCookieConsentForm = null;
  hasImportantNews = null;
  hasInfoModal = null;
  isResourcesPage = false;

  public constructor(
    private router: Router,
    private titleService: Title,
    private _configService: ConfigService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _renderer2: Renderer2,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.setTitle();
    this.isResourcesPage = this.router.url.includes('resources');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isResourcesPage = event.urlAfterRedirects.includes('resources');
    });
  }

  public setTitle() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
        this.webPage = data.client.webpage;
        this.hasCookieConsentForm = data.client.has_cookie_consent_form;
        this.hasImportantNews = data.client.has_important_news;
        if (data.client.has_info_modal) {
          const expiryDate = moment(data.client.info_modal_expiry);
          if (expiryDate.diff(moment(), 'days', true) > -1) {
            this.hasInfoModal = true;
          }
        }
        this.titleService.setTitle(data.client.title.toString());
        if ((this.cookieService.check('cookieConsent') && this.webPage === 'vraylar') || this.webPage !== 'vraylar') {
          if (data.client.analytics) {
            this._googleAnalyticsService.subscribe(data.client.analytics_key);
          }
        }
        if(data.client.tag_script)
        {
          let script = this._renderer2.createElement('script');
          script.src = data.client.tag_script_url;
          script.async = true;
          this._renderer2.appendChild(this._document.head, script);
        }
        if(data.client.consent_cookie_tag)
        {
          let cookieProTag = this._renderer2.createElement('script');
          cookieProTag.type = "text/javascript";
          cookieProTag.src = data.client.consent_cookie_src;
          this._renderer2.setAttribute(cookieProTag, "data-domain-script",data.client.consent_cookie_domain_script);
          this._renderer2.setAttribute(cookieProTag, "charset","UTF-8");
          this._renderer2.appendChild(this._document.head, cookieProTag);
          let cookieProScript = this._renderer2.createElement('script');
          cookieProScript.type = "text/javascript";
          cookieProScript.innerHTML = "function OptanonWrapper() { }";
          this._renderer2.appendChild(this._document.head, cookieProScript);
        }
        if(data.client.facebook_tag_script)
        {
          let script = this._renderer2.createElement('script');
          script.innerHTML = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '2026969740893126');fbq('track', 'PageView');";
          this._renderer2.appendChild(this._document.head, script);
          let noscript = this._renderer2.createElement('noscript');
          let img = this._renderer2.createElement('img');
          img.src = "https://www.facebook.com/tr?id=2026969740893126&ev=PageView&noscript=1";
          this._renderer2.setAttribute(img, "height", "1");
          this._renderer2.setAttribute(img, "width", "1");
          this._renderer2.setAttribute(img, "style", "display:none");
          this._renderer2.appendChild(noscript, img);
          this._renderer2.appendChild(this._document.head, noscript);
        }
      },
        (error) => { console.log(error); this.webPage = null; }
      );
  }
}
