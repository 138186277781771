import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {RegisterComponent} from './register/register.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {ListComponent} from './list/list.component';
import {ResourcesComponent} from './resources/resources.component';
import {RegisterFormComponent} from './register-form/register-form.component';
import {IndexComponent} from './index/index.component';
import {ClientGuard} from './client.guard';
import {ListBroadcastComponent} from './list-broadcast/list-broadcast.component';
import {AttendeeSearchComponent} from './attendee-search/attendee-search.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'index', component: IndexComponent },
  { path: '', children: [], pathMatch: 'full', canActivate: [ClientGuard] },
  { path: 'list/:zip', component: ListComponent },
  { path: 'broadcast', component: ListBroadcastComponent },
  { path: 'register/:program_id', component: RegisterComponent },
  { path: 'confirm/:program_id/:status', component: ConfirmationComponent },
  { path: ':product/resources', component: ResourcesComponent},
  { path: 'register/:program_id/new', component: RegisterFormComponent},
  { path: 'attend/:program_id/new/:signature_status', component: RegisterFormComponent},
  { path: 'attend/:program_id', component: AttendeeSearchComponent}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRoutingModule {
}
