import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';

import {FutureContact} from '../interfaces/index';
import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';

@Component({
  selector: 'app-future-contact',
  templateUrl: './future-contact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FutureContactComponent implements OnInit {
  @Output() submitProbabletarget = new EventEmitter<FutureContact>();
  webPage = null;

  contactModel: FutureContact = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    opt_in_privacy: true,
    office_zipcode: ''
  };

  phoneMask = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  errorField: any[] = [];

  submitted = false;

  constructor(protected _configService: ConfigService) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
  }

  submitContact(form) {
    if (form.valid && this.errorField.length == 0) {
      this.submitProbabletarget.emit(this.contactModel);
      this.submitted = true;
    }
  }

  togglePlaceholder(field) {
    if (field.valueAccessor.inputElement.placeholder == '') {
      field.valueAccessor.inputElement.placeholder = '(___) ___-____';
    } else {
      field.valueAccessor.inputElement.placeholder = '';
    }
  }
}
