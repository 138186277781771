import {Component, OnInit, Renderer2} from '@angular/core';
import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';

@Component({
  selector: 'app-important-news',
  template: `
    <ng-container [ngSwitch]="webPage">
      <migraine-important-news *ngSwitchCase="'migraine'"></migraine-important-news>
    </ng-container>`
})
export class ImportantNewsComponent implements OnInit {
  webPage = null;

  constructor(
    protected renderer: Renderer2,
    protected _configService: ConfigService
  ) {
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          this.webPage = data.client.webpage;
        },
        (error) => this.webPage = null
      );
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'migraine-important-news',
  templateUrl: '../ui/migraine/important-news.component.html',
})
export class MigraineImportantNewsComponent extends ImportantNewsComponent {
  showModal = false;
  importantNewsLink = '';

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
          if (data.client.has_important_news_link) {
            this.importantNewsLink = data.client.has_important_news_link;
          }
        },
      );

    this.showImportantNews();
  }

  showImportantNews() {
    setTimeout(() => {
      this.showModal = true;
      this.renderer.addClass(document.body, 'modal-open');
    }, 10000);
  }

  onCloseBtnClick() {
    this.showModal = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
