<style type="text/css">

</style>
<li class="d-flex justify-content-between">
  <aside>
    <h3> {{program.topic}} </h3>
    <h4><strong>{{program.meeting_date_long}}</strong>
      <time>{{program.meeting_time}} {{program.timezone}}</time>
    </h4>
    <p>SPEAKER:
      <ng-container *ngFor="let speaker of program.speakers; trackBy: trackBySpeakerId">{{speaker.name}},
        {{speaker.degree}}</ng-container>
    </p>
    <p *ngIf="program.sf_members.email">SALES REP: {{program.sf_members.lastname}}, {{program.sf_members.firstname}}
      {{program.sf_members.midname}}, {{program.sf_members.email}}</p>
  </aside>
  <aside class="upcoming_register">
    <h5 *ngIf="program.meeting_type_live">{{program.distance}} Miles Away</h5>
    <button (click)="registerNow(program)" class="btn btn-info btn-sm">REGISTER</button>
    <h5 *ngIf="program.meeting_type_live">{{program.slots_available}} Slots Available</h5>
  </aside>
</li>
