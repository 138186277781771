import {BrowserModule} from '@angular/platform-browser';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {GoogleMapsModule} from '@angular/google-maps';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {TextMaskModule} from 'angular2-text-mask';

import {environment} from '../environments/environment';

import {ConfigService} from './config/config.service';

import {APIService, AttendeeService, GoogleAnalyticsService} from './services/index';
import {AddressService} from './services/address.service';
import {AnalyticsService} from './services/analytics.service';


import {CookieService} from 'ngx-cookie-service';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';

import {HomeComponent} from './home/home.component';
import {
  HeaderComponent,
  BotoxHeaderComponent,
  VraylarHeaderComponent,
  MigraineHeaderComponent,
  UbrelvyHeaderComponent,
  QuliptaHeaderComponent,
  VuityHeaderComponent
} from './header/header.component';
import {
  FooterComponent,
  VraylarFooterComponent,
  MigraineFooterComponent,
  BotoxFooterComponent,
  UbrelvyFooterComponent,
  QuliptaFooterComponent,
  VuityFooterComponent
} from './footer/footer.component';
import {
  SearchComponent,
  BotoxSearchComponent,
  VraylarSearchComponent,
  MigraineSearchComponent,
  VuitySearchComponent
} from './search/search.component';
import {
  ListComponent,
  BotoxListComponent,
  VraylarListComponent,
  MigraineListComponent,
  VuityListComponent
} from './list/list.component';
import {
  RegisterComponent,
  VraylarRegisterComponent,
  MigraineRegisterComponent,
  BotoxRegisterComponent, VuityRegisterComponent
} from './register/register.component';
import {
  SafetyInfoComponent,
  VraylarSafetyInfoComponent,
  MigraineSafetyInfoComponent,
  BotoxSafetyInfoComponent,
  UbrelvySafetyInfoComponent,
  QuliptaSafetyInfoComponent,
  VuitySafetyInfoComponent
} from './safety-info/safety-info.component';
import {MapLocationComponent} from './map-location/map-location.component';
import {SpeakerListComponent} from './speaker-list/speaker-list.component';
import {SpeakerInfoComponent} from './speaker-info/speaker-info.component';
import {SpeakerModalComponent} from './speaker-modal/speaker-modal.component';
import {
  ConfirmationComponent,
  VraylarConfirmationComponent,
  MigraineConfirmationComponent,
  BotoxConfirmationComponent,
  VuityConfirmationComponent
} from './confirmation/confirmation.component';
import {FutureContactComponent} from './future-contact/future-contact.component';
import {
  SingleProgramComponent,
  VraylarSingleProgramComponent,
  MigraineSingleProgramComponent,
  VuitySingleProgramComponent
} from './single-program/single-program.component';
import {SFMembersComponent} from './sf-members/sf-members.component';
import {CustomModal} from './custom-modal/custom-modal.component';
import {
  SavingsInformationComponent,
  MigraineSavingsInformationComponent,
  UbrelvySavingsInformationComponent
} from './savings-information/savings-information.component';
import {
  CookieConsentComponent,
  MigraineCookieConsentComponent,
  VraylarCookieConsentComponent,
  BotoxCookieConsentComponent,
  UbrelvyCookieConsentComponent,
  QuliptaCookieConsentComponent,
  VuityCookieConsentComponent
} from './cookie-consent/cookie-consent.component';
import {ImportantNewsComponent, MigraineImportantNewsComponent} from './important-news/important-news.component';
import {InfoModalComponent, MigraineInfoModalComponent, VraylarInfoModalComponent} from './info-modal/info-modal.component';
import {WatchVideoModalComponent, BotoxWatchVideoModalComponent} from './watch-video-modal/watch-video-modal.component';
import {ResourcesComponent, UbrelvyResourcesComponent, QuliptaResourcesComponent} from './resources/resources.component';
import {RegisterFormComponent, MigraineRegisterFormComponent, VuityRegisterFormComponent, VraylarRegisterFormComponent} from './register-form/register-form.component';
import {SignaturePadComponent} from './signature-pad/signature-pad.component';
import {IndexComponent, MigraineIndexComponent} from './index/index.component';
import {ListBroadcastComponent, VuityListBroadcastComponent} from './list-broadcast/list-broadcast.component';
import { TargetComplianceModal } from './target-compliance/target-compliance.component';
import { AttendeeSearchComponent, VraylarAttendeeSearchComponent } from './attendee-search/attendee-search.component';
import {OnDemandProgramComponent, BotoxOnDemandProgramComponent} from './ondemand-program/ondemand-program.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent, BotoxFooterComponent, VraylarFooterComponent, MigraineFooterComponent,
    HeaderComponent, BotoxHeaderComponent, VraylarHeaderComponent, MigraineHeaderComponent, UbrelvyHeaderComponent, QuliptaHeaderComponent,
    SearchComponent, BotoxSearchComponent, VraylarSearchComponent, MigraineSearchComponent,
    ListComponent, BotoxListComponent, VraylarListComponent, MigraineListComponent,
    RegisterComponent, BotoxRegisterComponent, VraylarRegisterComponent, MigraineRegisterComponent,
    SafetyInfoComponent, BotoxSafetyInfoComponent, VraylarSafetyInfoComponent, MigraineSafetyInfoComponent,
    MapLocationComponent, SpeakerInfoComponent,
    SpeakerListComponent, SpeakerModalComponent,
    ConfirmationComponent, BotoxConfirmationComponent, VraylarConfirmationComponent, MigraineConfirmationComponent, FutureContactComponent,
    SingleProgramComponent, VraylarSingleProgramComponent, MigraineSingleProgramComponent,
    SFMembersComponent,
    CustomModal,
    SavingsInformationComponent, MigraineSavingsInformationComponent,
    CookieConsentComponent, VraylarCookieConsentComponent, MigraineCookieConsentComponent, BotoxCookieConsentComponent,
    ImportantNewsComponent, MigraineImportantNewsComponent,
    InfoModalComponent, MigraineInfoModalComponent, VraylarInfoModalComponent,
    ResourcesComponent, UbrelvyResourcesComponent, QuliptaResourcesComponent,
    RegisterFormComponent, MigraineRegisterFormComponent, VraylarRegisterFormComponent, SignaturePadComponent, IndexComponent, MigraineIndexComponent,
    UbrelvySafetyInfoComponent, UbrelvyFooterComponent, UbrelvySavingsInformationComponent, UbrelvyCookieConsentComponent,
    QuliptaSafetyInfoComponent, QuliptaFooterComponent, QuliptaCookieConsentComponent, VuityHeaderComponent, VuitySearchComponent,
    VuityListComponent, VuitySingleProgramComponent, VuitySafetyInfoComponent, VuityFooterComponent, VuityCookieConsentComponent,
    VuityRegisterComponent, VuityRegisterFormComponent, VuityConfirmationComponent,
    ListBroadcastComponent, VuityListBroadcastComponent, TargetComplianceModal,
    AttendeeSearchComponent, VraylarAttendeeSearchComponent,
    WatchVideoModalComponent, BotoxWatchVideoModalComponent,
    OnDemandProgramComponent, BotoxOnDemandProgramComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AngularMultiSelectModule,
    FormsModule,
    HttpClientModule,
    GooglePlaceModule,
    GoogleMapsModule,
    AppRoutingModule,
    TextMaskModule
  ],
  providers: [
    ConfigService,
    APIService,
    AttendeeService,
    GoogleAnalyticsService,
    CookieService,
    AnalyticsService,
    AddressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
