<style type="text/css">
  h1 {
    font-size: 20px !important;
  }

  h5 {
    font-weight: 400;
  }

  form {
    margin-top: 20px;
  }

  div.header-table table {
    margin-bottom: 0px;
    border: 1px solid #fff;
    border-bottom: 0px;
    color: #fff !important;
  }

  div.body-table {
    max-height: 330px;
    overflow: auto;
  }

  div.body-table table {
    background-color: #fff;
    color: #000;
    margin-top: 0px;
  }

  button {
    border-radius: 0px;
    height: 39px !important;
    font-size: 15px !important;
    padding-left: 12px;
    padding-right: 12px;
    width: 80px;
    font-weight: 400 !important;
  }

  #register label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
    cursor: pointer;
  }

  .left_content {
    border-right: 1px solid #ccc;
  }

  .register_panel {
    background-color: #4B4A4B;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    margin-bottom: 20px;
  }

  .register_panel table {
    font-size: 14px !important;
  }

  .buttons {
    height: 250px;
  }

  button.rgstr-btn-search {
    margin-top: 0px;
  }

  a.email {
    font-weight: 600;
  }

  a.privacy_policy {
    font-weight: 600;
    color: #03AEA9 !important;
  }


  button.btn-warning {
    margin-right: 0px !important;
  }

  .title {
    margin-bottom: 25px;
  }

  .invalid-feedback {
    font-size: 16px;
    color: #F6AAA8 !important;
  }

  .searchtarget .form-control {
    font-size: 13px !important;
    padding: 5px;
  }

  .searchtarget > .col-lg-2:nth-child(n+2) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .searchtarget > .col-lg-2:nth-child(1) {
    padding-right: 5px;
  }

  .loading_target_icon {
    line-height: 150px;
  }

  tr.selected_target {
    background-color: #FAFFBD !important
  }

  td.inital_message {
    border-color: #4B4A4B;
    background-color: #4B4A4B;
    padding: 0px;
  }

  td.inital_message div {
    height: 100%;
    line-height: 49px;
    background: #fff;
  }

  .add-new {
    color: #03AEA9 !important;
  }

</style>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program || loading"><i class="fa fa-spinner fa-spin"> </i></div>
  <div class="row" *ngIf="program && !loading">
    <div class="title col-12">
      <h1>Register for VRAYLAR<sup>&reg;</sup> Event: {{program.formatted_program_id}}<br></h1>
      <div> {{program.meeting_date_long}} from {{program.meeting_time}}
        <span *ngIf='program.meeting_type_live'> in {{program.location.city}},
          {{program.location.state}}</span>
      </div>
    </div>
    <div class="col-lg-9 left_content">
      <div class="panel register_panel">
        <div class="panel-body">
          <form role="form" name="searchtarget" class="row searchtarget" #f="ngForm" novalidate>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="firstname" class="rgstr-label">First Name *</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.firstname" name="firstname" type="text"
                       required #firstname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }">
                <div *ngIf="f.submitted && firstname.invalid" class="invalid-feedback">
                  <div *ngIf="firstname.errors.required">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="lastname" class="rgstr-label">Last Name *</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.lastname" name="lastname" type="text"
                       required #lastname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }">
                <div *ngIf="f.submitted && lastname.invalid" class="invalid-feedback">
                  <div *ngIf="lastname.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="searchstate" class="rgstr-label">State</label>
                <select class="form-control browser-default custom-select" name="state" id="state"
                        [(ngModel)]="searchTargetModel.state" #state="ngModel" (change)="getCities(state)">
                  <option [value]="null"> Select State</option>
                  <option *ngFor="let state of states" [value]="state.state_alpha2"> {{state.name}} </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group" id="citySelectize">
                <label for="city" class="rgstr-label">City</label>
                <select class="form-control" name="city" id="city" [(ngModel)]="searchTargetModel.city" #city="ngModel"
                        placeholder="Select State">
                  <option [value]="null"> Select City</option>
                  <option *ngFor="let city of cities" [value]="city.city"> {{city.city}} </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="zip" class="rgstr-label">State License Number</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.license_number" name="license_number"
                       type="text" maxlength="10">
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label>&nbsp;</label>
                <button type="submit" class="btn btn-default btn-large rgstr-btn-search" name="btnSearch"
                        (click)="searchTarget(f)">Search
                </button>
              </div>
            </div>
          </form>
          <div><i>This is a promotional event sponsored by AbbVie. Due to company policy, attendance is limited to
            appropriate specialties due to product indication and promotion.</i></div>
          <br/>
          <div><i>For example, child psychiatrists and those who treat solely geriatrics in a long-term care setting are
            not permitted to attend.</i></div>
          <div class="row" *ngIf="!loading_target">
            <div class="target_table col-12">
              <div class=" header-table">
                <table width="100%" class="table">
                  <thead>
                  <tr>
                    <th width="30%">Name</th>
                    <th width="25%">Degree</th>
                    <th width="45%">Address</th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div class="body-table">
                <table width="100%" class="table table-bordered">
                  <tbody>
                  <tr *ngIf="initial">
                    <td colspan="3" class="inital_message text-center">
                      <div>SEARCH AND IDENTITY YOURSELF</div>
                    </td>
                  </tr>
                  <tr *ngIf="!targetList || targetList.length == 0 && !initial">
                    <td colspan="3" class="inital_message text-center">
                      <div> No results found</div>
                      <div>Try a New Search or
                        <a class="add-new" [routerLink]="['/register', program.program_id, 'new']">Add New</a>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let target of targetList; trackBy: trackByTargetId"
                      [ngClass]="{selected_target:(selected_target_id == target.target_id)}">
                    <td width="30%">
                      <div *ngIf="!target.repeated" class="row">
                        <div class="col-2">
                          <input (change)="onTargetCheckbox($event, target)"
                                 [checked]="selected_target_id == target.target_id"
                                 class="target-checkbox"
                                 type="checkbox">
                        </div>
                        <div (click)="selectTargetForRegister(target)" class="col-10 pl-0">
                          <span>{{target.firstname}} {{target.midname}}
                            {{target.lastname}}</span>
                        </div>
                      </div>
                    </td>
                    <td (click)="selectTargetForRegister(target)" width="25%">
                      <span *ngIf="!target.repeated">{{target.degreeList}}</span>
                    </td>
                    <td (click)="selectTargetForRegister(target)" width="45%">
                      {{target.address.address1}} {{target.address.city}}, {{target.address.state}}
                      {{target.address.zip}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="display_count" *ngIf="targetList.length > 0"> Showing 1 to {{targetCount}} entries</div>
              <div class="display_add_new" *ngIf="targetList.length > 0">Not you?<br>
                <a class="add-new" [routerLink]="['/register', program.program_id, 'new']">Add New</a>
              </div>
            </div>
          </div>
          <div *ngIf="loading_target" class="loading_target_icon text-center">
            <i class="fa fa-spinner fa-spin fa-3x"> </i>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="Email">Email Address*
                <input class="form-control" type="email" [ngClass]="{'is-invalid': errorEmail}" placeholder="Email"
                       name="email" #email="ngModel"
                       [(ngModel)]="registerModel.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                       (change)="checkEmailAddress(email)" required email>
              </label>
              <div *ngIf="errorEmail">
                <p class="text-danger">{{errorEmailMsg}}</p>
              </div>
            </div>
            <div class="col-md-12">
              <p>
                AbbVie may collect your personal data through your online and offline interactions with us, including your contact,
                demographic, geolocation, and professional data. We may also collect your online usage data automatically through
                cookies and similar technologies. We use this data for several purposes, such as to comply with our legal obligations,
                to perform a contract with you, to provide you with and improve our programs, services, and products, to customize your
                experiences, and for research and analytics. We retain your personal data for as long as necessary to fulfill these
                purposes or to comply with our record retention obligations. We do not sell your personal data, but we may use and
                disclose it to marketing and advertising partners to deliver you ads based on your interests inferred from your activity
                across other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out of
                the use or disclosure of your personal data for online targeted advertising or for website analytics, go to your 
                <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy Choices</a> on our website. 
                For more information on the data categories we collect, the purposes for their collection, our
                disclosures to third parties, your data subject rights, and our data retention criteria, visit our
                <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank" class="privacy_policy">Privacy Notice</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="errorRSVP && !registerModel.canc_declined && !registerModel.registered">
          <p class="text-danger">{{errorMsg}}</p>
        </div>
      </div>
      <div class="buttons">
        <button type="button" class="btn btn-md btn-warning pull-right" (click)="registerVraylar()"
                *ngIf="!registering">
          <span>Submit</span>
        </button>
        <button type="button" class="btn btn-md btn-warning pull-right" (click)="registerVraylar()" *ngIf="registering">
          <i class="fa fa-spinner fa-spin"> </i>
        </button>
        <button type="button" class="btn btn-md btn-default pull-right" (click)="cancel()">Cancel</button>
      </div>
    </div>
    <div class="col-lg-3" id="right_bar">
      <div class="row" *ngIf='program.location'>
        <div class="col-12">
          <app-map-location [location]='program.location' [webPage]="webPage" [meetingLive]="program.meeting_type_live">
          </app-map-location>
        </div>

        <div class="col-12 col-md-6 col-lg-12">
          <app-speaker-list *ngIf="program" [speakers]='program.speakers' [title]="'Speaker(s)'" [webPage]="webPage"
                            class="col-12 col-md-6 col-lg-12"></app-speaker-list>
        </div>
        <div class="col-12 col-md-6 col-lg-12">
          <app-sf-members *ngIf="program" [formatPhone]="true" [rep]='program.sf_members'
                          [title]="'Sales Representative'" [webPage]="webPage"
                          class="col-12 col-md-6 col-lg-12"></app-sf-members>
        </div>
      </div>
    </div>
  </div>
</div>
