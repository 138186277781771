<style type="text/css">
  #botox_footer {
    padding: 20px 0;
  }

  #botox_footer .container {
    padding-left: 0;
    padding-right: 0;
  }

  #botox_footer p {
    font-size: 0.9rem;
    color: #000;
    margin: 0;
    padding: 0
  }

  .cookie-settings a {
    text-align: center;
    list-style-type: none;
    padding: 0 5px 0 0 !important;
    border: none !important;
    background: none !important;
    color: #2D559A !important;
    font-size: 14px !important;
    white-space: nowrap;
  }

  .check-icon {
    height: 12px;
    width: 26px;
    margin-bottom: 3px;
  }

  .privacy_choices {
    white-space: nowrap;
  }

  a {
    color: #2D559A;
    font-weight: 600;
  }
 
</style>
<footer id="botox_footer">
  <div class="container">
    <div class="row">
      <div class="col-12" id="copyright">
        <p class="cookie-settings">
          <a href="https://www.abbvie.com/accessibility-statement.html" target="_blank">Accessibility Statement</a>|
          <a href="https://www.abbvie.com/contactus.html" target="_blank">Contact Us</a>|
          <a href="https://www.abbvie.com/termsofuse.html" target="_blank">Terms of Use</a>|
          <a href=" https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank">Privacy Notice</a>|
          <a class="ot-sdk-show-settings" href="javascript:void(0)" id="ot-sdk-btn" title="Overridden by OneTrust">
            Cookies Settings
          </a>|
          <span class="privacy_choices">
            <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank">Your Privacy Choices</a>
            <img class="check-icon" src="assets/botox/images/privacyoptions.svg" alt="check">
          </span>
        </p>
        <p>
          © 2024 AbbVie. All rights reserved.
          BOTOX® and its design are registered trademarks of Allergan, Inc., an AbbVie company.
          All other trademarks are the property of their respective owners.
        </p>
        <p>US-BNO-230310 03/24</p>
      </div>
    </div>
  </div>
</footer>
