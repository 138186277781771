import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Config} from './config/config';
import {ConfigService} from './config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {
  webPage = null;
  clients = ['migraine'];

  constructor(private router: Router, private _configService: ConfigService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._configService.getConfig()
      .subscribe((data: Config) => {
        this.webPage = data.client.webpage;
        if (this.clients.includes(this.webPage)) {
          return this.router.navigate(['/index']);
        }
        return this.router.navigate(['/home']);
      });
  }
}
