<style type="text/css">
  .specialty_curve {
    width: 100%;
    height: 100%;
  }

  .specialty_curve:before {
    content: '';
    background-image: url(../../../assets/ubrelvy/images/ubrelvy-background-overlay-large-01.svg);
    height: 24%;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    background-size: 100% 100%;
    border: 0;
  }

  .event_banner {
    background-color: #3b4143;
    padding: 70px 0 120px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .event_banner:after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/ubrelvy/images/swoosh-single-white.svg');
    background-position: right bottom;
    background-size: 80% 80%;
    background-repeat: no-repeat;
    opacity: .08;
    position: absolute;
    right: -50px;
    bottom: 0;
    z-index: -1;
  }

  .event_banner h1 {
    text-transform: uppercase;
    color: #75c4d6;
    font-size: 32px;
    margin: 0;
    font-weight: 700;
  }

  .download_resource_sec h2 {
    color: #75cfdd;
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: 700;
  }

  .download_resource_sec h3 {
    color: #475353;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .download_resource > ul {
    width: 100%;
    padding: 0;
  }

  .download_resource > ul > li {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .download_resource_left {
    width: 80px;
    margin: 0;
  }

  .download_resource_left img {
    padding: 5px 5px 0;
  }

  .download_resource_right {
    width: calc(100% - 80px);
    padding-left: 15px;
  }

  .download_resource_right img {
    margin-top: 10px;
  }

  .download_resource_box h4 {
    color: #75cfdd;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2px;
    margin-top: 0;
  }

  .download_resource_box p {
    color: #475353;
    font-weight: 500;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .download_resource_box figure {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .download_text a {
    color: #75cfdd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -o-flex;
    display: flex;
    font-weight: 600;
    margin-top: 15px;
    text-decoration: none;
  }

  .download_text a span {
    border-bottom: solid 3px #75cfdd;
    font-size: 11px;
  }

  .download_text a:hover span {
    color: #475353;
    border-color: #475353;
  }

  .download_text a:hover img {
    -webkit-filter: brightness(0.2);
    -moz-filter: brightness(0.2);
    -ms-filter: brightness(0.2);
    -o-filter: brightness(0.2);
    filter: brightness(0.2);
  }

  .download_text img {
    width: 20px;
    margin-right: 15px;
  }

  @media only screen and (min-width: 1200px) {
    .event_banner {
      padding: 70px 0 150px;
      margin-bottom: 50px;
    }

    .event_banner:after {
      background-size: 45% auto;
      bottom: 80px;
    }

    .event_banner .specialty_curve:before {
      height: 50%;
    }

    .event_banner h1 {
      font-size: 40px;
    }

    .download_text a span {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 992px) {
    .download_resource_sec h2 {
      font-size: 32px;
    }

    .download_resource_sec h3 {
      font-size: 18px;
    }

    .download_resource > ul > li {
      margin-bottom: 40px;
    }

    .download_resource_right {
      padding-right: 50px;
    }
  }

  @media only screen and (max-width: 767px) {
    .specialty_curve:before {
      height: 10%
    }

    .event_banner {
      padding: 70px 0 95px;
    }

    .event_banner:after {
      background-position: 50% bottom;
      background-size: 160% 90%;
      right: 0;
    }

    .event_banner h1 {
      font-size: 25px;
    }

    .event_banner .specialty_curve:before {
      height: 20%;
    }

    .download_resource_sec h2 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .download_resource > ul > li {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: solid 1px #f5f5f5;
    }

    .download_resource_box {
      margin-bottom: 15px;
    }

    .download_resource_left {
      width: 50px;
    }

    .download_resource_right {
      width: calc(100% - 50px);
    }

    .download_resource_sec h3 {
      font-size: 15px;
    }

    .download_resource_box p, .download_resource_box h4 {
      font-size: 14px;
    }
  }
</style>
<ubrelvy-header></ubrelvy-header>
<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>Resources Page</h1>
      </div>
    </div>
  </div>
  <div class="specialty_curve"></div>
</section>
<section class="download_resource_sec">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 download_resource">
        <h2>Download resources to support your office and your patients</h2>
        <h3>In-Office Tools</h3>
        <ul class="clearfix">
          <li class="d-flex justify-content-between">
            <div class="download_resource_box col-lg-9 col-md-8">
              <div class="row">
                <div class="download_resource_left">
                  <img src="assets/ubrelvy/images/icons/rx-icon.svg" class="img-fluid"
                       alt="Ubrelvy prescription form">
                </div>
                <div class="download_resource_right">
                  <h4>Ubrelvy prescription form</h4>
                  <p>Make it even easier to prescribe UBRELVY. Download this template to save time when writing out a
                    prescription.</p>
                </div>
              </div>
            </div>
            <div class="download_text col-lg-3  col-md-4">
              <a href="https://www.ubrelvyhcp.com/pdf/ubrelvy-prescription-form.pdf" target="_blank">
                <img src="assets/ubrelvy/images/icons/down-arrow.svg" alt="Download Form">
                <span>Download the form</span>
              </a>
            </div>
          </li>
          <!-- li End -->
          <li class="d-flex justify-content-between">
            <div class="download_resource_box col-lg-9  col-md-8">
              <div class="row">
                <div class="download_resource_left">
                  <img src="assets/ubrelvy/images/icons/dosing.svg" class="img-fluid" alt="Dosing Card">
                </div>
                <div class="download_resource_right">
                  <h4>Dosing Card</h4>
                  <p>UBREVLY has 2 dosing strengths. Learn more about dosing considerations and how to prescribe.</p>
                </div>
              </div>
            </div>
            <div class="download_text col-lg-3  col-md-4">
              <a href="https://www.ubrelvyhcp.com/pdf/ubrelvy-dosing-card.pdf" target="_blank">
                <img src="assets/ubrelvy/images/icons/down-arrow.svg" alt="Download Form">
                <span>Download the dosing card</span>
              </a>
            </div>
          </li>
          <!-- li End -->
        </ul>
        <!-- Office Tool End -->

        <h3>Patient Resources</h3>
        <ul class="clearfix">
          <li class="d-flex justify-content-between">
            <div class="download_resource_box col-lg-9  col-md-8">
              <div class="row">
                <div class="download_resource_left">
                  <img src="assets/ubrelvy/images/icons/patient-brochure.svg" class="img-fluid"
                       alt="Patient Brochure">
                </div>
                <div class="download_resource_right">
                  <h4>Patient Brochure</h4>
                  <p>Encourage your patients to take UBREVLY when a migraine strikes. Download a brochure to help
                    patients better understand UBRELVY and how it works.</p>
                </div>
              </div>
            </div>
            <div class="download_text col-lg-3 col-md-4">
              <a href="https://www.ubrelvyhcp.com/pdf/ubrelvy-patient-brochure.pdf" target="_blank">
                <img src="assets/ubrelvy/images/icons/down-arrow.svg" alt="Download form">
                <span>Download the patient brochure</span>
              </a>
            </div>
          </li>
          <!-- li End -->
        </ul>
        <!-- Patient Resource End -->

        <h3 class="text-uppercase">Access and Savings Tool</h3>
        <ul class="clearfix">
          <li class="d-flex justify-content-between">
            <div class="download_resource_box col-lg-9 col-md-8">
              <div class="row">
                <div class="download_resource_left">
                  <img src="assets/ubrelvy/images/icons/save-card-hcp.svg" class="img-fluid"
                       alt="Access Support Leave Behind<">
                </div>
                <div class="download_resource_right">
                  <h4>Access Support Leave Behind</h4>
                  <p>UBREVLY offers a savings programs for eligible patients and services to aid along the way. Find
                    helpful information on ways to get your patients covered or click below to visit the U-Demand<sup>&trade;</sup>
                    Relief Suite for additional information.</p>
                  <figure><img src="assets/ubrelvy/images/udemand-logo-green.svg" class="img-fluid"
                               alt="Udemand Logo">
                  </figure>
                </div>
              </div>
            </div>
            <div class="download_text col-lg-3  col-md-4">
              <a href="https://www.ubrelvyhcp.com/pdf/ubrelvy-access-support-leave-behind.pdf" target="_blank">
                <img src="assets/ubrelvy/images/icons/down-arrow.svg" alt="Ubrelvy prescription form">
                <span>Download the leave behind</span>
              </a>
            </div>
          </li>
          <!-- li End -->
        </ul>
        <!-- Access and Saving Tool End -->
      </div>
    </div>
  </div>
</section>
<ubrelvy-savings-information id="callout_white_banner"></ubrelvy-savings-information>
<ubrelvy-safety-info></ubrelvy-safety-info>
