import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

import * as $ from 'jquery';

import {Meeting, Target} from '../interfaces/index';

import {ConfigService} from '../config/config.service';
import {APIService, AttendeeService} from '../services/index';
import {Config} from '../config/config';
import {Meta, Title} from '@angular/platform-browser';
import {SignaturePadComponent} from '../signature-pad/signature-pad.component';
import {AnalyticsService} from '../services/analytics.service';
import {NgModel} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TargetComplianceModal} from '../target-compliance/target-compliance.component';

@Component({
  selector: 'app-register',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-register *ngSwitchCase="'vraylar'"></vraylar-register>
      <migraine-register *ngSwitchCase="'migraine'"></migraine-register>
      <vuity-register *ngSwitchCase="'vuity'"></vuity-register>
      <botox-register *ngSwitchCase="'botox'"></botox-register>
    </ng-container>`
})
export class RegisterComponent implements OnInit {
  webPage;
  programType;
  registerModel: any = {
    'address1': null,
    'address2': null,
    'zip': null,
    'state': null,
    'city': null,
    'email': null,
    'message_confirmation': null,
    'phone_number': null
  };
  program_id: String;
  program: Meeting;

  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  // status flags;
  readyforinput = true;
  registered = false;
  registering = false;
  register_failed = false;
  errorMsg = 'Registration failed. Please try again.';
  different_email = false;
  zipcode_error = false;
  states: Object[];
  loading;

  constructor(
    protected _activeRouter: ActivatedRoute,
    protected _router: Router,
    protected _configService: ConfigService,
    protected _apiService: APIService,
    protected _attendeeService: AttendeeService,
    protected titleService: Title,
    protected _analyticsService: AnalyticsService,
    protected _modalService: NgbModal,
    private meta: Meta
  ) {
  }

  ngOnInit() {
    this.registerModel.answers = [];
    this._configService.getWebPage()
      .subscribe((data) => {
          this.webPage = data;
          // this.getStates();
        },
        (error) => this.webPage = null
      );
    this._configService.getConfig()
      .subscribe((data: Config) => {
        if (data.client.has_meta_description) {
          this.meta.updateTag({name: 'description', content: data.client.registration_meta_description});
        }
      });
  }

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getWebPage()
        .subscribe((data) => {
            this.webPage = data;
            this.getStates();

            this._activeRouter.params
              .subscribe(params => {
                  this.program_id = params['program_id'];
                  this.loading = true;
                  this._attendeeService.getCurrentProgram(this.program_id)
                    .subscribe((program: Meeting) => {
                      this.program = program;
                      this.program_id = this.program.program_id;
                      this.loading = false;
                      observer.next(true);
                    }, error => {
                      this._router.navigate(['home']);
                    });
                },
                error => {
                  console.log(error);
                  observer.error(false);
                });
          },
          (error) => {
            this.webPage = null;
            observer.error(false);
          }
        );
    });
  }

  getAddressList = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        if (term.length < 2) {
          return [];
        } else {
          this._apiService.getGeoAddresses(text$)
            .subscribe(function (data) {
              return data;
            });
        }
      })
    )

  setAddress = function (addrObj) {
    this.registerModel.address1 = addrObj.street_number + ' ' + addrObj.address1;
    // this.registerModel.address2=addrObj.address1;
    this.registerModel.city = addrObj.city;
    this.registerModel.zip = addrObj.zip;
    this.registerModel.state = addrObj.state;
    $('input[name="city"]').focus();
    $('input[name="zip"]').focus();
    return true;
  };

  cancel() {
    this._router.navigate(['home']);
  }

  register(form) {
    if (this.registerModel.email != this.registerModel.confirm_email) {
      this.different_email = true;
      $('html,body').animate({scrollTop: 0}, 500);
      return;
    }

    if (this.registerModel.zip) {
      const regex = /[0-9]{5}/g;
      const found = this.registerModel.zip.match(regex);
      if (this.registerModel.zip.length != 5 || (found && found.length == 0)) {
        this.register_failed = true;
        this.zipcode_error = true;
        $('html,body').animate({scrollTop: 0}, 500);
        return;
      }

    }

    if (!form.valid) {
      $('html,body').animate({scrollTop: 0}, 500);
    } else {
      this.readyforinput = false;
      this.registered = false;
      this.registering = true;
      this.register_failed = false;
      this._attendeeService.register(this.program_id, this.registerModel)
        .subscribe(attendee => {
            this._attendeeService.attendee_id = attendee.attendee_id;
            let attendee_status = 1;
            const total_attendee: Number = (this.registerModel.guests_rsvpd ? (parseInt(this.registerModel.guests_rsvpd)) : 0) + 1;
            if (this.program.slots_available < total_attendee) {
              attendee_status = 0;
            }
            this._router.navigate(['confirm/' + this.program_id + '/' + attendee_status]);
          },
          error => {
            //  $("html, body").animate({ scrollTop: 0 }, "slow");
            console.log(error.message);
            if (error.message) {
              this.errorMsg = error.message;
            }
            this.register_failed = true;
            this.registered = false;
            this.registering = false;
            this.readyforinput = true;
          });
    }
  }

  getStates() {
    this._apiService.get(this.webPage + '/getStates', [])
      .subscribe(states => {
          this.states = states;
        }
        , (error) => {
          console.log('Problem getting states');
        }
      );
  }
}

@Component({
  selector: 'botox-register',
  templateUrl: '../ui/botox/register.component.html'
})

export class BotoxRegisterComponent extends RegisterComponent {
  ngOnInit() {
    this.initializeClient().subscribe(() => {
      },
      error => {
        console.log(error);
      });
  }

  registerBotox(form) {
    if (this.registerModel.email != this.registerModel.confirm_email) {
      this.different_email = true;
      $('html,body').animate({scrollTop: 0}, 500);
      return;
    }

    if (!form.valid) {
      $('html,body').animate({scrollTop: 0}, 500);
    } else {
      this.registered = false;
      this.registering = true;
      this.register_failed = false;
      this._attendeeService.register(this.program_id, this.registerModel)
        .subscribe(attendee => {
            this._attendeeService.attendee_id = attendee.attendee_id;
            const attendee_status = 1;
            this._router.navigate(['confirm/' + this.program_id + '/' + attendee_status]);
          },
          error => {
            if (error.message) {
              this.errorMsg = error.message;
            }
            this.register_failed = true;
            this.registered = false;
            this.registering = false;
          });
    }
  }
}

/*
Vraylar Register Component Class
 */
@Component({
  selector: 'vraylar-register',
  templateUrl: '../ui/vraylar/register.component.html'
})

export class VraylarRegisterComponent extends RegisterComponent {
  states: Object[];
  targetList: Target[] = [];
  initial = true;
  loading_target = false;
  cities: Object[];
  searchTargetModel = {
    firstname: null,
    lastname: null,
    state: null,
    city: null,
    license_number: null
  };
  errorEmail = false;
  errorEmailMsg = '';
  errorRSVP = false;
  selected_target_id: number = null;
  attendee;
  targetCount: number = 0;
  showSignature;

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
    });
  }

  trackByTargetId(index: number, target: Target) {
    return target.target_id;
  }

  onTargetCheckbox(event, target) {
    this.resetTarget();
    if (event.target.checked) {
      this.selectTargetForRegister(target);
    }
  }

  resetTarget() {
    this.showSignature = false;
    this.selected_target_id = null;
    this.attendee = null;
    this.registerModel.email = null;
    this.registerModel.message_confirmation = false;
    this.registerModel.phone_number = null;
  }

  searchTarget(form) {
    if (!form.valid) {
      return;
    }

    this.initial = false;
    this.loading_target = true;
    this.searchTargetModel.state = this.searchTargetModel.state == 'null' ? null : this.searchTargetModel.state;
    this.searchTargetModel.city = this.searchTargetModel.city == 'null' ? null : this.searchTargetModel.city;
    this._attendeeService.searchTarget(this.searchTargetModel)
      .subscribe(data => {
          this.targetList = data;
          this.loading_target = false;
          this.targetCount = 0;
          for (let i = 0; i < this.targetList.length; i++) {
            if (this.targetList[i].repeated == false) {
              this.targetCount++;
            }
          }
        },
        (error) => {
          this.loading_target = false;
          console.log('Failed to load target');
        });
  }

  selectTargetForRegister(target) {
    this.selected_target_id = target.target_id;
    this.attendee = target;
    this.attendee.email = null;
    this.registerModel.email = null;
    this.registerModel.message_confirmation = false;
    this.registerModel.phone_number = null;
    this._attendeeService.checkMeetingAttendee(this.program_id, target)
      .subscribe(attendee_details => {
          if (attendee_details['attendee_id']) {
            this.attendee.attendee_id = attendee_details['attendee_id'];
            this.registerModel.email = attendee_details['email'];
          } else {
            // Show signature if it is a Target.
            this.showSignature = true;
          }
        },
        (error) => {
          console.log('Failed to get attendee id');
        });
  }

  checkEmailAddress(email) {
    if (email.errors) {
      this.errorEmail = true;
      this.errorEmailMsg = 'Please enter a valid email address';
    } else {
      this.errorEmail = false;
      this.errorEmailMsg = '';
    }
  }

  registerVraylar() {
    // Check if any errors
    if (this.errorEmail) {
      return;
    }

    // Check if target is selected
    if (!this.attendee || !this.attendee.target_id) {
      this.errorMsg = 'Please search and select yourself from above.';
      this.errorRSVP = true;
      return;
    }

    if (!this.registerModel.email) {
      this.errorEmail = true;
      this.errorEmailMsg = 'Email is Required';
      return;
    }

    this.attendee.registered = true;
    this.attendee.canc_declined = false;

    if (!this.attendee.attendee_id && this.attendee.restricted) {
      const modelRestrictedRef = this._modalService.open(TargetComplianceModal, {size: 'lg', centered: true});
      modelRestrictedRef.componentInstance.message = 'Registration could not be completed. ' +
        'Please contact your AbbVie Representative with questions.';
      this.registering = false;
      return;
    }

    // make degree array
    const degreeList: number[] = [];
    this.attendee.degrees.forEach((degree) => {
      degreeList.push(degree.degree_id);
    });
    this.attendee.degrees = degreeList;

    // make specialty array
    const specialtyList: number[] = [];
    this.attendee.specialties.forEach((specialty) => {
      specialtyList.push(specialty.specialty_id);
    });
    this.attendee.specialties = specialtyList;

    // make title array
    const titleList: number[] = [];
    this.attendee.titles.forEach((title) => {
      titleList.push(title.title_id);
    });
    this.attendee.titles = titleList;

    this.registering = true;
    this.attendee.email = this.registerModel.email;

    if (!this.attendee.attendee_id) {
      this._attendeeService.checkCustomerCompliance(this.program_id, this.attendee.customer_id, this.attendee.target_id)
        .subscribe(response => {
            if (response['compliance_status'] === 'fail') {
              const modelref = this._modalService.open(TargetComplianceModal, {size: 'lg', centered: true});
              modelref.componentInstance.message = response['error_message'];
              this.registering = false;
            } else {
              this.registerAttendee();
            }
          },
          (error) => {
            this.registering = false;
            console.error(error);
          });
    } else {
      this.registerAttendee();
    }
  }

  registerAttendee() {
    this._attendeeService.register(this.program_id, this.attendee)
      .subscribe(status => {
          const attendee_status = 1;
          this.registering = false;
          this._router.navigate(['confirm/' + this.program_id + '/' + attendee_status]);
        },
        error => {
          this.registering = false;
          $('html, body').animate({scrollTop: 0}, 'slow');
          if (error.message) {
            this.errorMsg = error.message;
          }
        });
  }

  getCities(state) {
    this.searchTargetModel.city = null;
    this.cities = [];
    if (state.value == null || state.value == 'null') {
      return;
    }
    this._apiService.get(this.webPage + '/getCities/' + state.value, [])
      .subscribe(cities => {
          this.cities = cities;
        }
        , (error) => {
          console.log('Problem getting cities');
        }
      );
  }
}

/*
migraine Register Component Class
 */
@Component({
  selector: 'migraine-register',
  templateUrl: '../ui/migraine/register.component.html'
})

export class MigraineRegisterComponent extends RegisterComponent {
  states: Object[];
  targetList: Target[] = [];
  initial: Boolean = true;
  loading_target: Boolean = false;
  cities: Object[];
  searchTargetModel = {
    firstname: null,
    lastname: null,
    state: null,
    city: null,
    license_number: null,
    npi_number: null
  };
  errorEmail = false;
  errorEmailMsg = '';
  errorRSVP = false;
  errorPhoneNumber = false;
  errorPhoneMsg = '';
  selected_target_id: number = null;
  attendee;
  targetCount = 0;

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
    });
  }

  trackByTargetId(index: number, target: Target) {
    return target.target_id;
  }

  searchTarget(form) {
    if (!form.valid) {
      return;
    }

    this.initial = false;
    this.loading_target = true;
    this.searchTargetModel.state = this.searchTargetModel.state == 'null' ? null : this.searchTargetModel.state;
    this.searchTargetModel.city = this.searchTargetModel.city == 'null' ? null : this.searchTargetModel.city;
    this._attendeeService.searchTarget(this.searchTargetModel)
      .subscribe(data => {
          this.targetList = data;
          this.loading_target = false;
          this.targetCount = 0;
          for (let i = 0; i < this.targetList.length; i++) {
            if (this.targetList[i].repeated == false) {
              this.targetCount++;
            }
          }
        },
        (error) => {
          this.loading_target = false;
          console.log('Failed to load target');
        });
  }

  selectTargetForRegister(target) {
    this.selected_target_id = target.target_id;
    this.attendee = target;
    this.attendee.email = null;
    this.registerModel.email = null;
    this.registerModel.message_confirmation = false;
    this.registerModel.phone_number = null;
    this.errorPhoneNumber = false;
    this._attendeeService.checkMeetingAttendee(this.program_id, target)
      .subscribe(attendee_details => {
          if (attendee_details['attendee_id']) {
            this.attendee.attendee_id = attendee_details['attendee_id'];
            this.registerModel.email = attendee_details['email'];
            if (attendee_details['sms_opt_in'] && attendee_details['sms_opt_in'] !== '0000-00-00 00:00:00') {
              this.registerModel.message_confirmation = true;
              this.errorPhoneNumber = true;
              for (const phoneDetails of attendee_details['phones']) {
                if (phoneDetails.type === 'cell') {
                  this.registerModel.phone_number = phoneDetails.phone;
                  this.errorPhoneNumber = false;
                }
              }
            }
          }
        },
        (error) => {
          console.log('Failed to get attendee id');
        });
  }

  checkEmailAddress(email) {
    if (email.errors) {
      this.errorEmail = true;
      this.errorEmailMsg = 'Please enter a valid email address';
    } else {
      this.errorEmail = false;
      this.errorEmailMsg = '';
    }
  }

  checkMessageConfirmation() {
    this.errorPhoneNumber = false;
    if (this.registerModel.message_confirmation) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter Mobile Phone Number';
    } else {
      this.registerModel.phone_number = '';
    }
  }

  checkPhoneNumber(phone) {
    if (phone.value == '') {
      this.errorPhoneNumber = true;
    } else if (phone.invalid) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter a Valid Mobile Phone Number';
    } else {
      this.errorPhoneNumber = false;
      this.errorPhoneMsg = null;
    }
  }

  registerMigraine() {
    // Check if any errors
    if (this.errorPhoneNumber || this.errorEmail) {
      return;
    }

    // Check if target is selected
    if (!this.attendee || !this.attendee.target_id) {
      this.errorMsg = 'Please search and select yourself from above.';
      this.errorRSVP = true;
      return;
    }

    if (!this.attendee.attendee_id && this.attendee.restricted) {
      const modelRestrictedRef = this._modalService.open(TargetComplianceModal, {size: 'lg', centered: true});
      modelRestrictedRef.componentInstance.message = 'Registration could not be completed. ' +
        'Please contact your AbbVie Representative with questions.';
      this.registering = false;
      return;
    }

    if (!this.registerModel.email) {
      this.errorEmail = true;
      this.errorEmailMsg = 'Email is Required';
      return;
    }

    // make degree array
    const degreeList: number[] = [];
    this.attendee.degrees.forEach((degree) => {
      degreeList.push(degree.degree_id);
    });
    this.attendee.degrees = degreeList;

    // make specialty array
    const specialtyList: number[] = [];
    this.attendee.specialties.forEach((specialty) => {
      specialtyList.push(specialty.specialty_id);
    });
    this.attendee.specialties = specialtyList;

    // make title array
    const titleList: number[] = [];
    this.attendee.titles.forEach((title) => {
      titleList.push(title.title_id);
    });
    this.attendee.titles = titleList;
    if (this.registerModel.message_confirmation) {
      this.attendee.sms_opt_in = 1;
      if (this.registerModel.phone_number) {
        this.attendee.phones = [
          {
            'type': 'cell',
            'phone': this.registerModel.phone_number
          }
        ];
      }
    } else {
      this.attendee.sms_opt_in = 0;
    }

    this.registering = true;
    this.attendee.email = this.registerModel.email;

    if (!this.attendee.attendee_id) {
      this._attendeeService.checkCustomerCompliance(this.program_id, this.attendee.customer_id, this.attendee.target_id)
        .subscribe(response => {
            if (response['compliance_status'] === 'fail') {
              const modelref = this._modalService.open(TargetComplianceModal, {size: 'lg', centered: true});
              modelref.componentInstance.message = response['error_message'];
              this.registering = false;
            } else {
              this.registerAttendee();
            }
          },
          (error) => {
            this.registering = false;
            console.error(error);
          });
    } else {
      this.registerAttendee();
    }
  }

  registerAttendee() {
    this._attendeeService.register(this.program_id, this.attendee)
      .subscribe(status => {
          const attendee_status = 1;
          this.registering = false;
          this._router.navigate(['confirm/' + this.program_id + '/' + attendee_status]);
        },
        error => {
          this.registering = false;
          $('html, body').animate({scrollTop: 0}, 'slow');
          if (error.message) {
            this.errorMsg = error.message;
          }
        });
  }

  getCities(state) {
    this.searchTargetModel.city = null;
    this.cities = [];
    if (state.value == null || state.value == 'null') {
      return;
    }
    this._apiService.get(this.webPage + '/getCities/' + state.value, [])
      .subscribe(cities => {
          this.cities = cities;
        }
        , (error) => {
          console.log('Problem getting cities');
        }
      );
  }
}

/*
migraine Register Component Class
 */
@Component({
  selector: 'vuity-register',
  templateUrl: '../ui/vuity/register.component.html'
})

export class VuityRegisterComponent extends RegisterComponent {
  states: Object[];
  targetList: Target[] = [];
  initial: Boolean = true;
  loading_target: Boolean = false;
  cities: Object[];
  searchTargetModel = {
    firstname: null,
    lastname: null,
    state: null,
    city: null,
    license_number: null
  };
  errorEmail = false;
  errorEmailMsg = '';
  errorRSVP = false;
  errorPhoneNumber = false;
  errorPhoneMsg = '';
  selected_target_id: number = null;
  attendee;
  targetCount = 0;
  showSignature = false;
  validSignature = false;
  firstInitiation = true;
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': window.innerWidth > 768 ? 500 : 350,
    'canvasHeight': 170,
    'backgroundColor': 'rgb(255, 255, 255)'
  };

  ngOnInit() {
    this.initializeClient().subscribe((success) => {
      setTimeout(() => {
        this.track();
      }, 2000);
    });
  }

  track() {
    const analyticObject = {
      'pageInfo': {
        'domain': window.location.hostname,
        'subDomain': '',
        'url': window.location.href,
        'path': window.location.pathname,
        'pageName': 'Register Event',
        'title': this.titleService.getTitle(),
        'prefix': 'vuitylive'
      },
      'category': {
        'primaryCategory': 'Register',
        'subCategory1': 'Identified HCP',
        'subCategory2': this.program_id.toString()
      },
      'attributes': {
        'country': 'US',
        'language': 'en-US',
        'template': '',
        'promoMatsNumber': 'US-VUI-210146',
        'globalISI': '',
        'type': 'Register'
      }
    };

    this._analyticsService.updateStateObject(analyticObject);
  }

  onFieldChange(field: NgModel, fieldName, isPII = false) {
    let fieldValue = '',
      fieldError = '';
    if (!isPII) {
      fieldValue = field.value;
    }

    if (field.errors !== null) {
      fieldError = field.name + ' is required';
    }

    this.trackInteraction(fieldName, fieldValue, fieldError);
  }

  trackInteraction(fieldName, fieldValue, fieldError) {
    let stepName = '';
    if (this.firstInitiation) {
      stepName = 'initiation';
    }

    this.firstInitiation = false;
    const analyticObject = {
      link: {
        name: fieldName,
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: fieldName,
        name: 'Register for Event',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Form',
        name: ''
      },
      form: {
        name: 'Event Registration',
        category: 'registration',
        subCategory: 'event:' + this.program.program_id,
        fieldName: fieldName,
        fieldValue: fieldValue,
        fieldValues: '',
        stepName: stepName,
        stepNumber: '',
        error: fieldError
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  linkInteraction(title, type) {
    const analyticObject = {
      link: {
        name: title,
        displayTitle: '',
        type: type,
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: title,
        name: 'Register for Event',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Link',
        name: ''
      },
      form: {
        name: '',
        category: '',
        subCategory: '',
        fieldName: '',
        fieldValue: '',
        fieldValues: '',
        stepName: '',
        stepNumber: '',
        error: ''
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  onStateChange(field: NgModel, fieldName) {
    this.getCities(field);
    this.onFieldChange(field, fieldName);
  }

  clearSignature() {
    this.signaturePad.clear();
    this.validSignature = false;
  }

  drawStart() {
    this.validSignature = true;
  }

  trackByTargetId(index: number, target: Target) {
    return target.target_id;
  }

  searchTarget(form) {
    if (!form.valid) {
      return;
    }

    this.initial = false;
    this.loading_target = true;
    this.showSignature = false;
    this.searchTargetModel.state = this.searchTargetModel.state == 'null' ? null : this.searchTargetModel.state;
    this.searchTargetModel.city = this.searchTargetModel.city == 'null' ? null : this.searchTargetModel.city;
    this.trackInteraction('Search', '', '');
    this._attendeeService.searchTarget(this.searchTargetModel)
      .subscribe(data => {
          this.targetList = data;
          this.loading_target = false;
          this.targetCount = 0;
          for (let i = 0; i < this.targetList.length; i++) {
            if (this.targetList[i].repeated == false) {
              this.targetCount++;
            }
          }
        },
        (error) => {
          this.loading_target = false;
          console.log('Failed to load target');
        });
  }

  resetTarget() {
    this.showSignature = false;
    this.selected_target_id = null;
    this.attendee = null;
    this.registerModel.email = null;
    this.registerModel.message_confirmation = false;
    this.registerModel.phone_number = null;
    this.errorPhoneNumber = false;
  }

  onTargetCheckbox(event, target) {
    this.resetTarget();
    if (event.target.checked) {
      this.selectTargetForRegister(target);
    }
  }

  selectTargetForRegister(target) {
    this.resetTarget();
    this.selected_target_id = target.target_id;
    this.attendee = target;
    this.attendee.email = null;
    this._attendeeService.checkMeetingAttendee(this.program_id, target)
      .subscribe(attendee_details => {
          if (attendee_details['attendee_id']) {
            this.attendee.attendee_id = attendee_details['attendee_id'];
            this.registerModel.email = attendee_details['email'];
            if (attendee_details['sms_opt_in'] && attendee_details['sms_opt_in'] !== '0000-00-00 00:00:00') {
              this.registerModel.message_confirmation = true;
              this.errorPhoneNumber = true;
              for (const phoneDetails of attendee_details['phones']) {
                if (phoneDetails.type === 'cell') {
                  this.registerModel.phone_number = phoneDetails.phone;
                  this.errorPhoneNumber = false;
                }
              }
            }
          } else {
            // Show signature if it is a Target.
            this.showSignature = true;
          }
        },
        (error) => {
          console.log('Failed to get attendee id');
        });
  }

  checkEmailAddress(email) {
    if (email.errors) {
      this.errorEmail = true;
      this.errorEmailMsg = 'Please enter a valid email address';
    } else {
      this.errorEmail = false;
      this.errorEmailMsg = '';
    }
  }

  checkMessageConfirmation() {
    this.errorPhoneNumber = false;
    if (this.registerModel.message_confirmation) {
      this.linkInteraction('Sign up to receive text messages', 'Internal');
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter Phone Number';
    } else {
      this.registerModel.phone_number = '';
    }
  }

  checkPhoneNumber(phone) {
    if (phone.value == '') {
      this.errorPhoneNumber = true;
    } else if (phone.invalid) {
      this.errorPhoneNumber = true;
      this.errorPhoneMsg = 'Please Enter a Valid Phone Number';
    } else {
      this.errorPhoneNumber = false;
      this.errorPhoneMsg = null;
    }
  }

  onRegisterInteraction() {
    const fieldValues = [];
    for (const key in this.attendee) {
      if (this.attendee.hasOwnProperty(key)) {
        if (key === 'phones' && this.registerModel.phone_number) {
          fieldValues.push('phone' + ':' + this.registerModel.phone_number);
        } else if (key === 'address' && this.attendee[key] !== null) {
          const data = this.attendee[key].address1 + ', ' + this.attendee[key].city +
            ', ' + this.attendee[key].state + ', ' + this.attendee[key].zip;
          fieldValues.push('address' + ':' + data);
        } else {
          fieldValues.push(key + ':' + this.attendee[key]);
        }
      }
    }

    const analyticObject = {
      link: {
        name: 'Submit',
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: 'Submit',
        name: 'Register for Event',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Form',
        name: ''
      },
      form: {
        name: 'Event Registration',
        category: 'registration',
        subCategory: 'event:' + this.program.program_id,
        fieldName: '',
        fieldValue: '',
        fieldValues: fieldValues.join('|'),
        stepName: 'submit',
        stepNumber: '',
        error: ''
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: 'primary',
        type: 'form',
        category: 'event registration'
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  cancelVuity() {
    this.cancel();
    const analyticObject = {
      link: {
        name: 'Cancel',
        displayTitle: '',
        type: 'Internal',
        url: window.location.href
      },
      component: {
        type: 'Navigation',
        l10title: '',
        title: 'Cancel',
        name: 'Register for Event',
        position: 'Content'
      },
      content: {
        type: '',
        title: '',
        name: ''
      },
      interaction: {
        type: 'Form',
        name: ''
      },
      form: {
        name: 'Event Registration',
        category: 'registration',
        subCategory: 'event:' + this.program.program_id,
        fieldName: '',
        fieldValue: '',
        fieldValues: '',
        stepName: '',
        stepNumber: '',
        error: ''
      },
      video: {
        name: '',
        id: '',
        length: '',
        action: '',
        playback: ''
      },
      journey: {
        content: '',
        patient: '',
        messageBucket: ''
      },
      mva: {
        name: '',
        tier: '',
        type: '',
        category: ''
      }
    };
    this._analyticsService.updateActionObject(analyticObject);
  }

  registerVuity() {
    // Check if any errors
    if (this.errorPhoneNumber || this.errorEmail) {
      return;
    }

    // Check if target is selected
    if (!this.attendee || !this.attendee.target_id) {
      this.errorMsg = 'Please search and select yourself from above.';
      this.errorRSVP = true;
      return;
    }

    if (!this.attendee.attendee_id && this.attendee.restricted) {
      const modelRestrictedRef = this._modalService.open(TargetComplianceModal, {size: 'lg', centered: true});
      modelRestrictedRef.componentInstance.message = 'Registration could not be completed. ' +
        'Please contact your AbbVie Representative with questions.';
      this.registering = false;
      return;
    }

    if (!this.registerModel.email) {
      this.errorEmail = true;
      this.errorEmailMsg = 'Email is Required';
      return;
    }

    if (!this.attendee.attendee_id) {
      if (this.signaturePad.isEmpty()) {
        return;
      }
      this.attendee.rsvp_signature = this.signaturePad.toDataURL().split('data:image/png;base64,')[1];
    }

    // make degree array
    const degreeList: number[] = [];
    this.attendee.degrees.forEach((degree) => {
      degreeList.push(degree.degree_id);
    });
    this.attendee.degrees = degreeList;

    // make specialty array
    const specialtyList: number[] = [];
    this.attendee.specialties.forEach((specialty) => {
      specialtyList.push(specialty.specialty_id);
    });
    this.attendee.specialties = specialtyList;

    // make title array
    const titleList: number[] = [];
    this.attendee.titles.forEach((title) => {
      titleList.push(title.title_id);
    });
    this.attendee.titles = titleList;
    if (this.registerModel.message_confirmation) {
      this.attendee.sms_opt_in = 1;
      if (this.registerModel.phone_number) {
        this.attendee.phones = [
          {
            'type': 'cell',
            'phone': this.registerModel.phone_number
          }
        ];
      }
    } else {
      this.attendee.sms_opt_in = 0;
    }

    this.registering = true;
    this.attendee.email = this.registerModel.email;

    if (!this.attendee.attendee_id) {
      this._attendeeService.checkCustomerCompliance(this.program_id, this.attendee.customer_id, this.attendee.target_id)
        .subscribe(response => {
            if (response['compliance_status'] === 'fail') {
              const modelref = this._modalService.open(TargetComplianceModal, {size: 'lg', centered: true});
              modelref.componentInstance.message = response['error_message'];
              this.registering = false;
            } else {
              this.registerAttendee();
            }
          },
          (error) => {
            this.registering = false;
            console.error(error);
          });
    } else {
      this.registerAttendee();
    }
  }

  registerAttendee() {
    this.onRegisterInteraction();
    this._attendeeService.register(this.program_id, this.attendee)
      .subscribe(status => {
          const attendee_status = 1;
          this.registering = false;
          this._router.navigate(['confirm/' + this.program_id + '/' + attendee_status]);
        },
        error => {
          this.registering = false;
          $('html, body').animate({scrollTop: 0}, 'slow');
          if (error.message) {
            this.errorMsg = error.message;
          }
        });
  }

  getCities(state) {
    this.searchTargetModel.city = null;
    this.cities = [];
    if (state.value == null || state.value == 'null') {
      return;
    }
    this._apiService.get(this.webPage + '/getCities/' + state.value, [])
      .subscribe(cities => {
          this.cities = cities;
        }
        , (error) => {
          console.log('Problem getting cities');
        }
      );
  }
}
