<section class="reps" id="{{webPage}}_reps">
  <div class="right_bar_header">
    <div class="row">
      <div class="col-3 marker">
        <i class="fa fa-user-md"></i>
        <img src="assets/images/icon_pat.png" class="" alt="Icon pat call center" />
      </div>
      <div class="col-12 title"> {{title}} </div>
    </div>
  </div>
  <div class="content text-center">
    <div>
        <p *ngIf="rep.lastname">{{rep.lastname}}, {{rep.firstname}} {{rep.midname}} </p>
        <p class="email"> {{rep.email}}</p>
        <p *ngIf= "rep.phones && rep.phones[0] !== undefined"> {{formatPhoneNumber(rep.phones[0]['phone'])}}</p>
    </div>
  </div>
</section>
