import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

import {ClientConfig, Config} from './config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  clientConfig = 'assets/config/config.json';
  configUrl: string;
  currentClient: string;

  configs: Config;

  constructor(
    private http: HttpClient,
    private _router: Router
  ) {
  }

  setClient() {
    return Observable.create(observer => {
      if (this.configUrl) {
        observer.next(this.configUrl);
      } else {
        return this.http.get(this.clientConfig).subscribe((clients: ClientConfig) => {
          let href = location.href;
          clients.clients.forEach((client) => {
            if (href.indexOf(client) > -1) {
              this.currentClient = client;
              this.configUrl = 'assets/config/' + this.currentClient + '.config.json';
            }
          });
          observer.next(this.configUrl);
        });
      }

    });
  }

  getConfig() {
    return Observable.create(observer => {
      if (this.configs) {
        observer.next(this.configs);
      } else {
        this.setClient()
          .subscribe((success) => {
            return this.http.get(this.configUrl).subscribe((configs: Config) => {
              this.configs = configs;
              observer.next(this.configs);
            });
          });
      }
    });

  }

  getWebPage() {
    return Observable.create(observer => {
      this.getConfig()
        .subscribe((data: Config) => {
            this.configs = data;
            observer.next(data.client.webpage);
          },
          (error) => observer.error(error)
        );
    });
  }

  getPageTitle() {
    return Observable.create(observer => {
      this.getConfig()
        .subscribe((data: Config) => {
            this.configs = data;
            observer.next(data.client.title);
          },
          (error) => observer.error(error)
        );
    });
  }
}

