<style type="text/css">
  .cards_sec {
    width: 100%;
    display: block;
    background: #76c4d6;
    padding: 50px 0
  }

  .cards_sec > .row {
    max-width: 1100px;
    margin: 0 auto
  }

  .cards_sec aside {
    padding: 0 30px;;
    margin-top: 0;
    margin-bottom: 50px
  }

  .cards_sec aside > div {
    height: 100%;
    background: #fff;
    box-shadow: 0 4px 15px #151515;
    -webkit-box-shadow: 0 4px 15px #151515;
    -moz-box-shadow: 0 4px 15px #151515;
    -ms-box-shadow: 0 4px 15px #151515;
    -o-box-shadow: 0 4px 15px #151515;
    padding: 25px;
    text-align: center;
    position: relative;
  }

  .cards_sec aside > div:hover {
    -webkit-box-shadow: 0 7px 20px #151515;
    -moz-box-shadow: 0 7px 20px #151515;
    -ms-box-shadow: 0 7px 20px #151515;
    -o-box-shadow: 0 7px 20px #151515;
    box-shadow: 0 7px 20px #151515;
  }

  .cards_sec aside figure {
    width: 120px;
    height: 120px;
    margin: 0 auto 30px;
    border-radius: 100%;
  }

  .cards_sec aside figure img {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 0;
  }

  .cards_sec aside h2 {
    margin-bottom: 20px;
    font-size: 30px;
    color: #4d4d4f;
    padding: 0 10px;
  }

  .cards_sec aside p {
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #4d4d4f;
  }

  .cards_sec aside a.arrow_card {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    line-height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cards_sec .cards_box:hover a.arrow_card {
    background: #071d49;
  }

  .right_arrow {
    margin-left: -2px
  }

  .right_arrow:after {
    content: '';
    border: solid 3px #fff;
    display: block;
    border-left: transparent;
    border-bottom: transparent;
    width: 11px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* Home Card New Design */
  .bg-orange {
    background-color: #ef5e31;
  }

  .bg-light-blue {
    background-color: #76c4d6;
  }

  .bg-light-green {
    background-color: #1faf8b;
  }

  .cards_sec aside.bg-orange, .cards_sec aside.bg-light-blue {
    padding-top: 0;
    margin-top: 0;
  }

  /* home_migraine_sec */
  .home_migraine_sec {
    width: 100%;
    background-color: #fff;
  }

  .home_migraine_sec .home_migraine_content {
    width: 100%;
    display: block;
  }

  .home_migraine_content h2 {
    width: 100%;
    letter-spacing: 1px;
    text-align: center;
    color: #071d49;
    font-weight: 600;
  }

  @media only screen and (min-width: 991px) {
    .cards_sec aside.bg-orange, .cards_sec aside.bg-light-blue {
      padding-top: 40px;
    }

    .cards_sec .bg-orange .cards_box, .cards_sec .bg-light-blue .cards_box {
      max-width: 450px;
      margin: 0 auto;
      -webkit-transform: translateY(80px);
      -moz-transform: translateY(80px);
      -ms-transform: translateY(80px);
      -o-transform: translateY(80px);
      transform: translateY(80px);
    }

    .home_migraine_sec .row {
      width: 100%;
      min-height: 350px;
      align-items: center;
      padding-top: 75px;
    }

    .home_migraine_content h2 {
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 992px) {
    .cards_sec aside.bg-orange, .cards_sec aside.bg-light-blue {
      background-color: transparent;
    }
  }

  @media only screen and (min-width: 992px) {
    .cards_sec aside {
      margin-top: -110px;
      margin-bottom: 0
    }

    .cards_sec aside figure img {
      margin-top: -90px
    }
  }

  @media only screen and (max-width: 767px) {
    .cards_sec {
      padding: 20px 0
    }

    .cards_sec aside figure {
      width: 90px;
      height: 90px;
      margin-bottom: 10px
    }

    .cards_sec aside h2 {
      margin-bottom: 5px;
      font-size: 18px;
    }
  }

</style>
<section class="cards_sec py-0 bg-white">
  <div class="container-fluid">
    <div class="row">
      <aside class="col-md-12 col-lg-6 bg-orange">
        <div class="cards_box">
          <figure><img class="img-fluid" src="../../../assets/migraine/images/icons/preventive_treatment_icon.png"
                       alt="Qulipta Register"></figure>
          <h2>Learn more about this <b>preventive treatment</b> for episodic and chronic migraine</h2>
          <a class="arrow_card bg-orange" href="https://www.quliptahcp.com/" target="_blank">
            <i class="right_arrow"></i>
          </a>
        </div>
      </aside>
      <aside class="col-md-12 col-lg-6 bg-light-blue">
        <div class="cards_box">
          <figure><img class="img-fluid" src="../../../assets/migraine/images/icons/acute_treatment_icon.png"
                       alt="Ubrelvy Register"></figure>
          <h2>Learn more about this <b>acute treatment</b> for migraine</h2>
          <a class="arrow_card bg-light-green" href="https://www.ubrelvyhcp.com/" target="_blank">
            <i class="right_arrow"></i>
          </a>
        </div>
      </aside>
    </div>
  </div>
</section>

<!-- Cards End -->
<section class="home_migraine_sec">
  <div class="container">
    <div class="row">
      <div class="home_migraine_content">
        <h2>ABBVIE OFFERS A PORTFOLIO OF TREATMENTS FOR MIGRAINE PATIENTS</h2>
      </div>
    </div>
  </div>
</section>
