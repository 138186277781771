import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';

@Component({
  selector: 'app-footer',
  template: `
    <ng-container [ngSwitch]="webPage">
      <vraylar-footer *ngSwitchCase="'vraylar'"></vraylar-footer>
      <migraine-footer *ngSwitchCase="'migraine'"></migraine-footer>
      <vuity-footer *ngSwitchCase="'vuity'"></vuity-footer>
      <botox-footer *ngSwitchCase="'botox'"></botox-footer>
    </ng-container>`
})
export class FooterComponent implements OnInit {
  webPage = null;

  constructor(
    private _configService: ConfigService) {
  }

  ngOnInit() {
    this._configService.getWebPage()
      .subscribe((data) => {
          this.webPage = data;
        },
        (error) => this.webPage = null
      );
  }

}

@Component({
  selector: 'botox-footer',
  templateUrl: '../ui/botox/footer.component.html'
})

export class BotoxFooterComponent extends FooterComponent {
}

@Component({
  selector: 'vraylar-footer',
  templateUrl: '../ui/vraylar/footer.component.html'
})

export class VraylarFooterComponent extends FooterComponent {
}

@Component({
  selector: 'migraine-footer',
  templateUrl: '../ui/migraine/footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MigraineFooterComponent extends FooterComponent {
}

@Component({
  selector: 'vuity-footer',
  templateUrl: '../ui/vuity/footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VuityFooterComponent extends FooterComponent {
}

@Component({
  selector: 'ubrelvy-footer',
  templateUrl: '../ui/ubrelvy/footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UbrelvyFooterComponent extends FooterComponent {
}

@Component({
  selector: 'qulipta-footer',
  templateUrl: '../ui/qulipta/footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class QuliptaFooterComponent extends FooterComponent {
}
