import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {Config} from '../config/config';
import {ConfigService} from '../config/config.service';
import { AttendeeService } from '../services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-watch-video-modal',
  template: `
    <ng-container [ngSwitch]="webPage">
      <botox-watch-video-modal *ngSwitchCase="'botox'" [program]="program" (closeModalEvent)="closeModal()"></botox-watch-video-modal>
    </ng-container>`
})
export class WatchVideoModalComponent implements OnInit {
  webPage = null;
  @Input() program: any = {};
  @Output() closeModalEvent = new EventEmitter<any>();
  constructor(
    protected renderer: Renderer2,
    protected _configService: ConfigService,
    protected _attendeeService: AttendeeService,
    ) {
  }

  registerModel = {
    firstname: null,
    lastname: null,
    email: null,
    confirm_email: null,
  }

  closeModal(){
    this.closeModalEvent.emit();
  }

  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  registered = false;
  registering = false;
  register_failed = false;
  errorMsg = 'Registration failed. Please try again.';
  different_email = false;

  initializeClient() {
    return Observable.create(observer => {
      this._configService.getWebPage()
        .subscribe((data) => {
            this.webPage = data;
          },
          (error) => {
            this.webPage = null;
            observer.error(false);
          }
        );
    });
  }

  ngOnInit() {
    this._configService.getConfig()
      .subscribe((data: Config) => {
        this.webPage = data.client.webpage;
      },
        (error) => this.webPage = null
      );
  }
}

@Component({
  selector: 'botox-watch-video-modal',
  templateUrl: '../ui/botox/watch-video-modal.component.html',
})
export class BotoxWatchVideoModalComponent extends WatchVideoModalComponent {
  showModal = true;
  videoSrc = null;
  @Input() program: any = {};
  @Output() closeModalEvent = new EventEmitter<any>();
  readMore = false;

  ngOnInit() {
    this.initializeClient().subscribe(() => {
    },
      error => {
        console.log(error);
      });
  }

  registerBotox(form) {
    if (this.registerModel.email != this.registerModel.confirm_email) {
      this.different_email = true;
      return;
    }

    if (form.valid) {
      this.registered = false;
      this.registering = true;
      this.register_failed = false;
      this._attendeeService.register(this.program.meeting_id, this.registerModel)
        .subscribe(attendee => {
          if (attendee.attendee_id) {
            this.registerSuccess();
          }
        },
        error => {
          if (error.message && error.message === 'The patient could not be created as an existing member with these details exists.') {
            this.registerSuccess();
          } else {
            this.errorMsg = error.message;
            this.register_failed = true;
            this.registered = false;
            this.registering = false;
          }
        });
      }
    }  

  registerSuccess() {
    this.registering = false;
    this.videoSrc = 'https://downloads.hcpmo.com/downloadResource.php?token=8AcobxDoHrcwBQFS&output=stream&rid=251975F8C511290BB37C6AF410710897';
    this.registered = true;
  }

  close() {
    this.closeModalEvent.emit();
  }

  showReadMore() {
    this.readMore = !this.readMore;
  }
}
