<style type="text/css">
  .upcoming_register h5 {
    color: #071D49;
    margin-bottom: 10px;
    font-weight: 700;
  }

  h4 {
    width: 100%;
    max-width: 570px;
    color: #364FCB;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 18px;
    margin-top: 0;
    line-height: 1.2;
  }

  .upcoming_register button {
    min-width: 130px;
    text-align: center;
    align-self: center;
    background: #26DEC2;
    border-color: #26DEC2;
    padding: 12px 20px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    border-radius: 6px !important;
    display: inline-block;
  }
</style>
<li class="d-flex justify-content-between">
  <aside>
    <h3> {{program.topic}} </h3>
    <h4><strong>{{program.meeting_date_long}}</strong>
      <time>{{program.meeting_time}} {{program.timezone}}</time>
    </h4>
    <p>
      <ng-container *ngFor="let speaker of program.speakers; trackBy: trackBySpeakerId">SPEAKER: {{speaker.name}},
        {{speaker.degree}}<br></ng-container>
    </p>
    <p *ngIf="program.sf_members.email && program.event_type_sub_code !== 'BRDPGM'">SALES REP: {{program.sf_members.lastname}}, {{program.sf_members.firstname}}
      {{program.sf_members.midname}}, {{program.sf_members.email}}</p>
  </aside>
  <aside class="upcoming_register">
    <h5 *ngIf="program.meeting_type_live">{{program.distance}} Miles Away</h5>
    <button (click)="registerNow(program)" class="btn btn-info btn-sm">REGISTER</button>
    <h5 *ngIf="program.meeting_type_live">{{program.slots_available}} Slots Available</h5>
  </aside>
</li>
