<style type="text/css">

  .box_header_container .box_header h4 {
    line-height: 30px;
    height: 30px;
  }

  .box_header {
    max-width: 300px;
    background-color: #03AEA9 !important;
  }

  .box_container {
    padding: 20px;
    margin-top: 50px;
    border: 2px solid #03AEA9 !important;
  }

  .box_content {
    min-height: 385px;
    overflow: auto;
    padding: 10px 0 5px 5px;
  }

  .button_container {
    height: 50px;
    margin: 10px 5px 0px 5px;
  }

  .register_button .btn {
    margin: 0;
  }

  .register-row {
    margin: 0;
    justify-content: center;
  }

  .register_button button {
    border-width: 0px;
    border-radius: 4px;
    margin: 6px;
    border-color: #761F76;
    background-color: #761F76;
    color: #fff;
    padding: 8px 20px;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
  }

  .miles_info,
  .slots_info {
    border-top: 2px solid #03AEA9;
    border-bottom: 2px solid #03AEA9;
    color: #03AEA9;
    font-weight: 600;
    font-size: 14px;
    padding: 0 6px;
    position: relative;
  }

  .align-middle {
    top: 50%;
    position: absolute;
    width: 100%;
    left: 0;
    transform: translatey(-50%);
  }

  .location {
    margin: 0px !important;
  }

</style>
<div class="box_container">
  <div class="box_header_container">
    <div class="box_header text-center">
      <h4> {{!program.meeting_type_live ? "Virtual" : program.event_heading}} </h4>
    </div>
  </div>
  <div class="box_content">
    <div class="text-center" [innerHTML]="superscriptTradeMark(program.topic_short)"></div>
    <div>
      <label>Date/Time:</label>
      <div>{{program.meeting_date}}</div>
      <div>{{program.meeting_time}} {{program.timezone}}</div>
    </div>
    <div>
      <label>Location:</label>
      <div *ngIf="program.meeting_type_live; else notShowLocation">
        <div> {{program.location.name}}</div>
        <div>{{program.location.address}}</div>
        <div><p class="location" *ngIf="program.location.city">{{program.location.city}}, 
          {{program.location.state}} {{program.location.zipcode}}</p></div>
      </div>
      <ng-template #notShowLocation>
        <div>
          Virtual Program
        </div>
      </ng-template>
    </div>
    <div>
      <label>Speaker(s):</label>
      <div *ngFor="let speaker of program.speakers; trackBy: trackBySpeakerId">
        <div> {{speaker.name}}, {{speaker.degree}}
          <span data-toggle="speakerModal" data-target="#speakerModal"
                (click)="onSpeakerModalClick(speaker.speaker_id)">
                <span class="fake-anchor"> View Bio </span>
              </span>
        </div>
        <div>{{speaker.institution}}</div>
        <br>
        <app-speaker-modal [speaker]='speaker' [webPage]="webPage" [showModal]='showModal' [currentModal]='currentModal'
                           (hideModal)="hideModal($event)"></app-speaker-modal>
      </div>
    </div>
    <div>
      <label>Sales Rep(s):</label>
      <div *ngIf="program.sf_members.email">
        <div>{{program.sf_members.lastname}}, {{program.sf_members.firstname}} {{program.sf_members.midname}} </div>
        <div>{{program.sf_members.email}}</div>
        <br>
      </div>
    </div>
  </div>
  <div class="row register-row text-center" *ngIf="canregister">
    <div class="miles_info col-3" *ngIf="program.meeting_type_live">
      <span class="align-middle">{{program.distance}} miles away</span>
    </div>
    <div class="register_button col-6">
      <button (click)="registerNow(program)" class="btn">Register</button>
    </div>
    <div class="slots_info col-3" *ngIf="program.meeting_type_live">
      <span class="align-middle">{{program.slots_available}} slots available</span>
    </div>
  </div>
</div>

