import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Config} from '../config/config';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  webPage = null;

  constructor(private _configService: ConfigService, private meta: Meta) {
  }

  ngOnInit() {
    this._configService.getWebPage()
      .subscribe((data) => {
          this.webPage = data;
        },
        (error) => this.webPage = null
      );
    this._configService.getConfig()
      .subscribe((data: Config) => {
        if (data.client.has_meta_description) {
          this.meta.updateTag({name: 'description', content: data.client.home_meta_description});
        }
      });
  }

}
