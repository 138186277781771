import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import moment from 'moment';

import {LoadMoreObject, Meeting, MeetlingList, SearchObject, Speaker} from '../interfaces/index';
import {ConfigService} from '../config/config.service';
import {APIService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AttendeeService {
  loading = new Subject<Boolean>();
  searchParams;
  searchDistance;
  programTypeKey;
  attendee_id = null;
  private programs = new Subject<MeetlingList>();
  private onDemandPrograms = new Subject<any>();
  private loadMoreObj = new Subject<LoadMoreObject>();
  private current_program: Meeting;
  private formatted_programs: MeetlingList = {
    'live': [],
    'virtual': [],
    'conference': [],
    'clear_meeting_list': false
  };
  private webPage;
  private date_format = 'dddd, MMMM D, YYYY';
  private clientConfig;

  constructor(
    private _apiService: APIService,
    private _configService: ConfigService
  ) {

    this.formatted_programs['live'] = [];
    this.formatted_programs['virtual'] = [];
    this.formatted_programs['clear_meeting_list'] = false;
    this._configService.getConfig()
      .subscribe((data) => {
          this.webPage = data.client.webpage;
          this.clientConfig = data.client;
          if (data.client.date_format) {
            this.date_format = data.client.date_format;
          }
        },
        (error) => this.webPage = null
      );
  }

  getMeetingLists(): Observable<MeetlingList> {
    return this.programs.asObservable();
  }

  isDataLoading(): Observable<Boolean> {
    return this.loading.asObservable();
  }

  getLoadMoreDetails(): Observable<LoadMoreObject> {
    return this.loadMoreObj.asObservable();
  }

  sortMeetings() {
    this.formatted_programs['live'].sort(function (k, p) {
      if (k['distance'] > p['distance']) {
        return 1;
      } else if (k['distance'] < p['distance']) {
        return -1;
      }
      return 0;
    });
  }

  clearMeetingLists() {
    return Observable.create(observer => {
      this.formatted_programs['live'] = [];
      this.formatted_programs['virtual'] = [];
      this.formatted_programs['conference'] = [];
      this.formatted_programs['clear_meeting_list'] = true;
      this.programs.next(this.formatted_programs);
      this.attendee_id = null;
      observer.next(true);
    });
  }

  setSearchDistance(searchDistance: number | string) {
    this.searchDistance = searchDistance;
  }

  setProgramTypekey(programTypeKey: string) {
    this.programTypeKey = programTypeKey;
  }

  setMeetingLists(brand, p: SearchObject) {
    this.loading.next(true);
    const defLoadMoreObj: LoadMoreObject = {
      show_load_more: false,
      page: p.page ? p.page : 1
    };
    if (p.page == null || p.page <= 1) {
      this.formatted_programs['live'] = [];
      this.formatted_programs['virtual'] = [];
    }
    this.formatted_programs['clear_meeting_list'] = false;
    this.searchParams = p;
    this.attendee_id = null;
    this.searchDistance = this.searchParams.distance;
    let params = JSON.parse(JSON.stringify(p));

    return Observable.create(observer => {
      let temp_params = Object.assign({}, params);

      this._apiService.get(brand + '/meetings', temp_params).subscribe(results => {
        if (!results['error']) {
          let programs = results;
          this.formatMeetingData(programs['meetings']);
          this.loading.next(false);
          this.programs.next(this.formatted_programs);
          if (results.load_more_details) {
            this.loadMoreObj.next(results.load_more_details);
            this.searchParams.page = results.load_more_details.page;
          }

          observer.next(true);
          observer.complete();
        } else {
          this.clearMeetingLists();
          this.loading.next(false);
          this.loadMoreObj.next(defLoadMoreObj);
          observer.error(false);
        }
      }, (error) => {
        this.loading.next(false);
        this.loadMoreObj.next(defLoadMoreObj);
        observer.error(error);
      });
    });
  }

  getOnDemandMeetingLists(): Observable<any> {
    return this.onDemandPrograms.asObservable();
  }

  setOnDemandMeetingLists(brand, p: any) {
    let params = JSON.parse(JSON.stringify(p));

    return Observable.create(observer => {
      let temp_params = Object.assign({}, params);

      this._apiService.get(brand + '/onDemandMeetings', temp_params).subscribe(results => {
        if (!results['error']) {
          let programs = results;
          this.onDemandPrograms.next(programs);
          observer.next(true);
          observer.complete();
        } else {
          this.onDemandPrograms.next([]);
          observer.error(false);
        }
      }, (error) => {
        observer.error(error);
      });
    });
  }

  formatMeetingData(programs: any[]): void {
    for (let rawProgram of programs) {
      this.format(rawProgram);
    }
  }

  format(rawProgram: {}) {
    let program: Meeting;
    const formattedDate = moment(rawProgram['meeting_date'] + ' ' + rawProgram['event_start_time']);
    const defaultEndTime = this.clientConfig.default_end_time ? this.clientConfig.default_end_time : 60;
    const endDate = rawProgram['end_date'] !== '0000-00-00' ? rawProgram['end_date'] : rawProgram['meeting_date'];

    const rep = rawProgram['sf_members'][0] ? rawProgram['sf_members'][0] : {};
    let endDateTime = '';
    let sf_members = [];
    if (Object.keys(rep).length > 0) {
      for (const host of rawProgram['hosts']) {
        if (host['wwid'] == rep.wwid) {
          sf_members = host;
        }
      }
    }

    if (rawProgram['end_time'] === '00:00:00') {
      endDateTime = moment(new Date(moment(endDate).format('YYYY/MM/DD') + ' ' + rawProgram['event_start_time']))
        .add(defaultEndTime, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      endDateTime = endDate + ' ' + rawProgram['end_time'];
    }

    program = {
      program_id: rawProgram['meeting_id'],
      formatted_program_id: rawProgram['formatted_meeting_id'],
      event_title: rawProgram['meeting_name'],
      event_type: rawProgram['program_type_name'],
      event_type_sub_code: rawProgram['event_type']['sub_code'],
      event_indication: rawProgram['indication_id'],
      event_heading: (rawProgram['program_type_name'] == 'Webcast' ? 'Online' : 'Live') + ' ' +
        (rawProgram['indication_id_name'] ? rawProgram['indication_id_name'] : ''),
      meeting_datetime: new Date(formattedDate.format('YYYY/MM/DD') + ' ' + rawProgram['event_start_time']),
      meeting_orig_datetime_utc: rawProgram['event_start_datetime_utc'],
      meeting_orig_datetime: rawProgram['meeting_date'] + ' ' + rawProgram['event_start_time'],
      meeting_end_datetime: endDateTime,
      meeting_date: formattedDate.format(this.date_format),
      meeting_date_long: formattedDate.format('dddd, MMMM D, YYYY'),
      meeting_time: formattedDate.format('LT'),
      meeting_type_name: rawProgram['meeting_type_name'],
      meeting_type_live: rawProgram['meeting_type']['live'],
      timezone: rawProgram['timezone'],
      alternate_time_zones: rawProgram['alternate_time_zones'] ? rawProgram['alternate_time_zones'] : ' ',
      topic: rawProgram['topics'] && rawProgram['topics'][0] ? rawProgram['topics'][0]['topic_name'] : '',
      topic_short: rawProgram['topics'] && rawProgram['topics'][0] ? rawProgram['topics'][0]['topic_name_short'] : '',
      topic_audience: rawProgram['topics'] && rawProgram['topics'][0] ? rawProgram['topics'][0]['topic_audience'] : '',
      location: {
        name: rawProgram['venues'][0] ? rawProgram['venues'][0]['venue_name'] : '',
        address: rawProgram['venues'][0] && rawProgram['venues'][0]['venue_address'] ? (
          rawProgram['venues'][0]['venue_address']['address1'] + ' ' + rawProgram['venues'][0]['venue_address']['address2']) : '',
        city: rawProgram['venues'][0] && rawProgram['venues'][0]['venue_address'] ? rawProgram['venues'][0]['venue_address']['city'] : '',
        state: rawProgram['venues'][0] && rawProgram['venues'][0]['venue_address'] ? rawProgram['venues'][0]['venue_address']['state'] : '',
        zipcode: rawProgram['venues'][0] && rawProgram['venues'][0]['venue_address'] ? rawProgram['venues'][0]['venue_address']['zip'] : '',
        lat: rawProgram['venues'][0] ? rawProgram['venues'][0]['lat'] : '',
        lng: rawProgram['venues'][0] ? rawProgram['venues'][0]['lng'] : ''
      },
      distance: rawProgram['distance'] > 0 ? rawProgram['distance'] : 0,
      slots_available: (rawProgram['venues'][0] && (rawProgram['venues'][0]['venue_slot_available'] > 0)) ?
        rawProgram['venues'][0]['venue_slot_available'] : 0,
      speakers: [],
      external: false,
      sf_members: sf_members,
      topic_es: rawProgram['topics'] && rawProgram['topics'][0] ? rawProgram['topics'][0]['spanish_available'] : false,
      template_type: rawProgram['template_type'],
      web_reg: rawProgram['web_reg']
    };

    if (rawProgram['venues'][0] && (rawProgram['venues'][0]['distance_in_miles'] > 0)) {
      program['distance'] = Math.floor(rawProgram['venues'][0]['distance_in_miles']);
    }

    // find patient ambassador name
    let patient_ambassador_name = null;

    for (let speakerInfo of rawProgram['speakers']) {
      if (!speakerInfo['alternate_position'] || (speakerInfo['alternate_position'] && speakerInfo['alternate_position'] == 0)) {
        let speaker: Speaker = {
          title: speakerInfo['title'],
          //  firstname: speakerInfo['FirstName'],
          //  middlename: speakerInfo['MiddleName'],
          //  lastname: speakerInfo['LastName'],
          speaker_id: speakerInfo['speaker_id'],
          name: speakerInfo['firstname'] + ' ' + speakerInfo['lastname'],
          degree: speakerInfo['degrees'],
          credential: speakerInfo['credential'],
          // email: speakerInfo['Email1'],
          // phone: speakerInfo['BusinessPhoneNumber'],
          imageUrl: speakerInfo['image_url'],
          institution: speakerInfo['affiliation'],
          bio: speakerInfo['bio'],
          speaker_confirmed_timestamp: speakerInfo['speaker_confirmed_timestamp'] ? speakerInfo['speaker_confirmed_timestamp'] : '',
          consultant_category_type_code: speakerInfo['consultant_category_type_code'] ? speakerInfo['consultant_category_type_code'] : ''
        };

        program['speakers'].push(speaker);
        // find patient ambassador name
        if (speakerInfo['consultant_category_type_code'] == 'patient') {
          patient_ambassador_name = speakerInfo.firstname;
        }
      }
    }

    // Attach patient ambassador name on end
    if (patient_ambassador_name) {
      program['topic'] = program['topic'] + ' with Patient Ambassador ' + patient_ambassador_name;
    } else if (rawProgram['topics'][1] && rawProgram['topics'][1]['topic_name']) {
      program['topic'] = rawProgram['topics'][1]['topic_name'] + ', ' + program['topic'];
    }

    if (this.clientConfig.has_conference_meetings) {
      let programTypeKeys = {
        'Webcast': 'Virtual',
        'Conference Meeting': 'Conference',
        'Standard Slides': 'Local'
      };
      program['event_heading'] = programTypeKeys[rawProgram['program_type_name']];
    }
    if (!rawProgram['meeting_type']['live']) {
      this.formatted_programs['virtual'].push(program);
    } else if (program['event_type'].includes('Conference')) {
      this.formatted_programs['conference'].push(program);
    } else {
      this.formatted_programs['live'].push(program);
    }

    return program;
  }

  setCurrentProgram(program: Meeting) {
    this.current_program = program;
  }

  getCurrentProgram(program_id: String, params: Object={}) {
    return Observable.create(observer => {
      if (this.current_program && this.current_program.program_id == program_id) {
        observer.next(this.current_program);
      } else {
        this.fetchProgram(program_id, params)
          .subscribe((program) => {
            this.setCurrentProgram(program);
            observer.next(this.current_program);
          }, error => {
            observer.error(error);
          });
      }
    });
  }

  fetchProgram(program_id: String, params: Object) {
    return Observable.create(observer => {
      this._configService.getWebPage()
        .subscribe((data) => {
          const webPage = data;
          this._apiService.get(webPage + '/meetings/' + program_id, params)
            .subscribe(programs => {
              return observer.next(this.format(programs));
            }, error => {
              observer.error(error);
            });
        });
    });
  }

  register(program_id, values): Observable<any> {
    return Observable.create(observer => {
      this._apiService.post(this.webPage + '/meetings/' + program_id + '/register', values)
        .subscribe(results => {
            if (!results['error']) {
              observer.next(results['data']);
            } else {
              observer.error(results['error']);
            }
          },
          error => {
            observer.error(error);
          });
    });
  }

  getCalendar(program_id) {
    return Observable.create(observer => {
      this.getCurrentProgram(program_id)
        .subscribe((program: Meeting) => {
          const event = {
            'program_id': program['program_id'],
            'formatted_program_id': program['formatted_program_id'],
            'event_title': this.webPage == 'botox' ? program['formatted_program_id'] + ': Spasticity Webinar' : program['event_title'],
            'meeting_datetime': program['meeting_orig_datetime'],
            'meeting_endtime': program['meeting_end_datetime'],
            'timezone': program['timezone'],
            'location': program['template_type'] == 'live' || program['template_type'] == 'hybrid' ? program['location']['name'] : '',
            'web_reg': program['web_reg'],
            'template_type': program['template_type']
          };
          this._apiService.downloadFile(this.webPage + '/meetings/' + program_id + '/downloadCalendar', event)
            .subscribe((data) => {
                observer.next(data);
              },
              (error) => observer.error(false)
            );
        });
    });
  }

  getCurrentZip() {
    return Observable.create(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            let lat = position.coords.latitude;
            let lng = position.coords.longitude;
            this._apiService.reverserGeoCode(lat, lng)
              .subscribe(function (address) {
                let place = address.results[0];
                for (let i in place.address_components) {
                  let item = place.address_components[i];
                  if (item['types'].indexOf('postal_code') > -1) {
                    observer.next(item['long_name']);
                  }
                }
              });
          },
          error => {
            observer.error(error.code);
            switch (error.code) {
              case 1:
                console.log('Permission Denied');
                break;
              case 2:
                console.log('Position Unavailable');
                break;
              case 3:
                console.log('Timeout');
                break;
            }
          },
        );
      }
    });
  }

  searchTarget(params) {
    return Observable.create(observer => {
      this._apiService.post(this.webPage + '/targets', params)
        .subscribe(res => {
          let raw_targets = res['data'];
          let targets = [];
          for (let raw_target of raw_targets) {
            let degrees = []
            let target = {
              'target_id': raw_target['target_id'],
              'prefix': raw_target['prefix'],
              'firstname': raw_target['firstname'],
              'lastname': raw_target['lastname'],
              'midname': raw_target['midname'],
              'suffix': raw_target['suffix'],
              'affiliation': raw_target['affiliation'],
              'email': raw_target['email'],
              'customer_id': raw_target['customer_id'],
              'ims_num': raw_target['ims_num'],
              'me_num': raw_target['me_num'],
              'dea_num': raw_target['dea_num'],
              'npi_num': raw_target['npi_num'],
              'phones': raw_target['phones'],
              'titles': raw_target['titles'],
              'degrees': raw_target['degrees'],
              'specialties': raw_target['specialties'],
              'license': raw_target['license'],
              'restricted': raw_target['restricted'],
              'repeated': false,
              'address': {}
            };
            target['degreeList'] = '';
            if(raw_target.degrees.length > 0) {
              raw_target.degrees.forEach((degree) => {
                degrees.push(degree.degree_desc);
              });
              target['degreeList'] = degrees.join(', ');
            }
            if (raw_target.addresses.length > 0) {
              raw_target.addresses.forEach((address, index) => {
                target['address']['address1'] = address['address1'];
                target['address']['zip'] = address['zip'];
                target['address']['state'] = address['state'];
                target['address']['city'] = address['city'];
                let cloned_target = JSON.parse(JSON.stringify(target));

                if (index > 0) {
                  cloned_target['repeated'] = true;
                  targets.push(cloned_target);
                } else {
                  cloned_target['repeated'] = false;
                  targets.push(cloned_target);
                }
              });
            }
          }
          return observer.next(targets);
        });
    });
  }

  searchMeetingAttendees(program_id, attendeeParams) {
    return Observable.create(observer => {
      this._apiService.post(this.webPage + '/meetings/' + program_id + '/attendees/search',attendeeParams)
        .subscribe(res => {
          let raw_attendees = res['data'];
          let attendees = [];
          for (let raw_attendee of raw_attendees) {
            let degrees = [];
            let attendee = {
              'attendee_id': raw_attendee['attendee_id'],
              'prefix': raw_attendee['prefix'],
              'firstname': raw_attendee['firstname'],
              'lastname': raw_attendee['lastname'],
              'midname': raw_attendee['midname'],
              'suffix': raw_attendee['suffix'],
              'address': {}
            }
            if (raw_attendee.addresses.length > 0) {
              raw_attendee.addresses.forEach((address, index) => {
                attendee['address']['address1'] = address['address1'];
                attendee['address']['zip'] = address['zip'];
                attendee['address']['state'] = address['state'];
                attendee['address']['city'] = address['city'];
              });
            }
            if (raw_attendee.degrees.length > 0) {
              raw_attendee.degrees.forEach((degree, index) => {
                if(degree['degree_abbrv'] === 'OTH') {
                  degrees.push(raw_attendee['degree_other']);
                } else {
                  degrees.push(degree['degree_desc']);
                }
              });
            }
            attendee['degree'] = degrees.join(', ');
            attendees.push(attendee);
          }
          return observer.next(attendees);
        });
    })
  }

  checkMeetingAttendee(meeting_id, target) {
    const params = {
      target_id: target.target_id
    };
    return Observable.create(observer => {
      this._apiService.post(this.webPage + '/meetings/' + meeting_id + '/searchAttendee', params)
        .subscribe(res => {
          const attendee = res['data'];
          let attendee_details = [];
          if (attendee.length > 0) {
            attendee_details['attendee_id'] = attendee[0].attendee_id;
            attendee_details['sms_opt_in'] = attendee[0].sms_opt_in;
            attendee_details['phones'] = attendee[0].phones;
            attendee_details['email'] = attendee[0].email;
          }
          return observer.next(attendee_details);
        });
    });
  }

  checkCustomerCompliance(meeting_id, customer_id, target_id) {
    let params = {
      customer_id: customer_id,
      target_id: target_id
    };
    return Observable.create(observer => {
      this._apiService.post(this.webPage + '/meetings/' + meeting_id + '/compliance', params)
        .subscribe(response => {
          return observer.next(response['data']);
        });
    });
  }

  signAttendance(meeting_id, attendee) {
    return Observable.create(observer => {
    this._apiService.post(this.webPage + '/meetings/' + meeting_id + '/signAttendance',attendee)
        .subscribe(response => {
          return observer.next(response['data']);
        });
    });
  }
}
