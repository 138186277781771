<style type="text/css">
  #confirmation h1 {
    color: #000;
  }

  h3.top_header {
    font-weight: 500;
    color: #000;
    font-size: 17px;
  }

  .confirmation_message {
    margin-bottom: 40px;
  }

  .box_container {
    border-color: #2D559A;
  }

  .box_header_container, button {
    border-radius: 5px;
    margin: 0;
  }

  .box_header_container {
    max-width: 300px;
    margin: 0 auto;
    width: 75%;
    min-width: 200px;
  }

  .box_header_container .box_header {
    background-color: #2D559A;
    padding: 7px;
  }

  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #2D559A;
    border-color: #2D559A;
    font-weight: 400;
    text-transform: uppercase;
    padding: 14px 10px;
  }

  .box_content h3 {
    color: #2D559A;
  }

  .waitlist h5 {
    width: 80%;
  }

  .event-calendar-options {
    border: 1px solid #28a745;
    border-top: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    display: none;
    position: absolute;
    width: 100%;
    background: #fff;
  }

  .event-calendar-options li {
    padding: 0;
    border-bottom: 1px solid #c0c0c0;
  }

  .event-calendar-options li a {
    color: #28a745;
    padding: 10px 5px;
    display: block;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }

  .event-calendar-options li button {
    color: #28a745;
    border: 0;
    box-shadow: none;
    background: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 10px 5px;
    display: block;
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }

  @media (max-width: 487px) {
    .waitlist h5 {
      width: 90%;
    }
  }
</style>
<div class="container" id="confirmation">
  <div class="loading container text-center" *ngIf="!program"><i class="fa fa-spinner fa-spin"> </i></div>
  <div *ngIf="program" class="ml-1 mr-1">
    <div class="row">
      <div class="confirmation_message col-12 offset-md-1">
        <div class="confirmed">
          <h1>Thank you for registering for a Patient and Caregiver Education Event<br></h1>
          <h5><span>You are now confirmed for the following event. </span></h5>
          <h5><span>A confirmation email containing additional details will be sent to you shortly.</span></h5>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="box_container col-12 col-sm-6 col-md-4 m-auto" *ngIf="program">
        <div class="box_header_container">
          <div class="box_header text-center">
            <h4 class="confirmed"> Program Confirmation </h4>
          </div>
        </div>
        <div class="box_content">
          <h3> {{program.formatted_program_id + ': Spasticity Webinar'}} </h3>
          <div>
            <label><b>Date/Time:</b></label>
            <div>{{program.meeting_date}}</div>
            <div>{{program.meeting_time}} {{program.timezone}}</div>
            <div *ngIf="program.event_type === 'Webcast'">{{program.alternate_time_zones}}</div>
          </div>
          <div *ngIf='((program.event_type != "Webconference" && program.event_type != "Webcast" && program.meeting_type_live) ||
          (program.event_type == "Webcast" && program.meeting_type_live)
          ); else showVirtualLocation'>
            <label><b>Location:</b></label>
            <div> {{program.location.name}}</div>
            <div>{{program.location.address}}</div>
            <div>{{program.location.city}}, {{program.location.state}} {{program.location.zipcode}}</div>
          </div>
          <ng-template #showVirtualLocation>
            <div>
              <label><b> Location: </b></label>
              <div>Virtual Program</div>
            </div>
          </ng-template>
          <div>
            <label><b>Speaker(s):</b></label>
            <div *ngFor="let speaker of program.speakers">
              <div> {{speaker.name}}<span *ngIf="speaker.degree">, {{speaker.degree}} </span></div>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="calendar_container col-xs-12 col-sm-6 col-md-4 p-0 m-auto">
        <button type="button" (click)="showCalendarOptions()" class="btn btn-success w-100">
          <i class="fa fa-calendar" aria-hidden="true" style="font-size: 21px;">&nbsp;</i> Add To Calendar
          <i class="fa fa fa-info-circle" aria-hidden="true" style="font-size: 21px; float: right;" custom-popover=""
             title="" data-content="You can download the file and import it to your calendar" popover-placement="right"
             data-original-title=""></i>
        </button>
        <ul class="event-calendar-options">
          <li>
            <a [href]="googleCalLink"
               target="_blank"><i class="fa fa-google"></i> Google Cal</a>
          </li>
          <li>
            <button type="button" (click)="getCalendar()" class="btn w-100">
              <i class="fa fa-apple"></i> Apple iCal
            </button>
          </li>
          <li>
            <button type="button" (click)="getCalendar()" class="btn w-100">
              <i class="fa fa-envelope"></i> Outlook/Other
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
