<style type="text/css">
  .box_container {
    border: 1px #761F76 solid
  }

  .box_header {
    background-color: #03AEA9;
  }

  .box_header h4 {
    margin: 2px;
  }

  h2 {
    color: #4d4d4f;
    font-size: 22px;
    font-weight: 500;
  }

  input {
    max-width: 150px;
  }

  input.form-control {
    height: 45px;
  }

  button.btn-warning {
    border-color: #761F76 !important;
    background-color: #761F76 !important;
    color: #fff;
    border-radius: 2px;
    font-weight: 600;
    height: 45px;
  }

  .zipcenter {
    max-width: 400px;
    width: auto;
    margin: 15px auto 0 auto;
    text-align: center;
  }

  .zipcenter input {
    min-width: 100px;
    width: auto;
    margin: 0 auto !important;
  }

  .error-text {
    font-weight: 600;
    color: #a94442 !important;
  }

  #calling_info {
    flex: none;
    min-height: auto !important;
    align-items: normal !important;
    align-content: normal !important;
    text-align: center;
  }

  #calling_info #info_content {
    background-color: #03AEA9;
    margin-bottom: 20px;
    border-radius: 15px;
  }

  #calling_info #info_content a {
    text-decoration: underline;
  }

  #calling_info h2 {
    color: #fff !important;
    font-weight: 500;
    font-size: 20px !important;
  }

  .info_2018 {
    border: 1px solid #000;
    font-size: 14px;
    font-weight: 500;
    max-width: 620px;
    padding: 10px;
    color: #fff;
    background-color: #AB47BC;
  }

  .info_2018 a {
    color: #69F0AE !important;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 600;
  }

  .with-trademark {
    position: relative;
    padding-right: 4px;
    margin-right: 3px;
  }

  .with-trademark i {
    font-style: normal;
    position: absolute;
    right: 0;
    font-size: 14px;
  }

  .pd-20 {
    padding: 0 20px;
  }

  .distance_list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    margin: 10px 0 0 0 !important;
    flex-wrap: wrap;
  }

  .distance_list label.first {
    margin-left: 10px !important;
  }

  .distance_list label {
    max-width: none !important;
  }

  .distance_list .radio-inline.last .add-info:before {
    content: 'Programs';
  }

  .distance_list .radio-inline .add-info:before {
    content: 'miles';
  }

  .heading-text {
    color: #761F76;
  }
</style>
<section class="search_header">
  <div *ngIf="!showList">
    <h1 class="m-0">
      Upcoming <span class="with-trademark">VRAYLAR<sup>&reg;</sup></span> Speaker Program Presentation<br>
    </h1>
    <div class="row">
      <h2 class="col-12"><b>Learn about
        <span class="with-trademark">VRAYLAR<sup>&reg;</sup></span>from your peers</b>
      </h2>
    </div>
  </div>
</section>
<section class="row box_container mt-5" id="vraylar_search">
  <div class="box_header_container text-center">
    <div class="box_header">
      <h4>Please enter your ZIP code to search for VRAYLAR programs near you</h4>
    </div>
  </div>
  <form class="form-inline col-12" name="searchForm" (ngSubmit)="f.form.valid && searchPrograms(true)" #f="ngForm"
        novalidate>
    <div class="form-group zipcenter col-12">
      <input class="form-control" placeholder="Zip Code" [(ngModel)]="searchModel.zip" [value]="searchModel.zip"
             name="zip" #zip="ngModel" type="text" [ngClass]="{ 'is-invalid': f.submitted && zip.invalid }"
             [required]="searchModel.distance.toString() != 'Virtual'" pattern="(^\d{5}$)|(^\d{5}-\d{4}$)"
             autofocus="true"/>
      <button type="submit" class="btn btn-warning"
              [disabled]="disableSearchBtn || (zip.errors && zip.errors.pattern)">Search
      </button>
    </div>
    <div class="col-12">
      <p class="purple_text text-center" style="margin-top: 8px;"> You can search for live programs within a specific
        mile radius or virtual programs </p>
    </div>

    <div class="distance_list col-xs-12">
      <label class="purple_text radio-inline ng-binding ng-scope"
             *ngFor="let distance of distanceList; let first = first; let last = last"
             [ngClass]="{ first: first, last: last }">
        <input type="radio" [(ngModel)]="searchModel.distance" [value]="distance"
               class="ng-pristine ng-untouched ng-valid ng-not-empty" (change)="resetZip()"
               name="distance">{{distance}}&nbsp;<span class="add-info"></span>
      </label>
    </div>
    <div *ngIf="f.submitted && zip.invalid" class="col-12 text-center text-danger error-text">
      <div *ngIf="zip.errors.required">Zip code cannot be empty</div>
    </div>
    <div *ngIf="(zip.errors && zip.errors.pattern) || invalidzip" class="col-12 text-center text-danger">The zip code
      you
      entered is invalid. Please check
      and
      try again.
    </div>
  </form>
</section>
<section id="calling_info" class="pd-20" *ngIf="!showList">
  <div id="info_content">
    <h2>To register for an educational event over the phone, please call
      <a href="tel:1-888-335-8430" class="d-inline-block" style="text-decoration: none;"><b style="font-size: 20px;">1-888-335-8430</b></a>
    </h2>
  </div>
</section>
