<style type="text/css">
	.speaker {
		margin-bottom: 5px;
	}
	.visible {
		display: inline;
	}
	.hide {
		display: none;
	}
	.non-bold {
		font-weight: normal;
	}
</style>
<div *ngIf="['vraylar', 'migraine', 'vuity'].includes(webPage); else speakerInfoNonvraylar" class="speaker">
	<div class="{{webPage}}_speaker">
		<div class="snippet">
		  	<div class="speaker-intro clearfix">
		  		<div [ngClass]="webPage != 'vuity' ? 'visible' : 'hide'" class="float-left speaker-image" *ngIf="speaker.imageUrl">
		  			 <img width="82" height="82" class="ng-scope" [src]="speaker.imageUrl" />
		  		</div>
		  		<div class="float-left outer-intro">
		  			<div style="margin-bottom: 8px" *ngIf="webPage == 'migraine' || webPage == 'vuity'; else nonMigraineSpeaker">
						<div><b>{{speaker.name}}, {{speaker.degree}}</b></div>
					  </div>

					  <ng-template #nonMigraineSpeaker>
						<div style="margin-bottom: 8px">
							<div><b>{{speaker.name}}</b> </div>
							<div>{{speaker.degree}}</div>
						</div>
					  </ng-template>
		  			<div class="small-font">{{speaker.institution}}</div>
		  		</div>
		  	</div>
		    <!--  trigger modal -->
			<div [ngClass]="webPage != 'migraine' && webPage != 'vuity' ? 'visible d-block' : 'hide'" class = "text-center" data-toggle="speakerModal" data-target = "#speakerModal" (click) = "onSpeakerModalClick(speaker.speaker_id)">
			  <span class="fake-anchor"> View Bio </span>
			</div>
		</div>
		<app-speaker-modal [speaker] = 'speaker' [webPage]="webPage" [showModal] = 'showModal' [currentModal] = 'currentModal'
		(hideModal) = "hideModal($event)"></app-speaker-modal>
	</div>
</div>
<ng-template #speakerInfoNonvraylar>
	<div class="{{webPage}}_speaker">
		<div class="snippet" style="margin-bottom:15px;">
			<div style="text-align:center;">
			  <img width="110" height="110" class="ng-scope" [src]="speaker.imageUrl" *ngIf="speaker.imageUrl">
			</div>
			<br>
			<div>
				<b>{{speaker.name}}<span *ngIf="speaker.consultant_category_type_code !== 'patient'">, {{speaker.degree}}</span><div class="non-bold" *ngIf="speaker.consultant_category_type_code == 'patient'">{{speaker.degree}}</div></b>
			</div>
			<div>{{speaker.institution}}</div>
			<div></div>
		</div>
	</div>
</ng-template>
