import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router, NavigationEnd } from '@angular/router';

// --- use a declare to allow the compiler find the ga function
declare let ga: Function;

// notes you may need to do this as well: npm install --save-dev @types/google.analytics
@Injectable()
export class GoogleAnalyticsService {
  private subscription: Subscription;
  private initialized: Boolean = false;

  constructor(private router: Router) { }

  public initialize(a_key: String) {
    if (!this.initialized) {
      ga('create', a_key, 'auto');
      ga('send', 'pageview');
    }
  }

  public subscribe(a_key: String) {
    this.initialize(a_key);
    if (!this.subscription) {
      // subscribe to any router navigation and once it ends, write out the google script notices
      this.subscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // this will find & use the ga function pulled via the google scripts
          try {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
            console.log(`logging: ${event.urlAfterRedirects} analytics`);
          } catch {
            console.log('tracking not found - make sure you installed the scripts');
          }
        }
      });
    }
  }

  public unsubscribe() {
    if (this.subscription) {
      // --- clear our observable subscription
      this.subscription.unsubscribe();
    }
  }
}
