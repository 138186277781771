<style type="text/css">
  h5 {
    font-weight: 400;
  }

  div.header-table table {
    margin-bottom: 0;
    border: 1px solid #fff;
    border-bottom: 0;
  }

  div.body-table {
    max-height: 330px;
    overflow: auto;
  }

  .event_banner {
    background-color: #071D49;
    margin-bottom: 30px;
  }

  .event_banner h1 {
    color: #fff;
    font-size: 32px;
  }

  .buttons .btn-register {
    background-color: #071D49;
  }

  div.body-table table {
    background-color: #fff;
    color: #000;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container {
    font-weight: 500;
  }

  .logos {
    margin-bottom: 20px;
  }

  button {
    border-radius: 0;
    height: 39px !important;
    font-size: 15px !important;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 400 !important;
  }

  .panel-body {
    padding: 15px;
  }

  #register label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
    cursor: pointer;
  }

  .register_panel {
    background-color: #071D49;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    margin-bottom: 20px;
  }

  .register_panel table {
    font-size: 14px !important;
  }

  button.rgstr-btn-search {
    margin-top: 0;
    background-color: #a8afbf !important;
    border-color: #a8afbf;
    margin: 0px;
    max-width: 80px;
  }

  .btn-search {
    padding-left: 0px;
  }

  .target_table thead th {
    color: #fff;
  }

  .invalid {
    border-color: #dc3545;
  }

  a.email {
    font-weight: 600;
    color: #fff;
  }

  a.privacy_policy {
    font-weight: 600;
    color: #75c4d6 !important;
  }

  .privacy-policy {
    font-size: small;
  }

  button.btn-warning {
    margin-right: 0 !important;
  }

  span.opt_in {
    font-size: 14px;
  }

  .title {
    margin-bottom: 25px;
    text-align: center;
  }

  .title h1 {
    color: #071D49;
    font-weight: 600;
    display: inline-block;
    margin: 10px 0;
    font-weight: 44px;
  }

  .title h2 {
    margin: 0;
    font-weight: 700;
    color: #4d4d4f;
  }

  .invalid-feedback {
    font-size: 16px;
    color: #F6AAA8 !important;
  }

  .searchtarget .form-control {
    font-size: 13px !important;
    padding: 5px;
  }

  .loading_target_icon {
    line-height: 150px;
  }

  tr.selected_target {
    background-color: #FAFFBD !important
  }

  .table-bordered tr {
    cursor: pointer;
  }

  td.initial_message {
    border-color: #4B4A4B;
    background-color: #4B4A4B;
    padding: 0;
  }

  td.initial_message div {
    height: 100%;
    line-height: 49px;
    background: #fff;
  }

  td.initial_message div p {
    margin: 0 10px;
    line-height: 2;
  }

  td.initial_message div p a {
    color: #75c4d6;
  }

  .npi-question {
    margin-top: 0;
  }
</style>
<section class="event_banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1>EVENT REGISTRATION</h1>
      </div>
    </div>
  </div>
</section>
<section class="logos">
  <div class="row">
    <div class="col-6 text-right">
      <img src="assets/migraine/images/qulipta-logo.png" alt="Qulipta Logo">
    </div>
    <div class="col-6 ubrelvylogo">
      <img src="assets/migraine/images/ubrelvy-logo.png" alt="Ubrelvy Logo">
    </div>
  </div>
</section>
<div class="container" id="register">
  <div class="loading container text-center" *ngIf="!program || loading"><i class="fa fa-spinner fa-spin"> </i></div>
  <div class="row" *ngIf="program && !loading">
    <div class="col-12" id="right_bar">
      <div class="row" *ngIf='program.location'>
        <div class="col-12"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-map-location [location]='program.location' [webPage]="webPage" [meetingLive]='program.meeting_type_live'>
          </app-map-location>
        </div>
        <div class="col-12 col-md-6"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-speaker-list *ngIf="program" [speakers]='program.speakers' [title]="'Speaker(s)'"
                            [webPage]="webPage"></app-speaker-list>
        </div>
        <div class="col-12 col-md-6"
             [class.col-lg-4]="!program.meeting_type_live || program.meeting_type_name == 'Webcast'">
          <app-sf-members *ngIf="program" [rep]='program.sf_members' [title]="'Sales Representative'"
                          [webPage]="webPage"></app-sf-members>
        </div>
      </div>
    </div>
    <div class="title col-12">
      <h1>Register for Event: {{program.formatted_program_id}}<br></h1>
      <div>
        <h2>{{program.topic}}</h2>
        <h2> {{program.meeting_date_long}} at {{program.meeting_time}} {{program.timezone}}
          <span *ngIf='program.meeting_type_live'> in {{program.location.city}},
            {{program.location.state}}</span>
        </h2>
      </div>
    </div>
    <div class="col-lg-12 left_content">
      <div class="panel register_panel">
        <div class="panel-body">
          <p class="text-right m-0">* Mandatory Field</p>
          <form role="form" name="searchtarget" class="row searchtarget" #f="ngForm" novalidate>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="firstname" class="rgstr-label">First Name *</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.firstname" name="firstname" type="text"
                       required #firstname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }">
                <div *ngIf="f.submitted && firstname.invalid" class="invalid-feedback">
                  <div *ngIf="firstname.errors.required" class="text-danger">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="lastname" class="rgstr-label">Last Name *</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.lastname" name="lastname" type="text"
                       required #lastname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }">
                <div *ngIf="f.submitted && lastname.invalid" class="invalid-feedback">
                  <div *ngIf="lastname.errors.required" class="text-danger">Last Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-1">
              <div class="form-group">
                <label for="state" class="rgstr-label">State</label>
                <select class="form-control browser-default custom-select" name="state" id="state"
                        [(ngModel)]="searchTargetModel.state" #state="ngModel" (change)="getCities(state)">
                  <option [value]="null">State</option>
                  <option *ngFor="let state of states" [value]="state.state_alpha2"> {{state.name}} </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group" id="citySelectize">
                <label for="city" class="rgstr-label">City</label>
                <select class="form-control" name="city" id="city" [(ngModel)]="searchTargetModel.city" #city="ngModel">
                  <option [value]="null">City</option>
                  <option *ngFor="let city of cities" [value]="city.city"> {{city.city}} </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="license_number" class="rgstr-label">State License #</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.license_number" name="license_number"
                       type="text" maxlength="10" #license_number="ngModel">
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
              <div class="form-group">
                <label for="npi_number" class="rgstr-label">NPI #</label>
                <input class="form-control" [(ngModel)]="searchTargetModel.npi_number" name="npi_number" pattern="\d{10}"
                       type="text" maxlength="10" #npi_number="ngModel" [ngClass]="{ 'is-invalid': f.submitted && npi_number.invalid }">
                <div *ngIf="f.submitted && npi_number.invalid" class="invalid-feedback">
                  <div *ngIf="npi_number.errors.pattern">Please enter valid NPI Number</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-1 btn-search">
              <div class="form-group">
                <label>&nbsp;</label>
                <button type="submit" class="btn btn-default btn-sm rgstr-btn-search" name="btnSearch"
                        (click)="searchTarget(f)">SEARCH
                </button>
              </div>
            </div>
            <div class="col-12 npi-question">
              <p>
                Don't have an NPI or State License #? <a [routerLink]="['/register', program.program_id, 'new']">Click here to register</a>
              </p>
            </div>
          </form>
          <div class="row">
            <div class="col-12">
              <p>
                AbbVie adheres to all applicable federal and state laws and regulations, including disclosure
                requirements. Attendance at speaker programs is limited to active healthcare professionals. Accordingly,
                we appreciate your support in not bringing a spouse and/or guests to this promotional program.
                Additional attendance restrictions may apply based on specific state or federal requirements and where
                otherwise prohibited by law. It is Company policy to only include healthcare professionals who are
                involved in patient care consistent with our product indication(s).
              </p>
              <p>
                For any technical difficulties contact MedForce at
                <a href="mailto:support@abbviespeakerprograms.com?subject=Registration for Ubrelvy Event: {{program.formatted_program_id}}"
                class="email">support@abbviespeakerprograms.com</a>.
              </p>
            </div>
          </div>
          <div class="row" *ngIf="!loading_target">
            <div class="target_table col-12">
              <div class=" header-table">
                <table width="100%" class="table">
                  <thead>
                  <tr>
                    <th width="30%">Name</th>
                    <th width="25%">FID</th>
                    <th width="45%">Address</th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div class="body-table">
                <table width="100%" class="table table-bordered">
                  <tbody>
                  <tr *ngIf="initial">
                    <td colspan="3" class="initial_message text-center">
                      <div> Search and identify yourself</div>
                    </td>
                  </tr>
                  <tr *ngIf="!targetList || targetList.length == 0 && !initial">
                    <td colspan="3" class="initial_message text-left">
                      <div><p><strong>No Results </strong><br/>
                        Try a New Search, or <a [routerLink]="['/register', program.program_id, 'new']">Add New</a>
                      </p>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let target of targetList; trackBy: trackByTargetId"
                      (click)="selectTargetForRegister(target)"
                      [ngClass]="{selected_target:(selected_target_id == target.target_id)}">
                    <td width="30%"> <span *ngIf="!target.repeated">{{target.firstname}} {{target.midname}}
                      {{target.lastname}}</span></td>
                    <td width="25%"><span *ngIf="!target.repeated">{{target.me_num}}</span></td>
                    <td width="45%">{{target.address.address1}} {{target.address.city}}, {{target.address.state}}
                      {{target.address.zip}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="display_count" *ngIf="targetList.length > 0"> Showing 1 to {{targetCount}} of {{targetCount}}
                entries
              </div>
            </div>
          </div>
          <div *ngIf="loading_target" class="loading_target_icon text-center">
            <i class="fa fa-spinner fa-spin fa-3x"> </i>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <label for="Email">Email Address *
                <input class="form-control" type="email" [ngClass]="{'is-invalid': errorEmail}" placeholder="Email"
                       name="email" #email="ngModel"
                       [(ngModel)]="registerModel.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                       (change)="checkEmailAddress(email)" email>
              </label>
              <div *ngIf="errorEmail">
                <span class="text-danger">{{errorEmailMsg}}</span>
              </div>
            </div>
            <div class="col-md-12 privacy-policy">
              <p>AbbVie may collect your personal data through your online and offline interactions with us, including
                your contact, demographic, geolocation, and professional data. We may also collect your online usage data 
                automatically through cookies and similar technologies. We use this data for several purposes, such as to comply 
                with our legal obligations, to perform a contract with you, to provide you with and improve our programs, 
                services, and products, to customize your experiences, and for research and analytics. We 
                retain your personal data for as long as necessary to fulfill these purposes or to comply with our 
                record retention obligations.  We do not sell your personal data, but we may use and disclose it to marketing 
                and advertising partners to deliver you ads based on your interests inferred from your activity across 
                other unaffiliated sites and services ("online targeted advertising") and for website analytics. To opt out 
                of the use or disclosure of your personal data for online targeted advertising or for website 
                analytics, go to your <a href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" class="privacy_policy">Privacy
                  Choices</a> on our website. For more information on the data categories we collect, the 
                purposes for their collection, our disclosures to third parties, your data subject rights, and 
                our data retention criteria, visit our <a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" 
                target="_blank" class="privacy_policy">Privacy Notice</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-1">
          <span class="text-blue">SIGN UP TO RECEIVE TEXT MESSAGES SO YOU DON'T MISS OUT!</span>
        </div>
        <div class="col-md-12">
            <p>
                Check the box below if you are interested in receiving SMS text messages confirmations and reminders 
                for this AbbVie event.</p></div>
        <div class="col-1 text-right">
          <input type="checkbox" name="message-confirmation" value="true"
                 [(ngModel)]="registerModel.message_confirmation"
                 (change)="checkMessageConfirmation()">
        </div>
        <div class="col-11 nopadding">
          <span class="opt_in">
            I consent to receive automated and recurring text messages from "AbbVie", including services updates and reminders notifications 
            about this AbbVie event to the below mobile number. Message and data rates apply. I am not required to consent as a condition of 
            receiving goods or services. Reply HELP for help; reply STOP to opt out at any time. For Terms and Conditions visit 
            <a href="https://protect-us.mimecast.com/s/K0plC5yMM0H00D49CzM-l-?domain=privacy.abbvie" target="_blank" class="mobile_tc">mobile T&C</a>.
          </span>
          <label><b>Mobile Phone Number</b></label>
          <input type="text" class="col-4 form-control" name="phone" [(ngModel)]="registerModel.phone_number"
                 [attr.disabled]="!registerModel.message_confirmation ? 'disabled' : null"
                 [attr.required]="registerModel.message_confirmation" [ngClass]="{ 'invalid': errorPhoneNumber }"
                 #phone="ngModel"
                 (blur)="checkPhoneNumber(phone)" pattern="^[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}" [textMask]="{mask: mask}">
          <div *ngIf="errorPhoneMsg && errorPhoneNumber">
            <p class="text-danger">{{errorPhoneMsg}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="errorRSVP">
        <div class="col-12">
          <p class="text-danger">{{errorMsg}}</p>
        </div>
      </div>
      <div class="buttons">
        <button type="button" class="btn btn-md btn-register pull-right" (click)="registerMigraine()">
          <span *ngIf="!registering">SUBMIT</span>
          <span *ngIf="registering"><i class="fa fa-spinner fa-spin"></i></span>
        </button>
        <button type="button" class="btn btn-md btn-default pull-right" (click)="cancel()">CANCEL</button>
      </div>
    </div>
  </div>
</div>
