<style type="text/css">
  .upcoming_sec h2 {
    color: #071D49;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase
  }

  .upcoming_sec p {
    color: #4d4d4f;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }

  .upcoming_box {
    padding: 30px;
    background: #e6eaea;
    margin-bottom: 30px
  }

  .upcoming_box ul {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 0;
  }

  .loadmore_upcoming_event {
    text-align: center;
    display: block;
  }

  .loadmore_upcoming_event p {
    text-align: center !important;
    text-transform: uppercase;
    font-size: 20px !important;
  }

  .btn-register {
    background-color: #071D49;
  }

  @media only screen and (min-width: 992px) {
    .upcoming_sec h2 {
      font-size: 32px
    }

    .upcoming_sec p {
      font-size: 22px;
      margin-top: 0;
    }

    .upcoming_box ul li > aside {
      flex: 2 1 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .upcoming_sec h2 {
      font-size: 22px
    }

    .upcoming_sec p,
    .upcoming_box ul li > aside h3 {
      font-size: 16px
    }

    .upcoming_box h4 {
      font-size: 14px;
      margin: 0;
    }

    .upcoming_box ul {
      margin-bottom: 10px
    }

    .upcoming_box h4 strong {
      margin-bottom: 5px
    }

    .upcoming_box p {
      font-size: 13px
    }

    .upcoming_box {
      padding: 15px
    }

    .loadmore_upcoming_event a {
      font-size: 14px;
      margin-bottom: 10px
    }

    .loadmore_upcoming_event p {
      font-size: 13px
    }

  }
</style>
<section class="logos">
  <div class="row">
    <div class="col-6 text-right">
      <img src="assets/migraine/images/qulipta-logo.png" alt="Qulipta Logo">
    </div>
    <div class="col-6 ubrelvylogo">
      <img src="assets/migraine/images/ubrelvy-logo.png" alt="Ubrelvy Logo">
    </div>
  </div>
</section>
<section class="upcoming_sec">
  <div class="loading container text-center" *ngIf="loading"><i class="fa fa-spinner fa-spin"> </i></div>
  <div class="container" *ngIf="!loading">
    <div class="row">
      <div class="col-sm-12">
        <h2>Upcoming dual Speaker Programs </h2>
        <div class="upcoming_box">
          <div class="col-12" *ngIf="distanceErrorMessage">
            <h3 style="color:red" class="text-center"> {{distanceErrorMessage}} </h3>
          </div>
          <ul>
            <ng-container *ngFor="let program of livePrograms; trackBy: trackByProgramId">
              <migraine-single-program [program]="program" [canregister]="true" (register)="register($event)"
                                       [webPage]="webPage" *ngIf="program"></migraine-single-program>
            </ng-container>

            <ng-container *ngFor="let program of virtualPrograms; trackBy: trackByProgramId">
              <migraine-single-program [program]="program" [canregister]="true" (register)="register($event)"
                                       [webPage]="webPage" *ngIf="program"></migraine-single-program>
            </ng-container>
          </ul>
          <div class="loadmore_upcoming_event">
            <div class="row load_more_row text-center" *ngIf="!loading">
              <div id="load_more_button" class="col-12" (click)="loadMorePrograms()"
                   *ngIf="load_more_details.show_load_more && (livePrograms.length > 0 || virtualPrograms.length > 0)">
                <button class="btn btn-register">LOAD MORE</button>
              </div>
            </div>
            <p>
              <strong>To register for an educational event over the phone, please call: (888) 616-0377</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-savings-information></app-savings-information>
