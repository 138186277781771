<style type="text/css">
  #safety_information {
    padding: 0;
  }

  .safety_information {
    position: fixed;
    bottom: 0;
    background: #fff;
    opacity: 1;
    z-index: 100;
    font-size: 16.625px;
    font-family: Avenir Next W00;
  }

  .container {
    padding-right: 30px;
  }

  .safety_header {
    background-color: #2D559A;
    color: white;
  }

  .safety_detail .safety_header {
    background-color: white;
    color: #2D559A;
  }

  .safety_detail .safety_header h4 {
    color: white;
  }

  .safety_header h4 {
    color: white;
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
  }

  .safety_header span {
    font-weight: 600;
    transition: color .2s ease;
    cursor: pointer;
    font-size: 14px;
  }

  .scrolledTop {
    position: relative !important;
    height: auto;
    bottom: 0;
  }

  .scrolledTop .safety_header {
    background-color: #2D559A;
    color: white;
  }

  .scrolledTop h4 {
    color: white;
  }

  .scrolledBottom .safety_header {
    background-color: #2D559A;
    color: white;
  }

  .scrolledBottom {
    position: fixed !important;
    height: auto;
    bottom: 0;
  }

  .scrolledBottom h4 {
    color: white;
  }

  a {
    color: #2D559A;
    font-weight: 600;
    text-decoration: underline;
  }

  .snippet p {
    margin: 10px 0;
  }

  @media screen and (max-width: 480px) {
    .safety_information {
      font-size: 12.625px;
    }
    .safety_header h4 {
      font-size: 14px !important;
    }
    .safety_header span {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  }
</style>
<div class="container">
  <app-footer></app-footer>
</div>
<section [ngClass]="{safety_detail:safety_detail}" class="safety_information container-fluid" id="safety_information">
  <div class="safety_header" id="safety_header_information">
    <div class="d-flex flex-row container">
      <h4 class="m-1 flex-grow-1">Use and Important Safety Information</h4>
      <div class="p-2 d-flex flex-row-reverse justify-content-end">
        <span (click)="alterSafetyDetail(false)" *ngIf="safety_detail">Collapse <i class="fa fa-chevron-down"></i></span>
        <span (click)="alterSafetyDetail(true)" *ngIf="!safety_detail">Expand <i class="fa fa-chevron-up"></i></span>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="snippet">
          <p>
            <b>USE</b>
          </p>
          <p>
            BOTOX<sup>&reg;</sup> (onabotulinumtoxinA) is a prescription medicine that is injected into muscles and
            used to treat increased muscle stiffness in people 2 years of age and older with spasticity.
          </p>
          <p>
            BOTOX has not been shown to help people perform task-specific functions with their upper limbs or increase
            movement in joints that are permanently fixed in position by stiff muscles.
          </p>
        </div>
        <div class="snippet">
          <p><b>IMPORTANT SAFETY INFORMATION</b></p>
          <p><b>BOTOX may cause serious side effects that can be life threatening. Get medical help right
            away if you have any of these problems any time (hours to weeks) after injection of BOTOX:</b>
          </p>
          <ul>
            <li>
              <p><b>Problems swallowing, speaking, or breathing,</b> due to weakening of associated muscles, can be
                severe and result in loss of life. You are at the highest risk if these problems are preexisting before
                injection. Swallowing problems may last several months.</p>
            </li>
            <li>
              <p>
                <b>Spread of toxin effects.</b> The effect of botulinum toxin may affect areas away from the injection
                site and cause serious symptoms, including loss of strength and all-over muscle weakness; double vision;
                blurred vision; drooping eyelids; hoarseness or change or loss of voice; trouble saying words
                clearly; loss of bladder control; trouble breathing; and trouble swallowing.
              </p>
            </li>
          </ul>
          <p>
            BOTOX may cause loss of strength or general muscle weakness, vision problems, or dizziness
            within hours to weeks of receiving BOTOX. <b>If this happens, do not drive a car, operate
            machinery, or do other dangerous activities.</b>
          </p>
          <p>
            <b>Do not receive BOTOX if you</b> are allergic to any of the ingredients in BOTOX (see Medication Guide for
            ingredients); had an allergic reaction to any other botulinum toxin product such as Myobloc<sup>®</sup>
            (rimabotulinumtoxinB), Dysport<sup>®</sup> (abobotulinumtoxinA), or Xeomin<sup>®</sup>
            (incobotulinumtoxinA); have a skin infection at the planned injection site.
          </p>
          <p><b>The dose of BOTOX is not the same as, or comparable to, another botulinum toxin product.</b>
          </p>
          <p><b>Serious and/or immediate allergic reactions have been reported, </b> including itching; rash; red, itchy
            welts; wheezing; asthma symptoms; dizziness; or feeling faint. Get medical help right away if you experience
            symptoms; further injection of BOTOX should be discontinued.</p>
          <p><b>Tell your doctor about all your muscle or nerve conditions,</b> such as ALS or Lou Gehrig's disease,
            myasthenia gravis, or Lambert-Eaton syndrome, as you may be at increased risk of serious
            side effects, including difficulty swallowing and difficulty breathing from typical doses of
            BOTOX.</p>
          <p><b>Tell your doctor if you have any breathing-related problems.</b> Your doctor may monitor you for
            breathing problems during treatment with BOTOX for spasticity. The risk of developing lung disease in
            patients with reduced lung function is increased in patient receiving BOTOX.</p>
          <p><b>Bronchitis and upper respiratory tract infections (common colds) have been reported.</b> Bronchitis
            was reported more frequently in adults receiving BOTOX for upper limb spasticity. Upper respiratory
            infections were also reported more frequently in adults with prior breathing-related problems with
            spasticity. In pediatric patients treated with BOTOX for upper limb spasticity, upper respiratory tract
            infections were reported more frequently. In pediatric patients treated with BOTOX for lower limb
            spasticity, upper respiratory tract infections were not reported more frequently than placebo.
          </p>
          <p><b>Tell your doctor about all your medical conditions, including if you</b> have or have had bleeding
            problems; have plans to have surgery; had surgery on your face; have weakness of forehead muscles, trouble
            raising your eyebrows, drooping eyelids, and any other abnormal facial change; are pregnant or plan to
            become pregnant (it is not known if BOTOX can harm your unborn baby); are breastfeeding or plan to (it is
            not known if BOTOX passes into breast milk).</p>
          <p><b>Tell your doctor about all the medicines you take,</b> including prescription and over the-counter
            medicines, vitamins, and herbal supplements. Using BOTOX with certain other medicines may cause serious side
            effects. <b>Do not start any new medicines until you have told your doctor that you received BOTOX in the
              past.</b></p>
          <p>Tell your doctor if you received any other botulinum toxin product in the last 4 months; have received
            injections of botulinum toxin such as Myobloc<sup>&reg;</sup>, Dysport<sup>&reg;</sup>, or
            Xeomin<sup>&reg;</sup> in the past (tell your doctor exactly which product you received); have recently
            received an antibiotic by injection; take muscle relaxants; take an allergy or cold medicine; take a sleep
            medicine; take aspirin-like products or blood thinners.
          </p>
          <p><b>Other side effects of BOTOX include</b> dry mouth; discomfort or pain at the injection site; tiredness;
            headache; neck pain; eye problems such as double vision, blurred vision, decreased eyesight, drooping
            eyelids, swelling of your eyelids, and dry eyes; drooping eyebrows; and upper respiratory tract infection.
          </p>
          <p>For more information refer to the Medication Guide or talk with your doctor.</p>
          <p><b>You are encouraged to report negative side effects of prescription drugs to the FDA. Visit
            <a href="https://www.fda.gov/medwatch" target="_blank">www.fda.gov/medwatch</a>
            or call </b><b style="display: inline-block;">1-800-FDA-1088.</b></p>
          <p>
            <b>Please see full <a href="https://www.allergan.com/assets/pdf/botox_pi.pdf" target="_blank">
              Product information</a>, including Boxed Warning and
              <a href="https://www.rxabbvie.com/pdf/botox_medguide.pdf " target="_blank">Medication Guide</a> or
              visit <a href="https://www.rxabbvie.com/pdf/botox_pi.pdf" target="_blank">https://www.rxabbvie.com/pdf/botox_pi.pdf.</a></b>
          </p>
          <p><b>If you are having difficulty paying for your medicine, AbbVie may be able to help. Visit
            <a href="https://AbbVie.com/myAbbVieAssist" target="_blank">AbbVie.com/myAbbVieAssist</a>
            to learn more.</b></p>
        </div>
      </div>
    </div>
  </div>
</section>
